import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
  Button,
  Input,
  Form,
  FormField,
  FormTextArea,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Confirm,
} from "semantic-ui-react";
import { IoMdAdd } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";
import DefaultModal from "../../../constants/Modal";
import { RootState, AppDispatch } from "../../../../store/store";
import {
  fetchPermissions,
  addPermission,
  Permission,
  editPermission,
  deletePermission,
} from "../../../../store/slices/permissionsSlice";
import { SkeletonTable } from "../../../constants/SkeletonTable";

const Permissions: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    permissions,
    loading,
    error,
    addLoading,
    editLoading,
    deleteLoading,
  } = useSelector((state: RootState) => state.permissions);

  useEffect(() => {
    dispatch(fetchPermissions());
  }, []);

  const [state, setState] = useState<{
    open: boolean;
    size: "small" | "tiny" | "mini" | "large" | "fullscreen" | undefined;
  }>({ open: false, size: undefined });

  const [permissionToEdit, setPermissionToEdit] = useState<Permission>({
    id: 0,
    name: "",
    description: "",
  });

  const [newPermission, setNewPermission] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    setNewPermission({
      name: permissionToEdit.name,
      description: permissionToEdit.description,
    });
  }, [permissionToEdit]);

  const handleSave = async () => {
    permissionToEdit.id !== 0
      ? await dispatch(
          editPermission({
            id: permissionToEdit.id,
            name: newPermission.name,
            description: newPermission.description,
          })
        )
      : await dispatch(addPermission(newPermission));
    setState({ open: false, size: undefined });
  };

  const handleDelete = async (id: number) => {
    await dispatch(deletePermission(id));
    setConfirmOpen(false);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setNewPermission({ ...newPermission, [name]: value });
  };

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [permissionIdToDelete, setPermissionIdToDelete] = useState<
    number | null
  >(null);

  const openConfirm = (id: number) => {
    setPermissionIdToDelete(id);
    setConfirmOpen(true);
  };

  const handleConfirm = () => {
    if (permissionIdToDelete !== null) {
      handleDelete(permissionIdToDelete);
    }
  };

  const handleCancel = () => {
    setConfirmOpen(false);
    setPermissionIdToDelete(null);
  };

  return (
    <div>
      <DefaultModal
        title="Add New Permission"
        buttonTitle="Save"
        open={state.open}
        size={state.size}
        dimmer="default"
        onClose={() => setState({ open: false, size: undefined })}
        onSave={handleSave}
        loading={addLoading || editLoading}
      >
        <Form>
          <FormField>
            <label>Permission Name</label>
            <input
              name="name"
              placeholder="Permission Name"
              value={newPermission.name}
              onChange={handleChange}
            />
          </FormField>
          <FormTextArea
            name="description"
            label="Description"
            placeholder="Write some description about this permission..."
            value={newPermission.description}
            onChange={handleChange}
          />
        </Form>
      </DefaultModal>
      <Confirm
        open={confirmOpen}
        size="tiny"
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        content="Are you sure you want to delete this permission?"
        confirmButton={{ content: "Delete", loading: deleteLoading }}
      />
      <div className="m-3">
        <div className="bg-white border shadow-sm rounded-lg p-6">
          <div className="flex items-center justify-end">
            <Button
              primary
              size="tiny"
              onClick={() => {
                setPermissionToEdit({
                  id: 0,
                  name: "",
                  description: "",
                });
                setState({ open: true, size: "tiny" });
              }}
            >
              <div className="flex">
                <IoMdAdd />
                Add Permissions
              </div>
            </Button>
          </div>
          <div className="my-5">
            <Input icon="search" placeholder="Search..." />
          </div>
          <div>
            {loading ? (
              <SkeletonTable />
            ) : error ? (
              <p style={{ color: "red" }}>{error}</p>
            ) : (
              <Table basic="very">
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Permission Name</TableHeaderCell>
                    <TableHeaderCell>Description</TableHeaderCell>
                    <TableHeaderCell>Actions</TableHeaderCell>
                  </TableRow>
                </TableHeader>

                <TableBody>
                  {permissions &&
                    permissions.map((permission) => (
                      <TableRow key={permission.id}>
                        <TableCell>{permission.name}</TableCell>
                        <TableCell>{permission.description}</TableCell>
                        <TableCell>
                          <Dropdown trigger={<BsThreeDots />} icon={null}>
                            <DropdownMenu>
                              <DropdownItem
                                icon="pencil alternate"
                                text="Edit"
                                onClick={() => {
                                  setPermissionToEdit(permission);
                                  setState({ open: true, size: "tiny" });
                                }}
                              />
                              <DropdownItem
                                onClick={() => openConfirm(permission.id)}
                                icon="trash"
                                text="Delete"
                              />
                            </DropdownMenu>
                          </Dropdown>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Permissions;
