import React, { ReactNode } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

const CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID || "YOUR_GOOGLE_CLIENT_ID";

interface GoogleOAuthConfigProps {
  children: ReactNode;
}

const GoogleOAuthConfig: React.FC<GoogleOAuthConfigProps> = ({ children }) => {
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>{children}</GoogleOAuthProvider>
  );
};

export default GoogleOAuthConfig;
