import { configureStore } from "@reduxjs/toolkit";
import permissionsReducer from "./slices/permissionsSlice";
import roleReducer from "./slices/roleSlice";
import authenticationReducer from "./slices/authenticationSlice";
import userReducer from "./slices/userSlice";
import tenantReducer from "./slices/tenantSlice";
import categoryReducer from "./slices/categorySlice";
import contentPageReducer from "./slices/contentPageSlice";
import workspaceReducer from "./slices/workspaceSlice";
import workspaceContentReducer from "./slices/workspaceContentSlice";
import embedTokenReducer from "./slices/embedTokenSlice";
import capacityReducer from "./slices/capacitySlice";
import teamReducer from "./slices/teamSlice";
import teamUser from "./slices/teamUsersSlice";
import brandingReducer from "./slices/brandingSlice";
import colorReducer from "./slices/colorSlice";
import layoutReducer from "./slices/layoutSlice";

const store = configureStore({
  reducer: {
    permissions: permissionsReducer,
    roles: roleReducer,
    authentication: authenticationReducer,
    users: userReducer,
    tenants: tenantReducer,
    categories: categoryReducer,
    contentPages: contentPageReducer,
    workspaces: workspaceReducer,
    workspaceContents: workspaceContentReducer,
    embedToken: embedTokenReducer,
    capacities: capacityReducer,
    teams: teamReducer,
    teamUsers: teamUser,
    branding: brandingReducer,
    colors: colorReducer,
    layouts: layoutReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
