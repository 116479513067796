import React, { FC, ReactNode } from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "semantic-ui-react";

type ModalProps = {
  title: string;
  buttonTitle: string;
  open: boolean;
  size?: "mini" | "tiny" | "small" | "large" | "fullscreen";
  centered?: boolean;
  onClose: () => void;
  onSave: () => void;
  children: ReactNode;
  dimmer: string;
  loading?: boolean; // New loading prop
};

const DefaultModal: FC<ModalProps> = ({
  title,
  buttonTitle,
  open,
  size = "small",
  centered = true,
  onClose,
  onSave,
  children,
  dimmer,
  loading = false, // Default value for loading
}) => {
  return (
    <Modal
      size={size}
      open={open}
      onClose={onClose}
      dimmer={dimmer}
      centered={centered}
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalContent>{children}</ModalContent>
      <ModalActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button primary onClick={onSave} loading={loading}>
          {buttonTitle}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default DefaultModal;
