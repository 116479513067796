import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

export interface NavSecurity {
  Id: number;
  GroupId: number;
  CanEdit: boolean;
  RolesValidation: string;
}
export interface TeamType {
  id: number;
  teamName: string;
  description: string;
}

export interface ContentPage {
  id: number;
  CreatedBy?: string;
  CreatedOn?: string;
  UpdatedOn?: string;
  NavigationSetupId?: string;
  OrganisationId?: number;
  Parent?: string;
  Type?: string;
  type: string;
  IsRls?: boolean;
  PageType?: string;
  ReportType?: string;
  ReportDatasetId?: string;
  toggler?: string;
  Title: string;
  Icon: string;
  Description?: string;
  PagePath?: string;
  PowerBiWorkspace?: string;
  DisplayUseDynamicBinding?: boolean;
  DynamicDataSetid?: string;
  ReportPages?: string;
  ShowFilter?: boolean;
  ShowContentPane?: boolean;
  HideTitleAnddescription?: boolean;
  HideTitleSection?: boolean;
  ShowSharingButton?: boolean;
  ShowExportButton?: boolean;
  NavSecurity?: NavSecurity[];
  SortOrder: number;
  EmbedUrl?: string;
  Teams?: TeamType[];
}

interface ContentPageState {
  contentPages: ContentPage[];
  contentPage: ContentPage | null;
  loading: boolean;
  error: string | null;
  addLoading: boolean;
  deleteLoading: boolean;
  bulkUpdateLoading: boolean;
}

const initialState: ContentPageState = {
  contentPages: [],
  contentPage: null,
  loading: false,
  error: null,
  addLoading: false,
  deleteLoading: false,
  bulkUpdateLoading: false,
};

const apiUrl = "https://cedarplatform.io:4400/api/v1/navigations";

const getHeaders = (getState: () => RootState) => {
  const token = getState().authentication.tokens?.token;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const fetchContentPages = createAsyncThunk<
  ContentPage[],
  void,
  { state: RootState; rejectValue: string }
>(
  "contentPages/fetchContentPages",
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get<ContentPage[]>(
        apiUrl,
        getHeaders(getState)
      );
      const sortedData = response.data.sort(
        (a, b) => a.SortOrder - b.SortOrder
      );
      return sortedData;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to fetch content pages");
    }
  }
);

export const getContentPageById = createAsyncThunk<
  ContentPage,
  number,
  { state: RootState; rejectValue: string }
>(
  "contentPages/getContentPageById",
  async (contentPageId, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get<ContentPage>(
        `${apiUrl}/${contentPageId}`,
        getHeaders(getState)
      );
      return response.data;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to fetch content page by id");
    }
  }
);

export const addContentPage = createAsyncThunk<
  ContentPage,
  Omit<ContentPage, "id">,
  { state: RootState; rejectValue: string }
>(
  "contentPages/addContentPage",
  async (contentPageData, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post<{ navigation: ContentPage }>(
        apiUrl,
        contentPageData,
        getHeaders(getState)
      );
      return response.data.navigation;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to add content page");
    }
  }
);

export const deleteContentPage = createAsyncThunk<
  number,
  number,
  { state: RootState; rejectValue: string }
>(
  "contentPages/deleteContentPage",
  async (contentPageId, { getState, rejectWithValue }) => {
    try {
      await axios.delete(`${apiUrl}/${contentPageId}`, getHeaders(getState));
      return contentPageId;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to delete content page");
    }
  }
);

export const bulkUpdateContentPageSortOrder = createAsyncThunk<
  void,
  { id: number; sortOrder: number }[],
  { state: RootState; rejectValue: string }
>(
  "contentPages/bulkUpdateContentPageSortOrder",
  async (sortOrderUpdates, { getState, rejectWithValue }) => {
    try {
      await axios.put(
        `${apiUrl}/bulkupdate`,
        sortOrderUpdates,
        getHeaders(getState)
      );
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to bulk update content page sort orders");
    }
  }
);

export const updateContentPage = createAsyncThunk<
  ContentPage,
  { id: number; data: Omit<ContentPage, "id"> },
  { state: RootState; rejectValue: string }
>(
  "contentPages/updateContentPage",
  async ({ id, data }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.put<{ navigation: ContentPage }>(
        `${apiUrl}/${id}`,
        data,
        getHeaders(getState)
      );
      return response.data.navigation;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to update content page");
    }
  }
);

const contentPageSlice = createSlice({
  name: "contentPages",
  initialState,
  reducers: {
    clearContentPane: (state) => {
      state.contentPages = [];
      state.contentPage = null;
      state.loading = false;
      state.error = null;
      state.addLoading = false;
      state.deleteLoading = false;
      state.bulkUpdateLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentPages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchContentPages.fulfilled, (state, action) => {
        state.loading = false;
        state.contentPages = action.payload;
      })
      .addCase(fetchContentPages.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload ||
          action.error.message ||
          "Failed to fetch content pages";
      })
      .addCase(getContentPageById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getContentPageById.fulfilled, (state, action) => {
        state.loading = false;
        state.contentPage = action.payload;
      })
      .addCase(getContentPageById.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload ||
          action.error.message ||
          "Failed to fetch content page by id";
      })
      .addCase(addContentPage.pending, (state) => {
        state.addLoading = true;
        state.error = null;
      })
      .addCase(addContentPage.fulfilled, (state, action) => {
        state.addLoading = false;
        state.contentPages.push(action.payload);
      })
      .addCase(addContentPage.rejected, (state, action) => {
        state.addLoading = false;
        state.error =
          action.payload ||
          action.error.message ||
          "Failed to add content page";
      })
      .addCase(deleteContentPage.pending, (state) => {
        state.deleteLoading = true;
        state.error = null;
      })
      .addCase(deleteContentPage.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.contentPages = state.contentPages.filter(
          (contentPage) => contentPage.id !== action.payload
        );
      })
      .addCase(deleteContentPage.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error =
          action.payload ||
          action.error.message ||
          "Failed to delete content page";
      })
      .addCase(bulkUpdateContentPageSortOrder.pending, (state) => {
        state.bulkUpdateLoading = true;
        state.error = null;
      })
      .addCase(bulkUpdateContentPageSortOrder.fulfilled, (state, action) => {
        state.bulkUpdateLoading = false;
        // Update the sortOrder in state.contentPages
        action.meta.arg.forEach((update) => {
          const page = state.contentPages.find((page) => page.id === update.id);
          if (page) {
            page.SortOrder = update.sortOrder;
          }
        });
      })
      .addCase(bulkUpdateContentPageSortOrder.rejected, (state, action) => {
        state.bulkUpdateLoading = false;
        state.error =
          action.payload ||
          action.error.message ||
          "Failed to bulk update sort orders";
      })
      .addCase(updateContentPage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateContentPage.fulfilled, (state, action) => {
        state.loading = false;
        const editedTeamIndex = state.contentPages.findIndex(
          (team) => team.id === action.payload.id
        );
        if (editedTeamIndex !== -1) {
          state.contentPages[editedTeamIndex] = action.payload;
        }
      })
      .addCase(updateContentPage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearContentPane } = contentPageSlice.actions;
export default contentPageSlice.reducer;
