import React from "react";
import homeDashboard from "./../../assets/images/homeDashboard.jpg";

const Home = () => {
  return (
    <div className="m-3">
      <div className="flex items-center justify-end">
        {/* <img
          width="75%"
          height={200}
          src={homeDashboard}
          alt="Home Dashboard"
        /> */}
      </div>
    </div>
  );
};

export default Home;
