import { useState } from "react";
import forgotimage from "./../../assets/images/password-icon-png.jpg";
import { Input } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../store/slices/userSlice";
import toast from "react-hot-toast";
import { AppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleForgotPassword = () => {
    setLoading(true);
    if (!email) {
      setError("email required");
      return;
    }
    dispatch(
      forgotPassword({
        email,
      })
    )
      .then((res) => {
        if (res.type === "users/forgetpassword/fulfilled") {
          toast.success("Check you email");
          setEmail("");
          navigate("/");
        } else {
          throw new Error(res.payload as string);
        }
      })
      .catch((error: any) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="p-10 w-full h-screen">
      <div className="text-3xl font-bold">
        <span className="text-primary">CEDAR</span>
        <span className="text-secondary">STREET</span>
      </div>
      <div className="w-full h-full flex items-center justify-center gap-10">
        <div className="flex flex-col gap-8 w-[30rem]">
          <div>
            <h1 className="text-3xl font-bold">Reset Your Password</h1>
            <p>
              Please enter your registered email address below, and we will send
              you a verification link to help reset your password. Make sure to
              check your email for the verification email.
            </p>
          </div>
          <div>
            <div className="w-full">
              <Input
                fluid
                type="email"
                icon="mail"
                iconPosition="left"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className={`border-2 w-full bg-primary border-primary text-white rounded-full px-12 py-4 inline-block font-semibold`}
              disabled={loading}
              onClick={handleForgotPassword}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
