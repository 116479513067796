import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Form,
  Header,
  Divider,
  Icon,
  Grid,
  Segment,
  Dropdown,
  Message,
} from "semantic-ui-react";
import { useDispatch } from "react-redux";
import "./AddCategoryPage.css";
import { allIcons } from "../../../constants/icons";
import { AppDispatch, RootState } from "../../../../store/store";
import {
  ContentPage,
  addContentPage,
  updateContentPage,
} from "../../../../store/slices/contentPageSlice";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

type AddCategoryPageProps = {
  parentItem: string;
  isNew: boolean;
  category?: ContentPage;
};

const AddCategoryPage: FC<AddCategoryPageProps> = ({
  parentItem,
  isNew,
  category,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { addLoading, loading } = useSelector(
    (state: RootState) => state.contentPages
  );

  const [name, setName] = useState<string>("");
  const [selectedIcon, setSelectedIcon] = useState<string | undefined>(
    undefined
  );
  const [nameError, setNameError] = useState<string | null>(null);
  const [iconError, setIconError] = useState<string | null>(null);

  useEffect(() => {
    if (!isNew && category) {
      setName(category.Title);
      setSelectedIcon(category.Icon);
    } else {
      setName("");
      setSelectedIcon("");
    }
  }, [isNew, category]);

  const handleSubmit = () => {
    let hasError = false;
    if (!name) {
      setNameError("Name is required.");
      hasError = true;
    } else {
      setNameError(null);
    }

    if (!selectedIcon || selectedIcon === undefined) {
      setIconError("Icon is required.");
      hasError = true;
    } else {
      setIconError(null);
    }

    if (hasError) return;

    if (!isNew && category) {
      dispatch(
        updateContentPage({
          id: category.id,
          data: {
            Title: name,
            Icon: selectedIcon || "",
            SortOrder: category.SortOrder,
            type: "category",
          },
        })
      )
        .then((res) => {
          if (res.type === "contentPages/updateContentPage/fulfilled") {
            toast.success("Content Updated Successfully");
          } else if (res.type === "contentPages/updateContentPage/rejected") {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      dispatch(
        addContentPage({
          Title: name,
          Icon: selectedIcon || "home",
          SortOrder: 2,
          type: "category",
        })
      )
        .then((res) => {
          if (res.type === "contentPages/addContentPage/fulfilled") {
            toast.success("Content Created Successfully");
          } else if (res.type === "contentPages/addContentPage/rejected") {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleIconClick = (icon: string) => {
    setSelectedIcon(icon);
  };

  const customTrigger = (
    // @ts-ignore
    <span>{selectedIcon ? <Icon name={selectedIcon} /> : "Choose Icon"}</span>
  );

  return (
    <div className="add-content-page">
      <Header as="h3" className="text-primary opacity-90">
        Create Navigation Option
      </Header>
      <p>Edit the details for your navigation option</p>
      <div className="pt-2 text-primary opacity-85">
        <Header as="h4">
          <span>Parent Item: </span>
          <span>{parentItem}</span>
        </Header>
      </div>

      <div className="w-full flex items-center justify-end space-x-2">
        <Button basic size="tiny">
          Cancel
        </Button>
        <Button
          loading={addLoading || loading}
          primary
          size="tiny"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>

      <Divider />

      <Form>
        <Form.Input
          label="Name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={nameError ? { content: nameError, pointing: "below" } : null}
        />
        <Form.Field error={!!iconError}>
          <label>Icon</label>
          <Dropdown
            trigger={customTrigger}
            fluid
            selection
            className="icon-dropdown"
          >
            <Dropdown.Menu>
              <div className="icon-grid">
                <Grid columns={14} doubling stackable className="m-10">
                  {allIcons.map((icon) => (
                    <Grid.Column key={icon} className="">
                      <Segment
                        onClick={() => handleIconClick(icon)}
                        className={`icon-segment ${
                          selectedIcon === icon ? "selected" : ""
                        }`}
                      >
                        {/* @ts-ignore */}
                        <Icon name={icon} />
                      </Segment>
                    </Grid.Column>
                  ))}
                </Grid>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {iconError && <Message error content={iconError} />}
        </Form.Field>
      </Form>
    </div>
  );
};

export default AddCategoryPage;
