import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
  Button,
  Input,
  Form,
  FormField,
  FormGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Confirm,
  Modal,
  FormSelect,
  Pagination,
} from "semantic-ui-react";
import { IoMdAdd } from "react-icons/io";
import DefaultModal from "../../../constants/Modal";
import {
  fetchTeams,
  addTeam,
  editTeam,
  deleteTeam,
  TeamResponse,
  assignUser,
  fetchUnassignedUsers,
} from "../../../../store/slices/teamSlice";
import { AppDispatch, RootState } from "../../../../store/store";
import { SkeletonTable } from "../../../constants/SkeletonTable";
import { BsThreeDots } from "react-icons/bs";
import { fetchUsers } from "../../../../store/slices/userSlice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Teams = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [state, setState] = useState<{
    open: boolean;
    size: "small" | "tiny" | "mini" | "large" | "fullscreen" | undefined;
  }>({ open: false, size: undefined });
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState<number | null>(null);
  const [teamData, setTeamData] = useState({
    teamName: "",
    description: "",
  });
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [assignUserOpen, setAssignUserOpen] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null);
  const [teamToEdit, setTeamToEdit] = useState<TeamResponse | null>(null);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    dispatch(fetchTeams());
  }, []);

  useEffect(() => {
    if (selectedTeamId) {
      dispatch(fetchUnassignedUsers(selectedTeamId));
    }
  }, [selectedTeamId]);

  const {
    teams,
    loading,
    addLoading,
    editLoading,
    deleteLoading,
    assignUserLoading,
  } = useSelector((state: RootState) => state.teams);

  const { unassignedUsers } = useSelector((state: RootState) => state.teams);

  const handleClose = () => setState({ open: false, size: undefined });

  const handleOpen = () => {
    setTeamToEdit(null);
    setTeamData({
      teamName: "",
      description: "",
    });
    setState({ open: true, size: "tiny" });
  };

  const options = unassignedUsers
    ?.filter((user) => !user.isSuperTenant)
    .map((user) => ({
      key: user.id.toString(),
      text: user.firstName + " " + user.lastName + " - " + user.email,
      value: user.id.toString(),
    }));

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setTeamData({ ...teamData, [name]: value });
  };

  const handleSubmit = () => {
    if (!teamToEdit) {
      dispatch(addTeam(teamData))
        .then((res) => {
          if (res.type === "team/addTeam/fulfilled") {
            toast.success("Team added successfully");
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      dispatch(editTeam({ teamId: teamToEdit.id, teamData }))
        .then((res) => {
          if (res.type === "team/editTeam/fulfilled") {
            toast.success("Team edited successfully");
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleAssignUser = (teamId: number) => {
    setSelectedTeamId(teamId);
    setAssignUserOpen(true);
  };

  const handleAssignUserSubmit = () => {
    if (selectedTeamId !== null && selectedUsers.length > 0) {
      dispatch(assignUser({ userIds: selectedUsers, teamId: selectedTeamId }))
        .then((res) => {
          if (res.type === "team/assignUser/fulfilled") {
            toast.success("Users assigned successfully");
            setAssignUserOpen(false);
            setSelectedUsers([]);
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleUserSelectChange = (e: any, data: any) => {
    setSelectedUsers(data.value); // data.value will now be an array
  };

  useEffect(() => {
    if (teamToEdit) {
      setTeamData({
        teamName: teamToEdit.teamName,
        description: teamToEdit.description,
      });
    }
  }, [teamToEdit]);

  useEffect(() => {
    if (!deleteLoading && !editLoading && !addLoading && state.open) {
      handleClose();
    }
  }, [deleteLoading, editLoading, addLoading]);

  const handleEdit = (team: TeamResponse) => {
    setTeamToEdit(team);
    setState({ open: true, size: "tiny" });
  };

  const openConfirm = (teamId: number) => {
    setTeamToDelete(teamId);
    setConfirmOpen(true);
  };

  const handleConfirm = () => {
    if (teamToDelete !== null) {
      dispatch(deleteTeam(teamToDelete))
        .then((res) => {
          if (res.type === "team/deleteTeam/fulfilled") {
            toast.success("Team deleted successfully");
            setConfirmOpen(false);
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleCancel = () => {
    setConfirmOpen(false);
    setTeamToDelete(null);
  };

  const handlePageChange = (e: any, { activePage }: any) => {
    setActivePage(activePage);
  };

  const handleRowClick = (teamId: number, teamName: string) => {
    navigate(`team/${teamId}?teamName=${teamName}`);
  };

  const indexOfLastTeam = activePage * itemsPerPage;
  const indexOfFirstTeam = indexOfLastTeam - itemsPerPage;
  const currentTeams = teams.slice(indexOfFirstTeam, indexOfLastTeam);

  return (
    <div>
      <DefaultModal
        title={teamToEdit === null ? "Add New Team" : "Edit Team"}
        buttonTitle="Save"
        open={state.open}
        size={state.size}
        dimmer="default"
        onClose={handleClose}
        onSave={handleSubmit}
        loading={teamToEdit === null ? addLoading : editLoading} // Pass the loading state
      >
        <Form>
          <FormGroup widths="equal">
            <FormField>
              <label>Team Name</label>
              <input
                name="teamName"
                value={teamData.teamName}
                onChange={handleChange}
                placeholder="Team Name"
              />
            </FormField>
          </FormGroup>
          <FormGroup widths="equal">
            <FormField>
              <label>Description</label>
              <input
                name="description"
                value={teamData.description}
                onChange={handleChange}
                placeholder="Description"
              />
            </FormField>
          </FormGroup>
        </Form>
      </DefaultModal>
      <Confirm
        open={confirmOpen}
        size="tiny"
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        content="Are you sure you want to delete this user?"
        confirmButton={{ content: "Delete", loading: deleteLoading }}
      />
      <Modal
        open={assignUserOpen}
        size="tiny"
        onClose={() => setAssignUserOpen(false)}
      >
        <Modal.Header>Assign Users</Modal.Header>
        <Modal.Content>
          <Form>
            <FormSelect
              fluid
              label="Select Users"
              options={options}
              placeholder="Select Users"
              name="users"
              onChange={handleUserSelectChange}
              value={selectedUsers} // Bind to selectedUsers state
              multiple // Enable multiple selection
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setAssignUserOpen(false)}>Cancel</Button>
          <Button
            primary
            onClick={handleAssignUserSubmit}
            loading={assignUserLoading}
          >
            Assign
          </Button>
        </Modal.Actions>
      </Modal>
      <div className="m-3">
        <div className="bg-white border shadow-sm rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div className="my-5">
              <Input icon="search" placeholder="Search..." />
            </div>
            <Button primary size="tiny" onClick={handleOpen}>
              <div className="flex">
                <IoMdAdd />
                Add Team
              </div>
            </Button>
          </div>
          <div>
            {loading ? (
              <SkeletonTable />
            ) : (
              <>
                <Table basic="very">
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell>Role Name</TableHeaderCell>
                      <TableHeaderCell>Description</TableHeaderCell>
                      <TableHeaderCell>Actions</TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentTeams.map((team) => (
                      <TableRow
                        key={team.id}
                        onClick={() => handleRowClick(team.id, team.teamName)}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>{team.teamName}</TableCell>
                        <TableCell>{team.description}</TableCell>
                        <TableCell>
                          <Dropdown
                            className="hover:text-secondary p-1"
                            trigger={<BsThreeDots />}
                            icon={null}
                          >
                            <DropdownMenu>
                              <DropdownItem
                                icon="pencil alternate"
                                text="Edit"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEdit(team);
                                }}
                              />
                              <DropdownItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openConfirm(team.id);
                                }}
                                icon="trash"
                                text="Delete"
                              />
                              <DropdownItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAssignUser(team.id);
                                }}
                                icon="plus"
                                text="Assign User"
                              />
                            </DropdownMenu>
                          </Dropdown>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Pagination
                  activePage={activePage}
                  onPageChange={handlePageChange}
                  totalPages={Math.ceil(teams.length / itemsPerPage)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
