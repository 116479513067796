import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Segment,
} from "semantic-ui-react";
import { AppDispatch, RootState } from "../../../store/store";
import {
  ColorsState,
  fetchColors,
  updateColors,
  updateColor,
  resetColors,
} from "../../../store/slices/colorSlice";
import toast from "react-hot-toast";
import CustomLoader from "../../constants/CustomLoader";

const Colors: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { colors, loading } = useSelector((state: RootState) => state.colors);
  const [localColors, setLocalColors] = useState<Partial<ColorsState>>({});

  useEffect(() => {
    if (!colors) {
      dispatch(fetchColors());
    }
  }, [dispatch]);

  useEffect(() => {
    if (colors) {
      setLocalColors(colors);
    }
  }, [colors]);

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLocalColors({
      ...localColors,
      [name]: value,
    });
    dispatch(updateColor({ key: name as keyof ColorsState, value }));
  };

  const handleSaveChanges = () => {
    dispatch(updateColors(localColors))
      .then((res) => {
        if (res.type === "colors/updateColors/fulfilled") {
          toast.success("Colors updated successfully");
        } else {
          throw new Error(res.payload as string);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleResetColors = () => {
    dispatch(resetColors())
      .then((res) => {
        if (res.type === "colors/resetColors/fulfilled") {
          toast.success("Colors reset to default");
        } else {
          throw new Error(res.payload as string);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div className="m-3 p-3">
      <Segment>
        <div className="flex items-center justify-between mb-5">
          <div>
            <Header as="h2">Customize Colors</Header>
            <p>Customize colors of your site.</p>
          </div>
          <div>
            <Button
              primary
              onClick={handleSaveChanges}
              loading={loading}
              disabled={loading}
            >
              Save Changes
            </Button>
          </div>
        </div>
        <Divider />
        <Form>
          <Grid columns={3} className="" divided>
            {colors &&
              Object.keys(colors).map(
                (colorKey) =>
                  colorKey !== "id" &&
                  colorKey !== "TenantId" &&
                  colorKey !== "loading" &&
                  colorKey !== "updatedAt" &&
                  colorKey !== "createdAt" &&
                  colorKey !== "error" && (
                    <Grid.Column key={colorKey}>
                      <Form.Field className="flex items-center justify-between w-full">
                        <label>
                          {colorKey
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase()) ===
                          "Side Navigation Panel Secondary"
                            ? "Side Navigation Hover Color"
                            : colorKey
                                .replace(/([A-Z])/g, " $1")
                                .replace(/^./, (str) => str.toUpperCase()) ===
                              "Button Face Color"
                            ? "Title Description Color"
                            : colorKey
                                .replace(/([A-Z])/g, " $1")
                                .replace(/^./, (str) => str.toUpperCase()) ===
                              "Navigation Arrow Color"
                            ? "Active Tab Color"
                            : colorKey
                                .replace(/([A-Z])/g, " $1")
                                .replace(/^./, (str) => str.toUpperCase()) ===
                              "Pane Name Current Page"
                            ? "Header Background Color"
                            : colorKey
                                .replace(/([A-Z])/g, " $1")
                                .replace(/^./, (str) => str.toUpperCase())}
                        </label>
                        <input
                          type="color"
                          className="cursor-pointer"
                          style={{
                            backgroundColor: colors[
                              colorKey as keyof ColorsState
                            ] as string,
                          }}
                          name={colorKey}
                          value={
                            localColors[colorKey as keyof ColorsState] as string
                          }
                          onChange={handleColorChange}
                        />
                      </Form.Field>
                    </Grid.Column>
                  )
              )}
          </Grid>
        </Form>
      </Segment>
      <Button
        className="float-right"
        onClick={handleResetColors}
        loading={loading}
        disabled={loading}
      >
        Reset to Default
      </Button>
    </div>
  );
};

export default Colors;
