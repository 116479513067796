import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Button,
  Input,
  Form,
  FormGroup,
  FormInput,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Confirm,
  DropdownProps,
} from "semantic-ui-react";
import { BsThreeDots } from "react-icons/bs";
import DefaultModal from "../../../constants/Modal";
import { MdAdd } from "react-icons/md";
import {
  addTenant,
  fetchTenants,
  editTenant,
  deleteTenant,
  assignUserToTenant,
  TenantResponse,
  suspendTenant,
  activateTenant,
  changeDefaultTenant,
} from "../../../../store/slices/tenantSlice";
import { AppDispatch, RootState } from "../../../../store/store";
import { SkeletonTable } from "../../../constants/SkeletonTable";
import { fetchUsers } from "../../../../store/slices/userSlice";
import { fetchWorkspaces } from "../../../../store/slices/workspaceSlice";
import toast from "react-hot-toast";

const TenantAdmin = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentTenant, setCurrentTenant] = useState<any>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmSuspendOpen, setConfirmSuspendOpen] = useState(false);
  const [confirmActivateOpen, setConfirmActivateOpen] = useState(false);
  const [confirmChangeDefaultOpen, setConfirmChangeDefaultOpen] =
    useState(false);
  const [assignUserModalOpen, setAssignUserModalOpen] = useState(false);
  const [suspendTenantModalOpen, setSuspendTenantModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [state, setState] = useState<{
    open: boolean;
    size: "small" | "tiny" | "mini" | "large" | "fullscreen" | undefined;
  }>({ open: false, size: undefined });
  const { workspaces } = useSelector((state: RootState) => state.workspaces);

  const [tenantName, setTenantName] = useState("");
  const [numberOfTeams, setNumberOfTeams] = useState("");
  const [numberOfUsers, setNumberOfUsers] = useState("");
  const [powerBIWorkspace, setPowerBIWorkspace] = useState<any[]>([]);
  const [language, setLanguage] = useState("en");

  const {
    tenants,
    loading,
    addLoading,
    editLoading,
    deleteLoading,
    assignLoading,
    error,
  } = useSelector((state: RootState) => state.tenants);

  useEffect(() => {
    dispatch(fetchTenants());
    dispatch(fetchUsers());
    dispatch(fetchWorkspaces());
  }, [dispatch]);

  const workspaceOptions = workspaces.map((workspace) => ({
    key: workspace.id,
    text: workspace.name,
    value: workspace.id,
  }));

  const languageOptions = [
    {
      key: "en",
      text: "English",
      value: "en",
    },
  ];

  const { users } = useSelector((state: RootState) => state.users);

  const handleOpen = (tenant: TenantResponse | null) => {
    setCurrentTenant(tenant);
    setTenantName(tenant ? tenant.tenantName : "");
    setLanguage(tenant ? tenant.language : "");
    setPowerBIWorkspace(tenant ? tenant.powerBIWorkspace : []);
    setNumberOfTeams(tenant ? tenant.numberOfTeams : "");
    setNumberOfUsers(tenant ? tenant.numberOfUsers : "");
    setState({ open: true, size: "tiny" });
  };

  const handleClose = () => {
    setState({ open: false, size: undefined });
    setTenantName("");
    setLanguage("");
    setPowerBIWorkspace([]);
    setNumberOfTeams("");
    setNumberOfUsers("");
    setCurrentTenant(null);
  };

  const handleSave = () => {
    if (currentTenant) {
      dispatch(
        editTenant({
          tenantId: currentTenant.id,
          tenantData: {
            tenantName,
            language,
            numberOfTeams,
            numberOfUsers,
            powerBIWorkspace,
            isSuperTenant: false,
            tenantStatus: "created",
          },
        })
      )
        .then((res) => {
          if (res.type === "tenants/editTenant/fulfilled") {
            toast.success("Tenant edited successfully");
          } else if (res.type === "tenants/editTenant/rejected") {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      dispatch(
        addTenant({
          tenantName,
          language,
          numberOfTeams,
          numberOfUsers,
          powerBIWorkspace,
          isSuperTenant: false,
          tenantStatus: "created",
        })
      )
        .then((res) => {
          if (res.type === "tenants/addTenant/fulfilled") {
            toast.success("Tenant added successfully");
          } else if (res.type === "tenants/addTenant/rejected") {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleDelete = () => {
    if (currentTenant) {
      dispatch(deleteTenant(currentTenant.id))
        .then((res) => {
          if (res.type === "tenants/deleteTenant/fulfilled") {
            toast.success("Tenant deleted successfully");
          } else if (res.type === "tenants/deleteTenant/rejected") {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const openConfirm = (tenant: TenantResponse | null) => {
    setCurrentTenant(tenant);
    setConfirmOpen(true);
  };

  const openSuspendConfirm = (tenant: TenantResponse | null) => {
    setCurrentTenant(tenant);
    setConfirmSuspendOpen(true);
  };

  const openActivateConfirm = (tenant: TenantResponse | null) => {
    setCurrentTenant(tenant);
    setConfirmActivateOpen(true);
  };

  const openChangeDefaultConfirm = (tenant: TenantResponse | null) => {
    setCurrentTenant(tenant);
    setConfirmChangeDefaultOpen(true);
  };

  const handleConfirmCancel = () => {
    setConfirmOpen(false);
    setCurrentTenant(null);
  };

  const handleSuspendConfirmCancel = () => {
    setConfirmSuspendOpen(false);
    setCurrentTenant(null);
  };

  const handleActivateConfirmCancel = () => {
    setConfirmActivateOpen(false);
    setCurrentTenant(null);
  };

  const handleChangeDefaultCancel = () => {
    setConfirmChangeDefaultOpen(false);
    setCurrentTenant(null);
  };

  const handleAssignUser = (tenantId: number) => {
    setCurrentTenant({ id: tenantId });
    setAssignUserModalOpen(true);
  };

  const handleAssignUserSave = () => {
    if (currentTenant && selectedUserId) {
      dispatch(
        assignUserToTenant({
          userId: selectedUserId,
          tenantId: currentTenant.id.toString(),
        })
      )
        .then((res) => {
          if (res.type === "tenants/assignUserToTenant/fulfilled") {
            toast.success("User assigned successfully");
            setAssignUserModalOpen(false);
            setSelectedUserId(null);
          } else if (res.type === "tenants/assignUserToTenant/rejected") {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleSuspendTenant = () => {
    if (currentTenant) {
      dispatch(
        suspendTenant({
          tenantId: currentTenant.id.toString(),
        })
      )
        .then((res) => {
          if (res.type === "tenants/suspendTenant/fulfilled") {
            toast.success("User Suspended successfully");
            setConfirmSuspendOpen(false);
            setSelectedUserId(null);
          } else if (res.type === "tenants/suspendTenant/rejected") {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleChangeDefaultTenant = () => {
    if (currentTenant) {
      dispatch(
        changeDefaultTenant({
          tenantId: currentTenant.id.toString(),
        })
      )
        .then((res) => {
          if (res.type === "tenants/changeDefaultTenant/fulfilled") {
            toast.success("Default Tenant changed successfully");
            setConfirmChangeDefaultOpen(false);
            setSelectedUserId(null);
          } else if (res.type === "tenants/changeDefaultTenant/rejected") {
            setConfirmChangeDefaultOpen(false);
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleActivateTenant = () => {
    if (currentTenant) {
      dispatch(
        activateTenant({
          tenantId: currentTenant.id.toString(),
        })
      )
        .then((res) => {
          if (res.type === "tenants/activateTenant/fulfilled") {
            toast.success("User Activated successfully");
            setConfirmActivateOpen(false);
            setSelectedUserId(null);
          } else if (res.type === "tenants/activateTenant/rejected") {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  useEffect(() => {
    if (
      !deleteLoading &&
      !editLoading &&
      !addLoading &&
      !assignLoading &&
      (state.open || confirmOpen)
    ) {
      handleClose();
      handleConfirmCancel();
    }
  }, [deleteLoading, editLoading, addLoading, assignLoading]);

  return (
    <div>
      <DefaultModal
        title={currentTenant ? "Edit Tenant" : "Add Tenant"}
        buttonTitle="Save"
        open={state.open}
        size={state.size}
        centered={false}
        dimmer="default"
        onClose={handleClose}
        onSave={handleSave}
        loading={addLoading || editLoading}
      >
        <Form>
          <FormGroup widths="equal">
            <FormInput
              fluid
              label="Tenant Name"
              placeholder="Enter tenant name"
              value={tenantName}
              onChange={(e) => setTenantName(e.target.value)}
            />
          </FormGroup>
          <FormGroup widths="equal">
            <Dropdown
              fluid
              className="mx-2"
              label="Language"
              placeholder="Language"
              selection
              options={languageOptions}
              value={language}
              onChange={(e, { value }) => setLanguage(value as string)}
            />
          </FormGroup>
          <FormGroup widths="equal">
            <FormInput
              label="Number of Teams"
              name="numberOfTeams"
              value={numberOfTeams}
              type="number"
              onChange={(e) => setNumberOfTeams(e.target.value)}
              required
            />
            <FormInput
              label="Number of Users"
              name="numberOfUsers"
              type="number"
              value={numberOfUsers}
              onChange={(e) => setNumberOfUsers(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup widths="equal">
            <Dropdown
              placeholder="Power BI Workspace"
              fluid
              className="mx-2"
              multiple
              selection
              options={workspaceOptions}
              value={powerBIWorkspace}
              onChange={(e, { value }) =>
                setPowerBIWorkspace(value as string[])
              }
            />
          </FormGroup>
        </Form>
      </DefaultModal>
      <DefaultModal
        title="Assign User to Tenant"
        buttonTitle="Assign"
        open={assignUserModalOpen}
        size="tiny"
        centered={false}
        dimmer="default"
        onClose={() => setAssignUserModalOpen(false)}
        onSave={handleAssignUserSave}
        loading={assignLoading}
      >
        <Form>
          <FormGroup widths="equal">
            <Dropdown
              placeholder="Select User"
              fluid
              selection
              options={users.map((user) => ({
                key: user.id,
                text: user.firstName + " " + user.lastName,
                value: user.id,
              }))}
              onChange={(e, { value }: DropdownProps) =>
                setSelectedUserId(value as string)
              }
            />
          </FormGroup>
        </Form>
      </DefaultModal>
      <Confirm
        open={confirmOpen}
        onCancel={handleConfirmCancel}
        onConfirm={handleDelete}
        size="tiny"
        content="Are you sure you want to delete this tenant?"
        confirmButton={{ content: "Delete", loading: deleteLoading }}
      />
      <Confirm
        open={confirmSuspendOpen}
        onCancel={handleSuspendConfirmCancel}
        onConfirm={handleSuspendTenant}
        size="tiny"
        content="Are you sure do you want to suspend this tenant? every user under this tenant will not able to login"
        confirmButton={{ content: "Suspend", loading: deleteLoading }}
      />
      <Confirm
        open={confirmActivateOpen}
        onCancel={handleActivateConfirmCancel}
        onConfirm={handleActivateTenant}
        size="tiny"
        content="Are you sure do you want to activate this tenant?"
        confirmButton={{ content: "Activate", loading: deleteLoading }}
      />
      <Confirm
        open={confirmChangeDefaultOpen}
        onCancel={handleChangeDefaultCancel}
        onConfirm={handleChangeDefaultTenant}
        size="tiny"
        content="Are you sure do you want to change default tenant? when try to login you will be redirected to your default tenant"
        confirmButton={{ content: "Change", loading: deleteLoading }}
      />
      <div className="m-3">
        <div className="bg-white border shadow-sm rounded-lg p-6">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h2 className="text-xl font-semibold">Tenants</h2>
              <p className="text-gray-600 text-sm -mt-4">Manage Your Tenants</p>
            </div>
            <Button primary size="tiny" onClick={() => handleOpen(null)}>
              <div className="flex space-x-2">
                <MdAdd className="mr-2" />
                Add New Tenant
              </div>
            </Button>
          </div>
          <div className="my-5">
            <Input icon="search" placeholder="Search..." />
          </div>
          <div>
            <Table basic="very">
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Name</TableHeaderCell>
                  <TableHeaderCell width={"5"}>
                    Power BI Workspace
                  </TableHeaderCell>
                  <TableHeaderCell>Teams</TableHeaderCell>
                  <TableHeaderCell>Users</TableHeaderCell>
                  <TableHeaderCell width={"2"}>Status</TableHeaderCell>
                  <TableHeaderCell>Action</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan="6">
                      <SkeletonTable />
                    </TableCell>
                  </TableRow>
                ) : (
                  tenants.map((tenant) => (
                    <TableRow key={tenant.id}>
                      <TableCell>{tenant.tenantName} </TableCell>
                      <TableCell>{tenant.powerBIWorkspace}</TableCell>
                      <TableCell>{tenant.numberOfTeams}</TableCell>
                      <TableCell>{tenant.numberOfUsers}</TableCell>
                      <TableCell>
                        <span
                          className={`border rounded-xl px-2 ${
                            tenant.isActive
                              ? "border-green-500 text-green-500"
                              : "border-red-500 text-red-500"
                          } `}
                        >
                          {tenant.isActive ? "Active" : "Suspended"}
                        </span>
                      </TableCell>
                      <TableCell>
                        <Dropdown trigger={<BsThreeDots />} icon={null}>
                          <DropdownMenu>
                            <DropdownItem
                              icon="pencil alternate"
                              text="Edit"
                              onClick={() => handleOpen(tenant)}
                            />
                            <DropdownItem
                              icon="trash"
                              text="Delete"
                              onClick={() => openConfirm(tenant)}
                            />
                            <DropdownItem
                              onClick={() => handleAssignUser(tenant.id)}
                              icon="user"
                              text="Assign User"
                            />
                            {!tenant.isActive ? (
                              <DropdownItem
                                onClick={() => openActivateConfirm(tenant)}
                                icon="check"
                                text="Activate"
                              />
                            ) : (
                              <DropdownItem
                                onClick={() => openSuspendConfirm(tenant)}
                                icon="ban"
                                text="Suspend"
                              />
                            )}
                            {tenant.isActive && (
                              <DropdownItem
                                onClick={() => openChangeDefaultConfirm(tenant)}
                                icon="exchange"
                                text="Make Default"
                              />
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantAdmin;
