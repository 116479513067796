import React, { useEffect, useRef, useState } from "react";
import * as powerbiClient from "powerbi-client";
import * as models from "powerbi-models";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { getContentPageById } from "../store/slices/contentPageSlice";
import {
  fetchEmbedToken,
  setEmbeddedReport,
  clearEmbeddedReport,
} from "../store/slices/embedTokenSlice";
import CustomLoader from "../components/constants/CustomLoader";
import HeaderContainer from "../components/constants/HeaderContainer";
import "./report.css";
const PowerBIReport: React.FC = () => {
  const embedContainer = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [loaded, setLoaded] = useState(false);
  const [rendered, setRendered] = useState(false);
  const { reportID } = useParams<{ reportID: string }>();
  const { contentPage, loading } = useSelector(
    (state: RootState) => state.contentPages
  );
  const {
    embedToken,
    loading: embedLoading,
    embeddedReport,
  } = useSelector((state: RootState) => state.embedToken);
  const user = useSelector((state: RootState) => state.users.user);

  useEffect(() => {
    if (reportID) {
      dispatch(getContentPageById(Number(reportID)));
    }
  }, [reportID, dispatch]);

  useEffect(() => {
    if (contentPage) {
      dispatch(
        fetchEmbedToken({
          groupId: contentPage.PowerBiWorkspace || "",
          reportId: contentPage.PagePath || "",
        })
      );
    }
  }, [contentPage, dispatch]);

  useEffect(() => {
    const embedPowerBIReport = async () => {
      const powerbi = window["powerbi"];
      if (!powerbi) {
        console.error("Power BI SDK is not loaded");
        return;
      }

      if (contentPage && embedToken) {
        const EmbedUrl = contentPage.EmbedUrl;
        const embedReportId = contentPage.PagePath;
        const tokenType = models.TokenType.Embed;
        const permissions: models.Permissions = models.Permissions.All;

        const config: models.IReportEmbedConfiguration = {
          type: "report",
          tokenType: tokenType,
          accessToken: embedToken.token,
          embedUrl: EmbedUrl,
          id: embedReportId,
          permissions: permissions,
          settings: {
            panes: {
              filters: {
                visible: contentPage.ShowFilter,
              },
              pageNavigation: {
                visible: contentPage.ShowContentPane,
              },
            },

            // layoutType: contentPage.HideTitleAnddescription
            //   ? models.LayoutType.Custom
            //   : undefined,
            // customLayout: {
            //   displayOption: contentPage.HideTitleAnddescription
            //     ? models.DisplayOption.FitToWidth
            //     : undefined,
            //   pageSize: contentPage.HideTitleSection
            //     ? { type: models.PageSizeType.Widescreen }
            //     : undefined,
            // },
            // navContentPaneEnabled: contentPage.ShowSharingButton ?? false,
          },
        };

        if (embedContainer.current) {
          const embeddedReport = powerbi.embed(
            embedContainer.current,
            config
          ) as powerbiClient.Report;
          dispatch(setEmbeddedReport(embeddedReport));

          embeddedReport.off("loaded");
          embeddedReport.on("loaded", function () {
            setLoaded(true);
          });

          embeddedReport.off("error");
          embeddedReport.on(
            "error",
            function (event: powerbiClient.service.ICustomEvent<any>) {
              console.error(event.detail);
            }
          );

          embeddedReport.off("rendered");
          embeddedReport.on("rendered", function () {
            setRendered(true);
          });
        }
      }
    };

    if (contentPage && embedToken) {
      embedPowerBIReport();
    }
  }, [contentPage, embedToken, dispatch]);

  return (
    <div className="relative w-full h-full">
      {embedLoading || loading ? (
        <CustomLoader height={70} />
      ) : (
        <div className="w-full">
          {contentPage?.HideTitleAnddescription ? (
            <div></div>
          ) : (
            <HeaderContainer
              title={contentPage?.Title || ""}
              description={contentPage?.Description || ""}
            />
          )}
          <div
            className={`absolute right-0 left-0 ${
              !contentPage?.HideTitleAnddescription
                ? contentPage?.Title && contentPage?.Description
                  ? "top-16 bottom-0"
                  : "top-10 bottom-0"
                : "top-0 bottom-0"
            }`}
            id="embedContainer"
            ref={embedContainer}
          />
        </div>
      )}
    </div>
  );
};

export default PowerBIReport;
