import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

// Define interface for tenant
interface Tenant {
  tenantName: string;
  tenantStatus: string;
  isSuperTenant: boolean;
  language: string;
  numberOfTeams: string;
  numberOfUsers: string;
  powerBIWorkspace: string[];
}

export interface TenantResponse {
  id: number;
  isActive: boolean;
  tenantName: string;
  tenantStatus: string;
  isSuperTenant: boolean;
  language: string;
  numberOfTeams: string;
  numberOfUsers: string;
  powerBIWorkspace: string[];
  updatedAt: string;
  createdAt: string;
}

interface TenantState {
  tenants: TenantResponse[];
  loading: boolean;
  error: string | null;
  addLoading: boolean;
  editLoading: boolean;
  deleteLoading: boolean;
  assignLoading: boolean;
}

const initialState: TenantState = {
  tenants: [],
  loading: false,
  error: null,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  assignLoading: false,
};

const apiUrl = "https://cedarplatform.io:4400/api/v1/tenants";

const getHeaders = (getState: () => RootState) => {
  const token = getState().authentication.tokens?.token;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

// Define async thunk to fetch tenants with token handling
export const fetchTenants = createAsyncThunk<
  TenantResponse[],
  void,
  { state: RootState; rejectValue: string }
>("tenants/fetchTenants", async (_, { getState, rejectWithValue }) => {
  try {
    const response = await axios.get<TenantResponse[]>(
      apiUrl,
      getHeaders(getState)
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to fetch tenants");
  }
});

export const changeDefaultTenant = createAsyncThunk<
  void,
  { tenantId: string },
  { state: RootState; rejectValue: string }
>(
  "tenants/changeDefaultTenant",
  async ({ tenantId }, { getState, rejectWithValue }) => {
    try {
      await axios.put(
        `${apiUrl}/change-default-tenant`,
        { tenantId },
        getHeaders(getState)
      );
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to change default tenant");
    }
  }
);

// Define async thunk to add a new tenant with token handling
export const addTenant = createAsyncThunk<
  TenantResponse,
  Tenant,
  { state: RootState; rejectValue: string }
>("tenants/addTenant", async (tenantData, { getState, rejectWithValue }) => {
  try {
    const response = await axios.post<{ tenant: TenantResponse }>(
      apiUrl,
      tenantData,
      getHeaders(getState)
    );
    return response.data.tenant;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to add tenant");
  }
});

// Define async thunk to edit a tenant with token handling
export const editTenant = createAsyncThunk<
  TenantResponse,
  { tenantId: number; tenantData: Tenant },
  { state: RootState; rejectValue: string }
>(
  "tenants/editTenant",
  async ({ tenantId, tenantData }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.put<TenantResponse>(
        `${apiUrl}/${tenantId}`,
        tenantData,
        getHeaders(getState)
      );
      return response.data;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to edit tenant");
    }
  }
);

export const suspendTenant = createAsyncThunk<
  void,
  { tenantId: string },
  { state: RootState; rejectValue: string }
>(
  "tenants/suspendTenant",
  async ({ tenantId }, { getState, rejectWithValue }) => {
    try {
      await axios.put(
        `${apiUrl}/suspend-tenant`,
        { tenantId },
        getHeaders(getState)
      );
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to suspend tenant");
    }
  }
);

export const activateTenant = createAsyncThunk<
  void,
  { tenantId: string },
  { state: RootState; rejectValue: string }
>(
  "tenants/activateTenant",
  async ({ tenantId }, { getState, rejectWithValue }) => {
    try {
      await axios.put(
        `${apiUrl}/activate-tenant`,
        { tenantId },
        getHeaders(getState)
      );
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to activate tenant");
    }
  }
);

// Define async thunk to delete a tenant with token handling
export const deleteTenant = createAsyncThunk<
  number,
  number,
  { state: RootState; rejectValue: string }
>("tenants/deleteTenant", async (tenantId, { getState, rejectWithValue }) => {
  try {
    await axios.delete(`${apiUrl}/${tenantId}`, getHeaders(getState));
    return tenantId;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to delete tenant");
  }
});

// Define async thunk to assign a user to a tenant
export const assignUserToTenant = createAsyncThunk<
  void,
  { userId: string; tenantId: string },
  { state: RootState; rejectValue: string }
>(
  "tenants/assignUserToTenant",
  async ({ userId, tenantId }, { getState, rejectWithValue }) => {
    try {
      await axios.put(
        `${apiUrl}/assign-user`,
        { userId, tenantId },
        getHeaders(getState)
      );
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to assign user to tenant");
    }
  }
);

export const switchTenant = createAsyncThunk<
  void,
  { tenantId: string },
  { state: RootState; rejectValue: string }
>(
  "tenants/switchTenant",
  async ({ tenantId }, { getState, rejectWithValue }) => {
    try {
      await axios.put(
        `${apiUrl}/switch-user`,
        { tenantId },
        getHeaders(getState)
      );
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to assign user to tenant");
    }
  }
);

// Create a slice for tenants
const tenantSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchTenants
      .addCase(fetchTenants.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTenants.fulfilled, (state, action) => {
        state.loading = false;
        state.tenants = action.payload;
      })
      .addCase(fetchTenants.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || action.error.message || "Failed to fetch tenants";
      })
      // Handle addTenant
      .addCase(addTenant.pending, (state) => {
        state.addLoading = true;
        state.error = null;
      })
      .addCase(addTenant.fulfilled, (state, action) => {
        state.addLoading = false;
        state.tenants.push(action.payload);
      })
      .addCase(addTenant.rejected, (state, action) => {
        state.addLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to add tenant";
      })
      // Handle editTenant
      .addCase(editTenant.pending, (state) => {
        state.editLoading = true;
        state.error = null;
      })
      .addCase(editTenant.fulfilled, (state, action) => {
        state.editLoading = false;
        const editedTenantIndex = state.tenants.findIndex(
          (tenant) => tenant.id === action.payload.id
        );
        if (editedTenantIndex !== -1) {
          state.tenants[editedTenantIndex] = action.payload;
        }
      })
      .addCase(editTenant.rejected, (state, action) => {
        state.editLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to edit tenant";
      })
      // Handle deleteTenant
      .addCase(deleteTenant.pending, (state) => {
        state.deleteLoading = true;
        state.error = null;
      })
      .addCase(deleteTenant.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.tenants = state.tenants.filter(
          (tenant) => tenant.id !== action.payload
        );
      })
      .addCase(deleteTenant.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to delete tenant";
      })
      // Handle assignUserToTenant
      .addCase(assignUserToTenant.pending, (state) => {
        state.assignLoading = true;
        state.error = null;
      })
      .addCase(assignUserToTenant.fulfilled, (state) => {
        state.assignLoading = false;
      })
      .addCase(assignUserToTenant.rejected, (state, action) => {
        state.assignLoading = false;
        state.error =
          action.payload ||
          action.error.message ||
          "Failed to assign user to tenant";
      })
      .addCase(suspendTenant.pending, (state) => {
        state.deleteLoading = true;
        state.error = null;
      })
      .addCase(suspendTenant.fulfilled, (state, action) => {
        state.deleteLoading = false;
        const tenantId = action.meta.arg.tenantId;
        const tenantIndex = state.tenants.findIndex(
          (tenant) => tenant.id === parseInt(tenantId, 10)
        );
        if (tenantIndex !== -1) {
          state.tenants[tenantIndex].isActive = false;
        }
      })
      .addCase(suspendTenant.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to suspend tenant";
      })
      .addCase(activateTenant.pending, (state) => {
        state.deleteLoading = true;
        state.error = null;
      })
      .addCase(activateTenant.fulfilled, (state, action) => {
        state.deleteLoading = false;
        const tenantId = action.meta.arg.tenantId;
        const tenantIndex = state.tenants.findIndex(
          (tenant) => tenant.id === parseInt(tenantId, 10)
        );
        if (tenantIndex !== -1) {
          state.tenants[tenantIndex].isActive = true;
        }
      })
      .addCase(activateTenant.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to activate tenant";
      })
      .addCase(changeDefaultTenant.pending, (state) => {
        state.deleteLoading = true;
        state.error = null;
      })
      .addCase(changeDefaultTenant.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(changeDefaultTenant.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error =
          action.payload ||
          action.error.message ||
          "Failed to change default tenant";
      });
  },
});

export default tenantSlice.reducer;
