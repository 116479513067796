import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Confirm,
  Input,
  Button,
  Icon,
  Modal,
  Dropdown as SemanticDropdown,
} from "semantic-ui-react";
import { BsThreeDots } from "react-icons/bs";
import toast from "react-hot-toast";
import { AppDispatch, RootState } from "../../../../../store/store";
import { fetchTeamUsers } from "../../../../../store/slices/teamUsersSlice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SkeletonTable } from "../../../../constants/SkeletonTable";
import { deleteUser } from "../../../../../store/slices/userSlice";
import {
  assignUser,
  fetchUnassignedUsers,
} from "../../../../../store/slices/teamSlice";

const TeamUsers = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { teamId } = useParams<{ teamId: string }>();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<number | null>(null);
  const [teamName] = useSearchParams();
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [assignUserOpen, setAssignUserOpen] = useState(false);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchTeamUsers(Number(teamId)));
      dispatch(fetchUnassignedUsers(Number(teamId)));
    }
  }, [dispatch, teamId, assignUserOpen]);

  const { users: teamUsers, loading: teamUsersLoading } = useSelector(
    (state: RootState) => state.teamUsers
  );

  const { assignUserLoading, unassignedUsers } = useSelector(
    (state: RootState) => state.teams
  );

  const { deleteLoading } = useSelector((state: RootState) => state.users);

  const openConfirm = (userId: number) => {
    setUserToDelete(userId);
    setConfirmOpen(true);
  };

  const handleAssignUsersToTeam = () => {
    if (selectedUsers.length > 0) {
      dispatch(assignUser({ userIds: selectedUsers, teamId: Number(teamId) }))
        .then((res) => {
          if (res.type === "team/assignTeam/fulfilled") {
            toast.success("Users assigned successfully");
            setAssignUserOpen(false);
            setSelectedUsers([]);
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      toast.error("Please select at least one user.");
    }
  };

  const handleConfirm = () => {
    if (userToDelete !== null) {
      dispatch(deleteUser(userToDelete))
        .then((res) => {
          if (res.type === "users/deleteUser/fulfilled") {
            toast.success("User deleted successfully");
            setConfirmOpen(false);
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleCancel = () => {
    setConfirmOpen(false);
    setUserToDelete(null);
  };

  const userOptions = unassignedUsers
    ?.filter((user) => !user.isSuperTenant)
    .map((user) => ({
      key: user.id,
      text: `${user.firstName} ${user.lastName}`,
      value: user.id,
    }));

  return (
    <div>
      <Confirm
        open={confirmOpen}
        size="tiny"
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        content="Are you sure you want to delete this user?"
        confirmButton={{ content: "Delete", loading: deleteLoading }}
      />
      <div className="m-3">
        <div className="bg-white border shadow-sm rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div>
              <Button size="tiny" onClick={() => navigate(-1)}>
                <Icon name="arrow left" />
              </Button>
              <span className="text-xl font-semibold ml-2">
                {teamName.get("teamName")}
              </span>
            </div>
            <div>
              <Button
                size="tiny"
                primary
                onClick={() => setAssignUserOpen(true)}
              >
                <Icon name="plus" />
                Assign Users to Team
              </Button>
            </div>
          </div>
          <div className="my-5">
            <Input icon="search" placeholder="Search..." />
          </div>
          <div>
            {teamUsersLoading ? (
              <SkeletonTable />
            ) : (
              <Table basic="very">
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>Full Name</TableHeaderCell>
                    <TableHeaderCell>Email</TableHeaderCell>
                    <TableHeaderCell>Actions</TableHeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {teamUsers.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{`${user.firstName} ${user.lastName}`}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <Dropdown trigger={<BsThreeDots />} icon={null}>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => openConfirm(user.id)}
                              icon="trash"
                              text="Delete"
                            />
                          </DropdownMenu>
                        </Dropdown>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={assignUserOpen}
        size="tiny"
        onClose={() => setAssignUserOpen(false)}
      >
        <Modal.Header>Select Users to Assign</Modal.Header>
        <Modal.Content>
          <SemanticDropdown
            placeholder="Select Users"
            fluid
            multiple
            search
            selection
            options={userOptions}
            onChange={(e, { value }) => setSelectedUsers(value as number[])}
            value={selectedUsers}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setAssignUserOpen(false)}>Cancel</Button>
          <Button
            primary
            onClick={handleAssignUsersToTeam}
            disabled={selectedUsers.length === 0}
            loading={assignUserLoading}
          >
            Assign to Team
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default TeamUsers;
