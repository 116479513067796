import React, { useEffect } from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Navbar from "../components/header/Navbar";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "../components/reports/Home";
import PowerBIReport from "../Reports/PowerBIReport";
import OverviewPage from "./OverviewPage";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const ReportPage = () => {
  const { tokens } = useSelector((state: RootState) => state.authentication);
  const navigate = useNavigate();

  useEffect(() => {
    if (!tokens) {
      navigate("/");
    }
  }, [tokens]);

  return (
    <div className="w-full flex bg-[var(--side-navigation-panel-primary)]">
      <Sidebar />
      <div className="w-full rounded-3xl overflow-hidden m-4 bg-[var(--report-pane-background)]">
        <div className="flex flex-col h-full w-full">
          <Navbar />
          <div className="pt-2 w-full h-full">
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route
                path="/contentPage/:reportID"
                element={<PowerBIReport />}
              ></Route>
              <Route
                path="/contentPage/OverviewPage"
                element={<OverviewPage />}
              ></Route>
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPage;
