import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

// Define the data type based on the response
interface BrandingData {
  id: number;
  logoImage: string;
  siteFaviconImage: string;
  customLoader: string;
  loginLogoutBackground: string;
  showFooter: boolean;
  secondaryLogoImage: string;
  externalLinks: string;
  TenantId: number;
}

interface BrandingState {
  branding: BrandingData | null;
  loading: boolean;
  error: string | null;
}

const initialState: BrandingState = {
  branding: null,
  loading: false,
  error: null,
};

const getHeaders = (getState: () => RootState) => {
  const token = getState().authentication.tokens?.token;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const fetchBranding = createAsyncThunk<
  BrandingData,
  void,
  { state: RootState }
>("branding/fetchBranding", async (_, { getState, rejectWithValue }) => {
  try {
    const response = await axios.get<BrandingData[]>(
      "https://cedarplatform.io:4400/api/v1/branding",
      getHeaders(getState)
    );
    if (response.data.length > 0) {
      return response.data[0];
    } else {
      throw new Error("No branding data found");
    }
  } catch (error) {
    return rejectWithValue("Failed to fetch branding data");
  }
});

export const editBranding = createAsyncThunk<
  BrandingData,
  { formData: FormData },
  { state: RootState }
>(
  "branding/editBranding",
  async ({ formData }, { getState, rejectWithValue }) => {
    try {
      const token = getState().authentication.tokens?.token;
      const response = await axios.put<{ data: BrandingData }>(
        `https://cedarplatform.io:4400/api/v1/branding`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue("Failed to edit branding data");
    }
  }
);

const brandingSlice = createSlice({
  name: "branding",
  initialState,
  reducers: {
    setBranding: (state, action: PayloadAction<BrandingData>) => {
      state.branding = action.payload;
    },
    clearBranding: (state) => {
      state.branding = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBranding.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBranding.fulfilled, (state, action) => {
        state.loading = false;
        state.branding = action.payload;
      })
      .addCase(fetchBranding.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(editBranding.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editBranding.fulfilled, (state, action) => {
        state.loading = false;
        state.branding = action.payload;
      })
      .addCase(editBranding.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setBranding, clearBranding } = brandingSlice.actions;
export default brandingSlice.reducer;
