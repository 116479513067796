import { useState } from "react";
import { Input } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { createPassword, resetPassword } from "../../store/slices/userSlice";
import toast from "react-hot-toast";
import { AppDispatch } from "../../store/store";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams();

  const handleResetPassword = () => {
    setError("");
    setLoading(true);

    // Validate form fields
    if (!newPassword || !confirmPassword) {
      setError("All fields are required");
      setLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    // Dispatch reset password action
    dispatch(
      createPassword({
        token: String(params?.token),
        newPassword,
      })
    )
      .then((res) => {
        if (res.type === "users/createPassword/fulfilled") {
          toast.success(
            "Password reset successfully. Please log in with your new password."
          );
          navigate("/");
        } else {
          throw new Error(res.payload as string);
        }
      })
      .catch((error: any) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="p-10 w-full h-screen">
      <div className="text-3xl font-bold">
        <span className="text-primary">CEDAR</span>
        <span className="text-secondary">STREET</span>
      </div>
      <div className="w-full h-full flex items-center justify-center gap-10">
        <div className="flex flex-col gap-8 w-[30rem]">
          <div>
            <h1 className="text-3xl font-bold">Reset Your Password</h1>
            <p>
              Please enter your new password below. Make sure the passwords
              match before submitting to reset your password.
            </p>
          </div>
          <div>
            <Input
              fluid
              type="password"
              icon="lock"
              iconPosition="left"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              disabled={loading}
            />
          </div>
          <div>
            <Input
              fluid
              type="password"
              icon="lock"
              iconPosition="left"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={loading}
            />
          </div>
          {error && <p className="text-red-500">{error}</p>}
          <div>
            <button
              type="submit"
              className={`border-2 w-full bg-primary border-primary text-white rounded-full px-12 py-4 inline-block font-semibold`}
              disabled={loading}
              onClick={handleResetPassword}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
