import React, { useState } from "react";
import HeaderContainer from "../constants/HeaderContainer";
import ThemeLayout from "./components/ThemeLayout";
import Branding from "./components/Branding";
import Colors from "./components/Colors";
import Fonts from "./components/Fonts";

const Theme = () => {
  const [activeTab, setActiveTab] = useState("Branding");

  return (
    <div>
      <HeaderContainer
        title="Edit Theme"
        description="Manage site logo image, color and font"
        tabs={["Branding", "Theme & Layout", "Colors"]}
        activeTab={activeTab}
        onTabClick={setActiveTab}
      />
      <div>
        {activeTab === "Branding" && <Branding />}
        {activeTab === "Theme & Layout" && <ThemeLayout />}
        {activeTab === "Colors" && <Colors />}
      </div>
    </div>
  );
};

export default Theme;
