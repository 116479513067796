import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

export interface Capacity {
  id: string;
  displayName: string;
  admins: string[];
  sku: string;
  state: string;
  capacityUserAccessRight: string;
  region: string;
  users: string[];
}

interface EmbedTimeout {
  id: number;
  selectedCapacity: string;
  embeddedTimeout: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Schedule {
  id: number;
  startHour: string;
  startMinute: string;
  period: string;
  durationHours: number;
  durationMinutes: number;
  isEnabled: boolean;
}

interface CapacityState {
  capacities: Capacity[];
  schedules: Schedule[];
  loading: boolean;
  embedTimeouts: EmbedTimeout[];
  error: string | null;
}

const initialState: CapacityState = {
  capacities: [],
  schedules: [],
  embedTimeouts: [],
  loading: false,
  error: null,
};

export const fetchCapacities = createAsyncThunk<
  Capacity[],
  void,
  { state: RootState }
>("capacities/fetchCapacities", async (_, { getState }) => {
  const token = getState().authentication.tokens?.token;
  try {
    const response = await axios.get<Capacity[]>(
      "https://cedarplatform.io:4400/api/v1/workspaces/capabilities",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch capacities");
  }
});

export const fetchSchedules = createAsyncThunk<
  Schedule[],
  void,
  { state: RootState }
>("schedules/fetchSchedules", async (_, { getState }) => {
  const token = getState().authentication.tokens?.token;
  try {
    const response = await axios.get<Schedule[]>(
      "https://cedarplatform.io:4400/api/v1/capacity/schedule",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch schedules");
  }
});

export const getEmbedTimeout = createAsyncThunk<
  EmbedTimeout[],
  void,
  { state: RootState }
>("capacities/getEmbedTimeout", async (_, { getState }) => {
  const token = getState().authentication.tokens?.token;
  try {
    const response = await axios.get<EmbedTimeout[]>(
      "https://cedarplatform.io:4400/api/v1/capacity",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch embed timeouts");
  }
});

export const updateEmbedTimeout = createAsyncThunk<
  void,
  { id: string; timeout: string },
  { state: RootState }
>("capacities/updateEmbedTimeout", async ({ id, timeout }, { getState }) => {
  const token = getState().authentication.tokens?.token;
  try {
    await axios.put(
      `https://cedarplatform.io:4400/api/v1/capacity`,
      { embeddedTimeout: timeout },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw new Error("Failed to update embed timeout");
  }
});

export const addSchedule = createAsyncThunk<
  Schedule,
  {
    startHour: string;
    startMinute: string;
    period: string;
    durationHours: number;
    durationMinutes: number;
    isEnabled: boolean;
  },
  { state: RootState }
>(
  "capacities/addSchedule",
  async (
    {
      startHour,
      startMinute,
      period,
      durationHours,
      durationMinutes,
      isEnabled,
    },
    { getState }
  ) => {
    const token = getState().authentication.tokens?.token;
    try {
      const response = await axios.post<Schedule>(
        `https://cedarplatform.io:4400/api/v1/capacity/schedule`,
        {
          startHour,
          startMinute,
          period,
          durationHours,
          durationMinutes,
          isEnabled,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to add schedule");
    }
  }
);

export const deleteSchedule = createAsyncThunk<
  void,
  { scheduleId: number },
  { state: RootState }
>("capacities/deleteSchedule", async ({ scheduleId }, { getState }) => {
  const token = getState().authentication.tokens?.token;
  try {
    await axios.delete(
      `https://cedarplatform.io:4400/api/v1/capacity/schedule/${scheduleId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    throw new Error("Failed to delete schedule");
  }
});

export const updateSchedule = createAsyncThunk<
  Schedule,
  {
    scheduleId: number;
    startHour: string;
    startMinute: string;
    period: string;
    durationHours: number;
    durationMinutes: number;
    isEnabled: boolean;
  },
  { state: RootState }
>(
  "capacities/updateSchedule",
  async (
    {
      scheduleId,
      startHour,
      startMinute,
      period,
      durationHours,
      durationMinutes,
      isEnabled,
    },
    { getState }
  ) => {
    const token = getState().authentication.tokens?.token;
    try {
      const response = await axios.put<{ data: Schedule }>(
        `https://cedarplatform.io:4400/api/v1/capacity/schedule/${scheduleId}`,
        {
          startHour,
          startMinute,
          period,
          durationHours,
          durationMinutes,
          isEnabled,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      throw new Error("Failed to update schedule");
    }
  }
);

const capacitySlice = createSlice({
  name: "capacities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Capacities
      .addCase(fetchCapacities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCapacities.fulfilled, (state, action) => {
        state.loading = false;
        state.capacities = action.payload;
      })
      .addCase(fetchCapacities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch capacities";
      })

      // Fetch Schedules
      .addCase(fetchSchedules.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSchedules.fulfilled, (state, action) => {
        state.loading = false;
        state.schedules = action.payload;
      })
      .addCase(fetchSchedules.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch schedules";
      })

      // Get Embed Timeout
      .addCase(getEmbedTimeout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEmbedTimeout.fulfilled, (state, action) => {
        state.loading = false;
        state.embedTimeouts = action.payload;
      })
      .addCase(getEmbedTimeout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch embed timeouts";
      })

      // Update Embed Timeout
      .addCase(updateEmbedTimeout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEmbedTimeout.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateEmbedTimeout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to update embed timeout";
      })

      // Add Schedule
      .addCase(addSchedule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.schedules.push(action.payload);
      })
      .addCase(addSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to add schedule";
      })

      // Delete Schedule
      .addCase(deleteSchedule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.schedules = state.schedules.filter(
          (schedule) => schedule.id !== action.meta.arg.scheduleId
        );
      })
      .addCase(deleteSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to delete schedule";
      })

      // Update Schedule
      .addCase(updateSchedule.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSchedule.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.schedules.findIndex(
          (schedule) => schedule.id === action.payload.id
        );
        if (index !== -1) {
          state.schedules[index] = action.payload;
        }
      })
      .addCase(updateSchedule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to update schedule";
      });
  },
});

export default capacitySlice.reducer;
