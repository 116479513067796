import React from "react";

import HeaderContainer from "../constants/HeaderContainer";
import ManageNavigation from "./components/managcontents/ManageNavigation";

const ManageContent = () => {
  return (
    <div>
      <HeaderContainer
        title="Manage Navigation"
        description="Create and manage side navigation options"
      />
      <div className="p-5">
        <ManageNavigation />
      </div>
    </div>
  );
};

export default ManageContent;
