import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

// Define interfaces for better type safety
interface Role {
  id: number;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

interface UserTeam {
  id: number;
  createdAt: string;
  updatedAt: string;
  UserId: number;
  TeamId: number;
}

interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  dateOfBirth: string;
  isSuperTenant: boolean;
  defaultTenant: string;
  currentTenant: string;
  isLoggedIn: boolean;
  last_active_at: string;
  isActive: boolean;
  imageUrl: string | null;
  createdAt: string;
  updatedAt: string;
  RoleId: number;
  Role: Role;
  Address: string | null;
  UserTeam: UserTeam;
}

interface TeamUsersState {
  users: User[];
  loading: boolean;
  error: string | null;
}

const initialState: TeamUsersState = {
  users: [],
  loading: false,
  error: null,
};

const getHeaders = (getState: () => RootState) => {
  const token = getState().authentication.tokens?.token;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

// Define async thunk to fetch users under a team
export const fetchTeamUsers = createAsyncThunk<
  User[],
  number,
  { state: RootState; rejectValue: string }
>("teamUsers/fetchTeamUsers", async (teamId, { getState, rejectWithValue }) => {
  try {
    const response = await axios.get<User[]>(
      `https://cedarplatform.io:4400/api/v1/team/users/${teamId}`,
      {
        ...getHeaders(getState),
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to fetch users under team");
  }
});

// Create a slice for team users
const teamUsersSlice = createSlice({
  name: "teamUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTeamUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchTeamUsers.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload ||
          action.error.message ||
          "Failed to fetch users under team";
      });
  },
});

export default teamUsersSlice.reducer;
