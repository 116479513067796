import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import {
  fetchLayouts,
  updateLayout,
  updateLayoutLocally,
} from "../../../store/slices/layoutSlice";
import { Button } from "semantic-ui-react";
import toast from "react-hot-toast";

const ThemeLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const layouts = useSelector((state: RootState) => state.layouts.layouts);
  const loading = useSelector((state: RootState) => state.layouts.loading);
  const [localLayouts, setLocalLayouts] = useState(layouts);

  useEffect(() => {
    if (!layouts.length) {
      dispatch(fetchLayouts());
    }
  }, [layouts.length]);

  useEffect(() => {
    setLocalLayouts(layouts);
  }, [layouts]);

  const handleUpdateLayoutLocally = (id: number, newLayout: string) => {
    const updatedLayouts = localLayouts.map((layout) =>
      layout.id === id ? { ...layout, layout: newLayout } : layout
    );
    setLocalLayouts(updatedLayouts);
    dispatch(updateLayoutLocally({ id, layout: newLayout }));
  };

  const handleSaveChanges = () => {
    localLayouts.forEach((layout) => {
      dispatch(updateLayout(layout))
        .then((res) => {
          if (res.type === "layouts/updateLayout/fulfilled") {
            toast.success("Layout Changed");
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    });
  };

  return (
    <div className="p-6 border m-3 rounded">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-xl font-semibold">Customize Themes & Layouts</h2>
          <p className="text-gray-600 text-sm">
            Customize themes and layouts for your site
          </p>
        </div>
        <Button
          primary
          onClick={handleSaveChanges}
          loading={loading}
          disabled={loading}
        >
          Save Changes
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-8">
        <div
          className={`border rounded-xl p-4 shadow-md ${
            layouts[0]?.layout === "Classic" &&
            "border-[var(--navigation-border-color)]"
          } cursor-pointer hover:scale-105`}
          onClick={() => handleUpdateLayoutLocally(layouts[0].id, "Classic")}
        >
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-3">
              <span className="whitespace-nowrap">Classic Layout</span>
              <p className="text-gray-500 text-sm">
                The classic layout is the default reporting hub layout customers
                are familiar with. It comes with the top navbar and sidebar.
              </p>
            </div>
            <div className="col-span-2 border rounded bg-gray-100 p-2">
              <div className="grid grid-cols-4 my-2 gap-4 w-full">
                <div className="border rounded bg-black w-full">s</div>
                <div className="col-span-3 grid grid-cols-2 gap-2 w-full h-32">
                  <div className="border rounded bg-white w-full"></div>
                  <div className="border rounded bg-white w-full"></div>
                  <div className="col-span-2 border rounded bg-white w-full"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`border rounded-xl p-4 shadow-md ${
            layouts[0]?.layout === "Modern" &&
            "border-[var(--navigation-border-color)]"
          } cursor-pointer hover:scale-105`}
          onClick={() => handleUpdateLayoutLocally(layouts[0].id, "Modern")}
        >
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-3">
              <span className="whitespace-nowrap">Modern Layout</span>
              <p className="text-gray-500 text-sm">
                The modern layout is a new reporting hub layout customers are
                familiar with. It comes with the top sidebar and side sidebar.
              </p>
            </div>
            <div className="col-span-2 border rounded bg-gray-100 p-2">
              <div className="grid grid-cols-4 my-2 gap-4 w-full">
                <div className="border rounded bg-black w-full">s</div>
                <div className="col-span-3 grid grid-cols-2 gap-2 w-full h-32">
                  <div className="col-span-2 border rounded bg-black w-full"></div>
                  <div className="border rounded bg-white w-full"></div>
                  <div className="border rounded bg-white w-full"></div>
                  <div className="col-span-2 border rounded bg-white w-full"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeLayout;
