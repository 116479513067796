import React, { useState } from "react";
import HeaderContainer from "../constants/HeaderContainer";
import Permissions from "./components/UserManagement/Permissions";
import Role from "./components/UserManagement/Role";
import Teams from "./components/UserManagement/Team";
import Users from "./components/UserManagement/User";

const UserManagement = () => {
  const [activeTab, setActiveTab] = useState("Teams");

  return (
    <div>
      <HeaderContainer
        title="Manage Users"
        description="Security group synchronization"
        tabs={["Teams", "Users", "Role"]}
        activeTab={activeTab}
        onTabClick={setActiveTab}
      />
      <div>
        {activeTab === "Teams" && <Teams />}
        {activeTab === "Users" && <Users />}
        {activeTab === "Role" && <Role />}
        {activeTab === "Permissions" && <Permissions />}
      </div>
    </div>
  );
};

export default UserManagement;
