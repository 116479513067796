import { UserResponse } from "../store/slices/userSlice";

export const hasPermission = (
  userRole: UserResponse | null,
  allowedRoles: string[] | undefined
): boolean => {
  if (userRole?.isSuperTenant) {
    return true;
  }
  return userRole && allowedRoles
    ? allowedRoles.includes(userRole.Role.name)
    : false;
};
