import React, { useEffect, useReducer, useState } from "react";
import { FaClock } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import {
  Button,
  Card,
  CardContent,
  Confirm,
  Form,
  FormGroup,
  FormSelect,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import {
  Schedule,
  addSchedule,
  deleteSchedule,
  fetchCapacities,
  fetchSchedules,
  getEmbedTimeout,
  updateEmbedTimeout,
  updateSchedule,
} from "../../../../store/slices/capacitySlice";
import toast from "react-hot-toast";
import { SkeletonTable } from "../../../constants/SkeletonTable";
import { BsThreeDots } from "react-icons/bs";

type State = {
  open: boolean;
  size?: "mini" | "tiny" | "small" | "large" | "fullscreen";
};

type Action =
  | { type: "close" }
  | { type: "open"; size: "mini" | "tiny" | "small" | "large" | "fullscreen" };

function exampleReducer(state: State, action: Action): State {
  switch (action.type) {
    case "close":
      return { open: false };
    case "open":
      return { open: true, size: action.size };
    default:
      throw new Error("Unsupported action...");
  }
}

const hourOptions = Array.from({ length: 12 }, (_, i) => ({
  key: i + 1,
  text: i + 1,
  value: i + 1,
}));

const minuteOptions = Array.from({ length: 60 }, (_, i) => ({
  key: i,
  text: i < 10 ? `0${i}` : i,
  value: i,
}));

const ManageCapacity: React.FC = () => {
  const [state, dispatch] = useReducer(exampleReducer, {
    open: false,
    size: undefined,
  });
  const [startHour, setStartHour] = useState(0);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [startMinute, setStartMinute] = useState(0);
  const [scheduleMinute, setScheduleMinute] = useState(0);
  const [meridiem, setMeridiem] = useState("AM");
  const [selectedCapacity, setSelectedCapacity] = useState<string>("");
  const [scheduleToEdit, setScheduleToEdit] = useState<null | Schedule>(null);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage] = useState(10);
  const { capacities, schedules, embedTimeouts } = useSelector(
    (state: RootState) => state.capacities
  );
  const [scheduleToDelete, setScheduleToDelete] = useState<number | null>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const loading = useSelector((state: RootState) => state.capacities.loading);
  const error = useSelector((state: RootState) => state.capacities.error);
  const reduxDispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!capacities.length) {
      reduxDispatch(fetchCapacities());
    }
    if (!schedules.length) {
      reduxDispatch(fetchSchedules());
    }
    if (!embedTimeouts.length) {
      reduxDispatch(getEmbedTimeout());
    }
  }, [
    reduxDispatch,
    capacities.length,
    schedules.length,
    embedTimeouts.length,
  ]);

  useEffect(() => {
    if (capacities.length > 0) {
      setSelectedCapacity(capacities[0].id);
    }
  }, [capacities]);

  useEffect(() => {
    if (embedTimeouts.length > 0) {
      setMinute(Number(embedTimeouts[0]?.embeddedTimeout) || 0);
    }
  }, [embedTimeouts]);

  const capacityOptions = capacities.map((capacity) => ({
    key: capacity.id,
    text: capacity.displayName,
    value: capacity.id,
  }));

  const handlePageChange = (e: any, { activePage }: any) => {
    setActivePage(activePage);
  };

  const handleSaveChanges = () => {
    if (typeof minute === "number" && minute.toString) {
      reduxDispatch(updateEmbedTimeout({ id: "0", timeout: minute.toString() }))
        .then((res) => {
          if (res.type === "capacities/updateEmbedTimeout/fulfilled") {
            toast.success("Timeout updated successfully");
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      toast.error("Invalid minute value");
    }
  };

  const selectedCapacityState =
    capacities.find((cap) => cap.id === selectedCapacity)?.state || "";

  const handleEdit = (schedule: Schedule) => {
    setScheduleToEdit(schedule);
    dispatch({ type: "open", size: "tiny" });
  };

  const openConfirm = (scheduleId: number) => {
    setScheduleToDelete(scheduleId);
    setConfirmOpen(true);
  };

  const handleCancel = () => {
    setConfirmOpen(false);
    setScheduleToDelete(null);
  };

  const handleConfirm = () => {
    if (scheduleToDelete !== null) {
      reduxDispatch(deleteSchedule({ scheduleId: scheduleToDelete }))
        .then((res) => {
          if (res.type === "capacities/deleteSchedule/fulfilled") {
            toast.success("Schedule deleted successfully");
            setConfirmOpen(false);
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  useEffect(() => {
    setHour(Number(scheduleToEdit?.durationHours) || 0);
    setStartHour(Number(scheduleToEdit?.startHour) || 0);
    setStartMinute(Number(scheduleToEdit?.startMinute) || 0);
    setScheduleMinute(Number(scheduleToEdit?.durationMinutes) || 0);
    setMeridiem(scheduleToEdit?.period || "AM");
  }, [scheduleToEdit]);

  const handleSubmit = () => {
    if (scheduleToEdit) {
      reduxDispatch(
        updateSchedule({
          scheduleId: scheduleToEdit.id,
          startHour: startHour.toString(),
          startMinute: startMinute.toString(),
          period: meridiem,
          durationHours: hour,
          durationMinutes: scheduleMinute,
          isEnabled: true,
        })
      )
        .then((res) => {
          if (res.type === "capacities/updateSchedule/fulfilled") {
            toast.success("Schedule updated successfully");
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setHour(0);
          setStartHour(0);
          setStartMinute(0);
          setScheduleMinute(0);
          setMeridiem("AM");
          setScheduleToEdit(null);
        });
    } else {
      reduxDispatch(
        addSchedule({
          startHour: startHour.toString(),
          startMinute: startMinute.toString(),
          period: meridiem,
          durationHours: hour,
          durationMinutes: scheduleMinute,
          isEnabled: true,
        })
      )
        .then((res) => {
          if (res.type === "capacities/addSchedule/fulfilled") {
            toast.success("Schedule added successfully");
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setHour(0);
          setStartHour(0);
          setStartMinute(0);
          setScheduleMinute(0);
          setMeridiem("AM");
        });
    }
  };

  return (
    <div>
      <Confirm
        open={confirmOpen}
        size="tiny"
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        content="Are you sure you want to delete this schedule?"
        confirmButton={{ content: "Delete", loading: loading }}
      />
      <div className="mx-auto w-full">
        <div className="bg-white rounded-lg p-6">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h2 className="text-xl font-semibold">Setup Capacity</h2>
              <p className="text-gray-600 text-sm">
                Set up the capacity for your Power BI Configuration
              </p>
            </div>
            <Button
              primary
              size="tiny"
              onClick={handleSaveChanges}
              loading={loading}
            >
              Save Changes
            </Button>
          </div>
          <hr className="my-6 border-t border-gray-200" />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-gray-50 rounded-xl p-4 shadow-md">
              <div className="grid grid-cols-3 items-center mb-4 space-x-6">
                <FormSelect
                  className="col-span-2 w-3/3 flex flex-1 text-sm text-gray-900 whitespace-nowrap bg-gray-50 focus:outline-none"
                  options={capacityOptions}
                  placeholder="Select Capacity"
                  loading={loading}
                  value={selectedCapacity}
                  onChange={(_, { value }) =>
                    setSelectedCapacity(value as string)
                  }
                />
                {selectedCapacityState === "Active" ? (
                  <div className="col-span-1 flex items-center justify-end">
                    <div className="flex w-fit items-center text-green-500 text-xs bg-green-100 rounded-lg py-0.5 px-2">
                      <FaClock className="mr-2" />
                      <span>Running</span>
                    </div>
                  </div>
                ) : (
                  selectedCapacityState === "Suspended" && (
                    <div className="col-span-1 flex items-center justify-end">
                      <div className="flex w-fit items-center text-red-500 text-xs bg-red-100 rounded-lg py-0.5 px-2">
                        <FaClock className="mr-2" />
                        <span>Suspended</span>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="col-span-1 flex flex-1 items-center text-gray-900 text-xs rounded-lg space-x-6 mb-4 bg-white">
                <p className="flex w-3/4 flex-row items-center text-xs text-gray-700 leading-5">
                  Embed Capacity Timeout
                </p>
                <input
                  type="number"
                  min={0}
                  max={60}
                  value={minute}
                  onChange={(e) => setMinute(Number(e.target.value))}
                  className="appearance-none w-1/3 p-1 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                />
              </div>
            </div>
            <div className="rounded-lg p-4 shadow-md bg-gray-50">
              <Form size="small">
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Start Hour</label>
                    <Form.Select
                      options={hourOptions}
                      value={startHour}
                      onChange={(_, { value }) => setStartHour(value as number)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Start Minute</label>
                    <Form.Select
                      options={minuteOptions}
                      value={startMinute}
                      onChange={(_, { value }) =>
                        setStartMinute(value as number)
                      }
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Duration Hours</label>
                    <Form.Select
                      options={hourOptions}
                      value={hour}
                      onChange={(_, { value }) => setHour(value as number)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Duration Minutes</label>
                    <Form.Select
                      options={minuteOptions}
                      value={scheduleMinute}
                      onChange={(_, { value }) =>
                        setScheduleMinute(value as number)
                      }
                    />
                  </Form.Field>
                </Form.Group>
                <FormGroup inline>
                  <label>Meridiem</label>
                  <Form.Radio
                    label="AM"
                    value="AM"
                    checked={meridiem === "AM"}
                    onChange={() => setMeridiem("AM")}
                  />
                  <Form.Radio
                    label="PM"
                    value="PM"
                    checked={meridiem === "PM"}
                    onChange={() => setMeridiem("PM")}
                  />
                </FormGroup>
                <Button primary size="tiny" onClick={handleSubmit}>
                  {scheduleToEdit ? "Update Schedule" : "Add Schedule"}
                </Button>
              </Form>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg p-6 mt-2 mb-6">
          <h2 className="text-xl font-semibold mb-6">Scheduled Capacities</h2>
          {loading ? (
            <SkeletonTable />
          ) : (
            <Table basic="very" columns={4}>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Start Time</TableHeaderCell>
                  <TableHeaderCell>Duration</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>Actions</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {schedules
                  .slice(
                    (activePage - 1) * itemsPerPage,
                    activePage * itemsPerPage
                  )
                  .map((schedule) => (
                    <TableRow key={schedule.id}>
                      <TableCell>{`${schedule.startHour}:${schedule.startMinute} ${schedule.period}`}</TableCell>
                      <TableCell>
                        {`${schedule.durationHours}h ${schedule.durationMinutes}m`}
                      </TableCell>
                      <TableCell>
                        {schedule.isEnabled ? "Enabled" : "Disabled"}
                      </TableCell>
                      <TableCell>
                        <div className="flex space-x-2">
                          <Button
                            size="tiny"
                            onClick={() => handleEdit(schedule)}
                          >
                            Edit
                          </Button>
                          <Button
                            size="tiny"
                            color="red"
                            onClick={() => openConfirm(schedule.id)}
                          >
                            Delete
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageCapacity;
