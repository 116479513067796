import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { fetchBranding } from "../../store/slices/brandingSlice";
import loader from "./../../assets/images/Ripple@1x-1.0s-200px-200px.gif";

interface CustomLoaderProps {
  height: number;
}
const CustomLoader: FC<CustomLoaderProps> = ({ height }) => {
  const { branding } = useSelector((state: RootState) => state.branding);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!branding) {
      dispatch(fetchBranding());
    }
  }, [dispatch, branding]);

  return (
    <div className="flex w-full items-center justify-center h-screen -mt-20">
      <img
        width={height || 50}
        height={height || 50}
        src={branding?.customLoader ? branding?.customLoader : loader}
        alt="Loading..."
      />
    </div>
  );
};

export default CustomLoader;
