import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

export interface Permission {
  id: number;
  name: string;
  description: string;
}

interface PermissionsState {
  permissions: Permission[];
  loading: boolean;
  error: string | null;
  addLoading: boolean;
  editLoading: boolean;
  deleteLoading: boolean;
  assignLoading: boolean;
}

const initialState: PermissionsState = {
  permissions: [],
  loading: false,
  error: null,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  assignLoading: false,
};

export const fetchPermissions = createAsyncThunk<Permission[]>(
  "permissions/fetchPermissions",
  async () => {
    const response = await axios.get<Permission[]>(
      "https://cedarplatform.io:4400/api/v1/permissions"
    );
    return response.data;
  }
);

// Define async thunk to add a new permission
export const addPermission = createAsyncThunk<
  Permission,
  Omit<Permission, "id">,
  { state: RootState }
>("permissions/addPermission", async (newPermission, { getState }) => {
  const token = getState().authentication.tokens?.token;
  try {
    const response = await axios.post<Permission>(
      "https://cedarplatform.io:4400/api/v1/permissions",
      newPermission,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to add permission");
  }
});

export const editPermission = createAsyncThunk<
  Permission,
  Permission,
  { state: RootState }
>("permissions/editPermission", async (updatedPermission, { getState }) => {
  const token = getState().authentication.tokens?.token;
  try {
    const response = await axios.put<Permission>(
      `https://cedarplatform.io:4400/api/v1/permissions/${updatedPermission.id}`,
      updatedPermission,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to edit permission");
  }
});

export const deletePermission = createAsyncThunk<
  number,
  number,
  { state: RootState }
>("permissions/deletePermission", async (permissionId, { getState }) => {
  const token = getState().authentication.tokens?.token;
  try {
    await axios.delete(
      `https://cedarplatform.io:4400/api/v1/permissions/${permissionId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return permissionId;
  } catch (error) {
    throw new Error("Failed to delete permission");
  }
});

// Define async thunk to assign permissions to a role
export const assignPermission = createAsyncThunk<
  void,
  { roleId: number; permissionIds: string[] },
  { state: RootState }
>(
  "permissions/assignPermission",
  async ({ roleId, permissionIds }, { getState }) => {
    const token = getState().authentication.tokens?.token;
    try {
      await axios.post(
        "https://cedarplatform.io:4400/api/v1/users/assign-permissions",
        { roleId, permissionIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      throw new Error("Failed to assign permissions");
    }
  }
);

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchPermissions.fulfilled,
        (state, action: PayloadAction<Permission[]>) => {
          state.loading = false;
          state.permissions = action.payload;
        }
      )
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch permissions";
      })
      .addCase(addPermission.pending, (state) => {
        state.addLoading = true;
        state.error = null;
      })
      .addCase(addPermission.fulfilled, (state, action) => {
        state.addLoading = false;
        state.permissions.push(action.payload);
      })
      .addCase(addPermission.rejected, (state, action) => {
        state.addLoading = false;
        state.error = action.error.message || "Failed to add permission";
      })
      .addCase(editPermission.pending, (state) => {
        state.editLoading = true;
        state.error = null;
      })
      .addCase(editPermission.fulfilled, (state, action) => {
        state.editLoading = false;
        const updatedPermission = action.payload;
        const index = state.permissions.findIndex(
          (perm) => perm.id === updatedPermission.id
        );
        if (index !== -1) {
          state.permissions[index] = updatedPermission;
        }
      })
      .addCase(editPermission.rejected, (state, action) => {
        state.editLoading = false;
        state.error = action.error.message || "Failed to edit permission";
      })
      .addCase(deletePermission.pending, (state) => {
        state.deleteLoading = true;
        state.error = null;
      })
      .addCase(deletePermission.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.permissions = state.permissions.filter(
          (perm) => perm.id !== action.payload
        );
      })
      .addCase(deletePermission.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error = action.error.message || "Failed to delete permission";
      })
      .addCase(assignPermission.pending, (state) => {
        state.assignLoading = true;
        state.error = null;
      })
      .addCase(assignPermission.fulfilled, (state) => {
        state.assignLoading = false;
      })
      .addCase(assignPermission.rejected, (state, action) => {
        state.assignLoading = false;
        state.error = action.error.message || "Failed to assign permissions";
      });
  },
});

export default permissionsSlice.reducer;
