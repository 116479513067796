import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import step from "./../assets/images/steps.png";
import { AppDispatch, RootState } from "../store/store";
import CustomLoader from "../components/constants/CustomLoader";
import { useDispatch } from "react-redux";
import { fetchContentPages } from "../store/slices/contentPageSlice";

const OverviewPage: React.FC = () => {
  const { tokens } = useSelector((state: RootState) => state.authentication);
  const { loading } = useSelector((state: RootState) => state.contentPages);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { contentPages, loading: contentPagesLoading } = useSelector(
    (state: RootState) => state.contentPages
  );

  useEffect(() => {
    if (!tokens) {
      navigate("/");
    }
  }, [tokens]);

  useEffect(() => {
    dispatch(fetchContentPages());
  }, [dispatch]);

  useEffect(() => {
    if (
      contentPages.length <= 0 &&
      !contentPagesLoading &&
      !contentPages.filter((item) => item.type === "contentPage")[0]?.id
    ) {
      navigate("/reports/contentPage/OverviewPage");
    } else if (contentPages.length > 0 && !contentPagesLoading) {
      navigate(
        `/reports/contentPage/${
          contentPages.filter((item) => item.type === "contentPage")[0]?.id
        }`
      );
    }
  }, [contentPages]);

  if (loading) {
    return <CustomLoader height={70} />;
  }

  return (
    <div className="border p-5 m-3 shadow rounded">
      <img src={step} width="100%" height="100%" alt="Steps" />
    </div>
  );
};

export default OverviewPage;
