import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

export interface ColorsState {
  brandPrimaryColor: string;
  sideNavigationPanelItemHighlight: string;
  sideNavigationFontHover: string;
  topNavigationPanelPrimary: string;
  reportPaneBackground: string;
  navigationArrowColor: string;
  sideNavigationHeaderFontColor: string;
  sideNavigationFontPrimary: string;
  buttonFaceColor: string;
  topNavigationPanelSecondary: string;
  contentPaneTitles: string;
  sideNavigationPanelPrimary: string;
  sideNavigationPanelSecondary: string;
  topNavatigationFont: string;
  paneNameCurrentPage: string;
  navigationBorderColor: string;
  TenantId: number;
}

interface ColorsSliceState {
  colors: ColorsState | null;
  loading: boolean;
  error: string | null;
  isFetching: boolean; // New flag to handle fetch state
}

const initialState: ColorsSliceState = {
  colors: null,
  loading: false,
  error: null,
  isFetching: false, // Initial fetch state
};

const getHeaders = (getState: () => RootState) => {
  const token = getState().authentication.tokens?.token;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const fetchColors = createAsyncThunk<
  ColorsState,
  void,
  { state: RootState }
>("colors/fetchColors", async (_, { getState, rejectWithValue }) => {
  try {
    const response = await axios.get<ColorsState[]>(
      "https://cedarplatform.io:4400/api/v1/colors",
      getHeaders(getState)
    );
    if (response.data.length > 0) {
      return response.data[0];
    } else {
      throw new Error("No colors data found");
    }
  } catch (error) {
    return rejectWithValue("Failed to fetch colors data");
  }
});

export const updateColors = createAsyncThunk<
  ColorsState,
  Partial<ColorsState>,
  { state: RootState }
>(
  "colors/updateColors",
  async (updatedColors, { getState, rejectWithValue }) => {
    try {
      const response = await axios.put<{ themeColor: ColorsState }>(
        "https://cedarplatform.io:4400/api/v1/colors",
        updatedColors,
        getHeaders(getState)
      );
      return response.data.themeColor;
    } catch (error) {
      return rejectWithValue("Failed to update colors data");
    }
  }
);

export const resetColors = createAsyncThunk<
  ColorsState,
  void,
  { state: RootState }
>("colors/resetColors", async (_, { getState, rejectWithValue }) => {
  try {
    const response = await axios.put<{ themeColor: ColorsState }>(
      "https://cedarplatform.io:4400/api/v1/colors/reset",
      {},
      getHeaders(getState)
    );
    return response.data.themeColor;
  } catch (error) {
    return rejectWithValue("Failed to update colors data");
  }
});

const colorsSlice = createSlice({
  name: "colors",
  initialState,
  reducers: {
    setColors: (state, action: PayloadAction<ColorsState>) => {
      state.colors = action.payload;
    },
    clearColors: (state) => {
      state.colors = null;
    },
    updateColor: (
      state,
      action: PayloadAction<{ key: keyof ColorsState; value: string }>
    ) => {
      if (state.colors) {
        // @ts-ignore
        state.colors[action.payload.key] = action.payload.value;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchColors.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isFetching = true; // Indicate fetching is in progress
      })
      .addCase(fetchColors.fulfilled, (state, action) => {
        state.loading = false;
        state.isFetching = false;
        state.colors = action.payload;
      })
      .addCase(fetchColors.rejected, (state, action) => {
        state.loading = false;
        state.isFetching = false;
        state.error = action.payload as string;
      })
      .addCase(updateColors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateColors.fulfilled, (state, action) => {
        state.loading = false;
        state.colors = action.payload;
      })
      .addCase(updateColors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(resetColors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetColors.fulfilled, (state, action) => {
        state.loading = false;
        state.colors = action.payload;
      })
      .addCase(resetColors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setColors, clearColors, updateColor } = colorsSlice.actions;
export default colorsSlice.reducer;
