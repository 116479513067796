import { useEffect } from "react";
import Navbar from "../components/header/Navbar";
import SettingSidebar from "../components/sidebar/SettingSidebar";
import { Route, Routes, useNavigate } from "react-router-dom";
import ManageContent from "../components/settings/ManageContent";
import PowerBISetting from "../components/settings/PowerBISetting";
import Theme from "../components/settings/Theme";
import UserManagement from "../components/settings/UserManagement";
import AppSettings from "../components/settings/AppSettings";
import { hasPermission } from "../utils/roleUtils";
import SettingMenus from "../config/menuConfig";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";
import ProfilePage from "../components/settings/Profile";
import HelpPage from "../components/settings/Help";
import TeamUsers from "../components/settings/components/UserManagement/Team/TeamUsers";

const SettingPage = () => {
  const user = useSelector((state: RootState) => state.users.user);
  const { tokens } = useSelector((state: RootState) => state.authentication);
  const navigate = useNavigate();

  useEffect(() => {
    if (!tokens) {
      navigate("/");
    }
  }, [tokens]);

  return (
    <div className="w-full flex bg-[var(--side-navigation-panel-primary)]">
      <SettingSidebar />
      <div className="w-full rounded-3xl overflow-hidden m-4 bg-white ">
        <div
          style={{ height: "calc(100vh - 20px)" }}
          className="flex flex-col calc-screen-20 overflow-y-auto w-full"
        >
          <Navbar />
          <div className="pt-2">
            <Routes>
              {hasPermission(
                user,
                SettingMenus.find((item) => item.title === "Manage Navigation")
                  ?.allowedRoles
              ) && <Route path="/" element={<ManageContent />}></Route>}
              {hasPermission(
                user,
                SettingMenus.find((item) => item.title === "Power BI Settings")
                  ?.allowedRoles
              ) && <Route path="/powerbi" element={<PowerBISetting />}></Route>}
              {hasPermission(
                user,
                SettingMenus.find((item) => item.title === "Edit Theme")
                  ?.allowedRoles
              ) && <Route path="/theme" element={<Theme />}></Route>}
              {hasPermission(
                user,
                SettingMenus.find((item) => item.title === "User Management")
                  ?.allowedRoles
              ) && <Route path="/groups" element={<UserManagement />}></Route>}
              {hasPermission(
                user,
                SettingMenus.find((item) => item.title === "App Settings")
                  ?.allowedRoles
              ) && <Route path="/apps" element={<AppSettings />}></Route>}
              {hasPermission(
                user,
                SettingMenus.find((item) => item.title === "Profile")
                  ?.allowedRoles
              ) && <Route path="/profile" element={<ProfilePage />}></Route>}
              {hasPermission(
                user,
                SettingMenus.find((item) => item.title === "Help")?.allowedRoles
              ) && <Route path="/help" element={<HelpPage />}></Route>}
              {hasPermission(
                user,
                SettingMenus.find((item) => item.title === "Help")?.allowedRoles
              ) && (
                <Route
                  path="/groups/team/:teamId"
                  element={<TeamUsers />}
                ></Route>
              )}
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingPage;
