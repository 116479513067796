import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

export interface WorkspaceContent {
  id: string;
  reportType: string;
  name: string;
  webUrl: string;
  embedUrl: string;
  isFromPbix: boolean;
  isOwnedByMe: boolean;
  datasetId: string;
  datasetWorkspaceId: string;
  users: any[];
  subscriptions: any[];
  sections: any[];
}

interface WorkspaceContentState {
  workspaceContents: WorkspaceContent[];
  loading: boolean;
  error: string | null;
}

const initialState: WorkspaceContentState = {
  workspaceContents: [],
  loading: false,
  error: null,
};

export const fetchWorkspaceContents = createAsyncThunk<
  WorkspaceContent[],
  { groupId: string },
  { state: RootState }
>(
  "workspaceContents/fetchWorkspaceContents",
  async ({ groupId }, { getState }) => {
    const token = getState().authentication.tokens?.token;
    try {
      const response = await axios.get<WorkspaceContent[]>(
        `https://cedarplatform.io:4400/api/v1/workspaces/reports?groupId=${groupId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch workspace contents");
    }
  }
);

const workspaceContentSlice = createSlice({
  name: "workspaceContents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkspaceContents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWorkspaceContents.fulfilled, (state, action) => {
        state.loading = false;
        state.workspaceContents = action.payload;
      })
      .addCase(fetchWorkspaceContents.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch workspace contents";
      });
  },
});

export default workspaceContentSlice.reducer;
