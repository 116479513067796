import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

export interface Category {
  id: number;
  name: string;
  icon: string;
  sortOrder: number; // Added sortOrder field
}

interface CategoryState {
  categories: Category[];
  loading: boolean;
  error: string | null;
  addLoading: boolean;
  deleteLoading: boolean;
  bulkUpdateLoading: boolean; // Added bulkUpdateLoading field
}

const initialState: CategoryState = {
  categories: [],
  loading: false,
  error: null,
  addLoading: false,
  deleteLoading: false,
  bulkUpdateLoading: false, // Initialize bulkUpdateLoading
};

export const fetchCategories = createAsyncThunk<Category[]>(
  "categories/fetchCategories",
  async () => {
    try {
      const response = await axios.get<Category[]>(
        "https://cedarplatform.io:4400/api/v1/category"
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch categories");
    }
  }
);

export const addCategory = createAsyncThunk<
  Category,
  Omit<Category, "id">,
  { state: RootState }
>("categories/addCategory", async (categoryData, { getState }) => {
  const token = getState().authentication.tokens?.token;
  try {
    const response = await axios.post<{ category: Category }>(
      "https://cedarplatform.io:4400/api/v1/category",
      categoryData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.category;
  } catch (error) {
    throw new Error("Failed to add category");
  }
});

export const deleteCategory = createAsyncThunk<
  number,
  number,
  { state: RootState }
>("categories/deleteCategory", async (categoryId, { getState }) => {
  const token = getState().authentication.tokens?.token;
  try {
    await axios.delete(
      `https://cedarplatform.io:4400/api/v1/category/${categoryId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return categoryId;
  } catch (error) {
    throw new Error("Failed to delete category");
  }
});

export const bulkUpdateCategorySortOrder = createAsyncThunk<
  void,
  { id: number; sortOrder: number }[],
  { state: RootState }
>(
  "categories/bulkUpdateCategorySortOrder",
  async (sortOrderUpdates, { getState }) => {
    const token = getState().authentication.tokens?.token;
    try {
      await axios.put(
        "https://cedarplatform.io:4400/api/v1/category/bulkupdate",
        sortOrderUpdates,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      throw new Error("Failed to bulk update category sort orders");
    }
  }
);

const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch categories";
      })
      .addCase(addCategory.pending, (state) => {
        state.addLoading = true;
        state.error = null;
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.addLoading = false;
        state.categories.push(action.payload);
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.addLoading = false;
        state.error = action.error.message || "Failed to add category";
      })
      .addCase(deleteCategory.pending, (state) => {
        state.deleteLoading = true;
        state.error = null;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.categories = state.categories.filter(
          (category) => category.id !== action.payload
        );
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error = action.error.message || "Failed to delete category";
      })
      .addCase(bulkUpdateCategorySortOrder.pending, (state) => {
        state.bulkUpdateLoading = true;
        state.error = null;
      })
      .addCase(bulkUpdateCategorySortOrder.fulfilled, (state, action) => {
        state.bulkUpdateLoading = false;
        // Update the sortOrder in state.categories
        action.meta.arg.forEach((update) => {
          const category = state.categories.find(
            (category) => category.id === update.id
          );
          if (category) {
            category.sortOrder = update.sortOrder;
          }
        });
      })
      .addCase(bulkUpdateCategorySortOrder.rejected, (state, action) => {
        state.bulkUpdateLoading = false;
        state.error =
          action.error.message || "Failed to bulk update sort orders";
      });
  },
});

export default categorySlice.reducer;
