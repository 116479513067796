import React, { ReactNode } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const msalConfig = {
  auth: {
    clientId:
      process.env.REACT_APP_MICROSOFT_CLIENT_ID || "YOUR_MICROSOFT_CLIENT_ID",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: window.location.origin,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

interface MicrosoftOAuthConfigProps {
  children: ReactNode;
}

const MicrosoftOAuthConfig: React.FC<MicrosoftOAuthConfigProps> = ({
  children,
}) => {
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

export default MicrosoftOAuthConfig;
