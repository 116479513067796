import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { UserResponse } from "./userSlice";

// Define interfaces for better type safety
interface Team {
  teamName: string;
  description: string;
}

export interface TeamResponse {
  id: number;
  teamName: string;
  description: string;
  status: string;
  TenantId: number;
}

interface TeamState {
  teams: TeamResponse[];
  unassignedUsers: UserResponse[];
  loading: boolean;
  error: string | null;
  addLoading: boolean;
  editLoading: boolean;
  deleteLoading: boolean;
  assignUserLoading: boolean;
}

const initialState: TeamState = {
  teams: [],
  unassignedUsers: [],
  loading: false,
  error: null,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  assignUserLoading: false,
};

const getHeaders = (getState: () => RootState) => {
  const token = getState().authentication.tokens?.token;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

// Define async thunk to fetch teams
export const fetchTeams = createAsyncThunk<
  TeamResponse[],
  void,
  { state: RootState; rejectValue: string }
>("team/fetchTeams", async (_, { getState, rejectWithValue }) => {
  try {
    const response = await axios.get<TeamResponse[]>(
      "https://cedarplatform.io:4400/api/v1/team",
      getHeaders(getState)
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to fetch teams");
  }
});

// Define async thunk to fetch unassigned users for a specific team
export const fetchUnassignedUsers = createAsyncThunk<
  UserResponse[],
  number,
  { state: RootState; rejectValue: string }
>(
  "team/fetchUnassignedUsers",
  async (teamId, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get<UserResponse[]>(
        `https://cedarplatform.io:4400/api/v1/team/unassigned-users/${teamId}`,
        getHeaders(getState)
      );
      return response.data;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to fetch unassigned users");
    }
  }
);

// Define async thunk to add a new team
export const addTeam = createAsyncThunk<
  TeamResponse,
  Team,
  { state: RootState; rejectValue: string }
>("team/addTeam", async (teamData, { getState, rejectWithValue }) => {
  const token = getState().authentication.tokens?.token;
  try {
    const response = await axios.post<{ team: TeamResponse }>(
      "https://cedarplatform.io:4400/api/v1/team",
      teamData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.team;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to add team");
  }
});

// assignUser
export const assignUser = createAsyncThunk<
  TeamResponse,
  { userIds: number[]; teamId: number },
  { state: RootState; rejectValue: string }
>(
  "team/assignTeam",
  async ({ userIds, teamId }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.put<TeamResponse>(
        `https://cedarplatform.io:4400/api/v1/team/assign-to-user`,
        { userIds, teamId },
        getHeaders(getState)
      );
      return response.data;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to assign user");
    }
  }
);

// Define async thunk to edit a team
export const editTeam = createAsyncThunk<
  TeamResponse,
  { teamId: number; teamData: Team },
  { state: RootState; rejectValue: string }
>(
  "team/editTeam",
  async ({ teamId, teamData }, { getState, rejectWithValue }) => {
    const token = getState().authentication.tokens?.token;
    try {
      const response = await axios.put<TeamResponse>(
        `https://cedarplatform.io:4400/api/v1/team/${teamId}`,
        teamData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to edit team");
    }
  }
);

// Define async thunk to delete a team
export const deleteTeam = createAsyncThunk<
  number,
  number,
  { state: RootState; rejectValue: string }
>("team/deleteTeam", async (teamId, { getState, rejectWithValue }) => {
  const token = getState().authentication.tokens?.token;
  try {
    await axios.delete(`https://cedarplatform.io:4400/api/v1/team/${teamId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return teamId;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to delete team");
  }
});

// Create a slice for teams
const teamSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeams.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.loading = false;
        state.teams = action.payload;
      })
      .addCase(fetchTeams.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || action.error.message || "Failed to fetch teams";
      })
      .addCase(addTeam.pending, (state) => {
        state.addLoading = true;
        state.error = null;
      })
      .addCase(addTeam.fulfilled, (state, action) => {
        state.addLoading = false;
        state.teams.push(action.payload);
      })
      .addCase(addTeam.rejected, (state, action) => {
        state.addLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to add team";
      })
      .addCase(editTeam.pending, (state) => {
        state.editLoading = true;
        state.error = null;
      })
      .addCase(editTeam.fulfilled, (state, action) => {
        state.editLoading = false;
        const editedTeamIndex = state.teams.findIndex(
          (team) => team.id === action.payload.id
        );
        if (editedTeamIndex !== -1) {
          state.teams[editedTeamIndex] = action.payload;
        }
      })
      .addCase(editTeam.rejected, (state, action) => {
        state.editLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to edit team";
      })
      .addCase(deleteTeam.pending, (state) => {
        state.deleteLoading = true;
        state.error = null;
      })
      .addCase(deleteTeam.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.teams = state.teams.filter((team) => team.id !== action.payload);
      })
      .addCase(deleteTeam.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to delete team";
      })
      .addCase(assignUser.pending, (state) => {
        state.assignUserLoading = true;
        state.error = null;
      })
      .addCase(assignUser.fulfilled, (state, action) => {
        state.assignUserLoading = false;
      })
      .addCase(assignUser.rejected, (state, action) => {
        state.assignUserLoading = false;
      })
      .addCase(fetchUnassignedUsers.pending, (state) => {
        state.unassignedUsers = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUnassignedUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.unassignedUsers = action.payload;
      })
      .addCase(fetchUnassignedUsers.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload ||
          action.error.message ||
          "Failed to fetch unassigned users";
      });
  },
});

export default teamSlice.reducer;
