import React from "react";

interface CircularProgressProps {
  progress: number; // Progress percentage
}

const CircularProgress: React.FC<CircularProgressProps> = ({ progress }) => {
  const size = 40; // Size of the SVG container
  const strokeWidth = 3; // Stroke width of the circles
  const radius = 17; // Radius of the circles
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className="relative w-[40px] h-[40px]">
      <svg className="w-full h-full">
        <circle
          className="text-gray-300"
          strokeWidth={strokeWidth}
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          className="text-blue-500"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
      </svg>
      <span className="absolute inset-0 flex items-center justify-center text-xs text-blue-500">
        {`${progress}%`}
      </span>
    </div>
  );
};

export default CircularProgress;
