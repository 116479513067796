import React from "react";
import { useMsal } from "@azure/msal-react";
import { FaMicrosoft } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { signin } from "../../store/slices/authenticationSlice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "./authConfig";

interface MicrosoftLoginButtonProps {
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const MicrosoftLoginButton: React.FC<MicrosoftLoginButtonProps> = ({
  setError,
  setLoading,
}) => {
  const { instance } = useMsal();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleLogin = () => {
    setLoading(true);
    instance
      .loginPopup(loginRequest)
      .then((response: any) => {
        const email = response.account?.username;
        if (!email) throw new Error("Failed to get user email");

        dispatch(signin({ email }))
          .then((res) => {
            if (res.type === "authentication/signin/fulfilled") {
              toast.success("Login Success");
              navigate("/reports");
            } else {
              throw new Error(res.payload as string);
            }
          })
          .catch((error) => {
            console.log(error);
            setError(error.message);
          });
      })
      .catch((error) => {
        console.error("Login failed:", error);
        setError("An error occurred during authentication. Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <button onClick={handleLogin}>
        <div className="border-2 text-primary border-gray-200 rounded-full p-3 mx-1">
          <FaMicrosoft className="text-sm" />
        </div>
      </button>
    </div>
  );
};

export default MicrosoftLoginButton;
