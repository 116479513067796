import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import { Button, Icon, Menu, Popup, Confirm } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./../../../../store/store";
import {
  fetchCategories,
  deleteCategory,
  Category,
} from "./../../../../store/slices/categorySlice";
import {
  ContentPage,
  deleteContentPage,
  fetchContentPages,
  bulkUpdateContentPageSortOrder,
} from "./../../../../store/slices/contentPageSlice";
import AddContentPage from "./AddContentPage";
import AddCategoryPage from "./AddCategoryPage";
import { MdDragIndicator } from "react-icons/md";

const ManageNavigation: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const contentPages = useSelector(
    (state: RootState) => state.contentPages.contentPages
  );

  const [open] = useState(true);
  const [submenuOpen, setSubmenuOpen] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [isNew, setIsNew] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [category, setCategory] = useState<ContentPage>();
  const [contentPage, setContentPage] = useState<ContentPage>();
  const [parentItem, setParentItem] = useState("");
  const [parentID, setParentID] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmContentOpen, setConfirmContentOpen] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState<
    string | null | number
  >(null);
  const [loading, setLoading] = useState(false);
  const [isSortOrder, setIsSortOrder] = useState(false);
  const [draggedItem, setDraggedItem] = useState<{
    index: number;
    item: ContentPage | null;
  }>({ index: -1, item: null });
  const [updatedCategories, setUpdatedCategories] = useState<ContentPage[]>([]);

  useEffect(() => {
    dispatch(fetchContentPages());
  }, []);

  useEffect(() => {
    setUpdatedCategories(contentPages);
  }, [contentPages]);

  const toggleSubmenu = (index: number) => {
    setSubmenuOpen((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleAddNewClick = () => {
    setPopupOpen(!popupOpen);
  };

  const handleDeleteClick = (categoryId: number) => {
    setDeleteCategoryId(categoryId);
    setConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (deleteCategoryId !== null) {
      setLoading(true);
      if (typeof deleteCategoryId === "number") {
        await dispatch(deleteContentPage(deleteCategoryId));
      }
      setLoading(false);
      setConfirmOpen(false);
      setDeleteCategoryId(null);
    }
  };

  const handleDeleteContentClick = (categoryId: number) => {
    setDeleteCategoryId(categoryId);
    setConfirmContentOpen(true);
  };

  const handleConfirmDeleteContent = async () => {
    if (deleteCategoryId !== null) {
      setLoading(true);
      if (typeof deleteCategoryId === "number") {
        await dispatch(deleteContentPage(deleteCategoryId));
      }
      setLoading(false);
      setConfirmContentOpen(false);
      setDeleteCategoryId(null);
    }
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
    setConfirmContentOpen(false);
    setDeleteCategoryId(null);
  };

  const handleSortOrderToggle = () => {
    setIsSortOrder(!isSortOrder);
  };

  const handleDragStart = (index: number) => {
    setDraggedItem({ index, item: updatedCategories[index] });
  };

  const handleDragOver = (e: React.DragEvent<HTMLLIElement>, index: number) => {
    e.preventDefault();
    if (draggedItem.item === null || draggedItem.index === -1) return;

    const updatedList = [...updatedCategories];
    updatedList.splice(draggedItem.index, 1);
    updatedList.splice(index, 0, draggedItem.item);

    setDraggedItem({ index, item: draggedItem.item });
    setUpdatedCategories(updatedList);
  };

  const handleDrop = () => {
    setDraggedItem({ index: -1, item: null });
  };

  const handleSaveSortOrder = async () => {
    const updates = updatedCategories.map((item, idx) => ({
      id: item.id,
      sortOrder: idx + 1,
    }));
    await dispatch(bulkUpdateContentPageSortOrder(updates));
    dispatch(fetchCategories());
  };

  return (
    <div>
      <div className="border rounded px-2 py-5">
        <div className="grid grid-cols-3 gap-4">
          <div className="">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Menu Structure</h2>
              <Popup
                trigger={
                  <Button primary size="tiny" onClick={handleAddNewClick}>
                    <div className="flex items-center space-x-1">
                      <IoMdAdd />
                      <span>Add New</span>
                    </div>
                  </Button>
                }
                open={popupOpen}
                onClose={() => setPopupOpen(false)}
                onOpen={() => setPopupOpen(true)}
                on="click"
                position="bottom right"
                className="z-10"
              >
                <Menu vertical className="shadow-md">
                  <Menu.Item
                    onClick={() => {
                      setIsNew(true);
                      setIsCategory(true);
                      setParentItem("Main Category");
                      setPopupOpen(false);
                    }}
                  >
                    Add Category
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      setIsCategory(false);
                      setIsNew(true);
                      setParentItem("Main");
                      setParentID("");
                      setPopupOpen(false);
                    }}
                  >
                    Add Content Page
                  </Menu.Item>
                </Menu>
              </Popup>
            </div>
            <div className="flex items-center justify-between my-2 cursor-pointer">
              <div></div>
              <div onClick={handleSortOrderToggle}>
                <span className="text-secondary">Change Sort Order</span>
              </div>
            </div>
            <ul className="pt-2">
              {updatedCategories
                .filter(
                  (page) =>
                    // page.type === "category" ||
                    page.Parent === "" || page.Parent === null
                )
                .map((category, index) => (
                  <li
                    key={category.id}
                    draggable={isSortOrder}
                    onDragStart={() => handleDragStart(index)}
                    onDragOver={(e) => handleDragOver(e, index)}
                    onDrop={handleDrop}
                    className="flex flex-col w-full py-1"
                  >
                    <div className="flex w-full items-center justify-between">
                      {updatedCategories.find(
                        (page) => page.Parent === category.id?.toString()
                      ) ? (
                        <span
                          onClick={() => toggleSubmenu(index)}
                          className={`mr-2 w-6 cursor-pointer text-secondary shadow-sm rounded-full item-center justify-center ${
                            submenuOpen[index] ? "rotate-180" : "rotate-0"
                          }`}
                        >
                          <Icon name="angle down" />
                        </span>
                      ) : (
                        <span className="mr-2 w-6 text-secondary shadow rounded-full item-center justify-center"></span>
                      )}
                      <div
                        onClick={() => {
                          setIsCategory(
                            category.type === "category" ? true : false
                          );
                          setIsNew(false);
                          setCategory(category);
                          setContentPage(category);
                          setParentID(category.id?.toString());
                          setParentItem(category.Title);
                        }}
                        className={`w-full group text-gray-700 text-sm flex items-center gap-x-4 cursor-pointer p-2 ${
                          parentItem === category.Title && "border-secondary"
                        } hover:border-secondary rounded-md border`}
                      >
                        <span className="text-xl block float-left">
                          {/* @ts-ignore */}
                          <Icon name={category.Icon} />
                        </span>
                        <span
                          className={`text- font-medium flex-1 
                          duration-200 ${!open && "hidden"}`}
                        >
                          {category.Title}
                        </span>
                        <span className="hidden group-hover:flex space-x-2 items-center">
                          <span
                            className="hover:text-secondary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteClick(category.id);
                            }}
                          >
                            <FaTrash />
                          </span>
                          {category.type === "category" && (
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsCategory(false);
                                setIsNew(true);
                                setParentID(category.id?.toString());
                                setParentItem(category.Title + " / ");
                              }}
                              className="border z-[100] rounded-full p-0.5 hover:bg-secondary hover:text-white"
                            >
                              <IoMdAdd />
                            </span>
                          )}
                        </span>
                      </div>
                      {isSortOrder && (
                        <span className="ml-2 w-6 cursor-move text-secondary shadow-sm rounded-full item-center justify-center">
                          <MdDragIndicator size={18} />
                        </span>
                      )}
                    </div>
                    {submenuOpen[index] && (
                      <div>
                        {updatedCategories
                          .filter(
                            (page) => page.Parent === category.id?.toString()
                          )
                          .map((page, idx) => (
                            <ul
                              key={idx}
                              className={`ml-10 pl-2 my-1 flex items-center justify-between border-l-2 group border-gray-300 ${
                                parentItem ===
                                  category.Title + " / " + page.Title &&
                                "border-secondary"
                              }`}
                            >
                              <li
                                onClick={() => {
                                  setIsCategory(false);
                                  setIsNew(false);
                                  setContentPage(page);
                                  setParentID(page.id?.toString());
                                  setParentItem(
                                    category.Title + " / " + page.Title
                                  );
                                }}
                                className={`w-full group text-gray-700 text-sm flex items-center gap-x-4 
                                cursor-pointer p-2 ${
                                  parentItem ===
                                    category.Title + " / " + page.Title &&
                                  "border-secondary"
                                } hover:border-secondary rounded-md border`}
                              >
                                <span className="text-xl block float-left">
                                  {/* @ts-ignore */}
                                  <Icon name={page.Icon} />
                                </span>
                                <span
                                  className={`text- font-medium flex-1 duration-200 ${
                                    !open && "hidden"
                                  }`}
                                >
                                  {page.Title}
                                </span>
                                <span className="hidden group-hover:flex space-x-2 items-center">
                                  <span
                                    className="hover:text-secondary"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteContentClick(page.id);
                                    }}
                                  >
                                    <FaTrash />
                                  </span>
                                </span>
                              </li>
                              {isSortOrder && (
                                <span className="ml-2 w-6 cursor-move text-secondary shadow-sm rounded-full item-center justify-center">
                                  <MdDragIndicator size={18} />
                                </span>
                              )}
                            </ul>
                          ))}
                      </div>
                    )}
                  </li>
                ))}
            </ul>
            {isSortOrder && (
              <div className="flex justify-end mt-4">
                <Button primary size="small" onClick={handleSaveSortOrder}>
                  Save Sort Order
                </Button>
              </div>
            )}
          </div>
          <div className="col-span-2 border rounded-sm p-5 h-[500px] overflow-y-scroll">
            {isCategory && parentItem !== "" ? (
              <AddCategoryPage
                category={category}
                isNew={isNew}
                parentItem={parentItem}
              />
            ) : parentItem === "" ? (
              ""
            ) : (
              <AddContentPage
                contentPage={contentPage}
                parentID={parentID}
                parentItem={parentItem}
                isNew={isNew}
              />
            )}
          </div>
        </div>
        <Confirm
          open={confirmOpen}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          confirmButton={{ content: "Delete", loading }}
          content="Are you sure you want to delete this category?"
        />
        <Confirm
          open={confirmContentOpen}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDeleteContent}
          confirmButton={{ content: "Delete", loading }}
          content="Are you sure you want to delete this content page?"
        />
      </div>
    </div>
  );
};

export default ManageNavigation;
