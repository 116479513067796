import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

export interface Workspace {
  id: string;
  isReadOnly: boolean;
  isOnDedicatedCapacity: boolean;
  capacityId: string;
  defaultDatasetStorageFormat: string;
  type: string;
  name: string;
}

interface WorkspaceState {
  workspaces: Workspace[];
  loading: boolean;
  error: string | null;
}

const initialState: WorkspaceState = {
  workspaces: [],
  loading: false,
  error: null,
};

export const fetchWorkspaces = createAsyncThunk<
  Workspace[],
  void,
  { state: RootState }
>("workspaces/fetchWorkspaces", async (_, { getState }) => {
  const token = getState().authentication.tokens?.token;
  try {
    const response = await axios.get<{ value: Workspace[] }>(
      "https://cedarplatform.io:4400/api/v1/workspaces",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.value;
  } catch (error) {
    throw new Error("Failed to fetch workspaces");
  }
});

const workspaceSlice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkspaces.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWorkspaces.fulfilled, (state, action) => {
        state.loading = false;
        state.workspaces = action.payload;
      })
      .addCase(fetchWorkspaces.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch workspaces";
      });
  },
});

export default workspaceSlice.reducer;
