import React, { SyntheticEvent, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  Menu,
  Icon,
  Image,
  Search,
  SearchProps,
  SearchResultData,
} from "semantic-ui-react";
import { AppDispatch, RootState } from "../../store/store";
import { fetchTenants, switchTenant } from "../../store/slices/tenantSlice";
import { fetchUser } from "../../store/slices/userSlice";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import {
  clearContentPane,
  ContentPage,
} from "../../store/slices/contentPageSlice";
import _ from "lodash";
import { BsThreeDotsVertical } from "react-icons/bs";
import PowerBiService, {
  PowerBiExportRequest,
} from "../../services/PowerBiService";
import CircularProgress from "../settings/components/CircularProgress";
import { clearTokens } from "../../store/slices/authenticationSlice";
import { clearBranding } from "../../store/slices/brandingSlice";
import { clearColors } from "../../store/slices/colorSlice";
import { clearEmbeddedReport } from "../../store/slices/embedTokenSlice";
import { clearLayouts } from "../../store/slices/layoutSlice";
import { reset } from "../../store/slices/resetSlice";

interface SearchResult {
  title: string;
  description: string;
  id: number;
}
interface BottomNavbarProps {
  open: boolean;
}

const BottomNavbar: React.FC<BottomNavbarProps> = ({ open }) => {
  const { tenants } = useSelector((state: RootState) => state.tenants);
  const { user } = useSelector((state: RootState) => state.users);
  const { contentPages, contentPage } = useSelector(
    (state: RootState) => state.contentPages
  );
  const { embeddedReport } = useSelector(
    (state: RootState) => state.embedToken
  );

  const signout = () => {
    dispatch(clearTokens());
    dispatch(clearBranding());
    dispatch(clearColors());
    dispatch(clearEmbeddedReport());
    dispatch(clearLayouts());
    dispatch(clearContentPane());
    dispatch(reset());
    navigate("/");
  };

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [exportProgress, setExportProgress] = useState<number>(0);

  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(fetchTenants());
    dispatch(fetchUser());
  }, [dispatch]);

  const handleSwitchTenant = (tenantId: string) => {
    dispatch(switchTenant({ tenantId })).then(() => {
      toast.success("Tenant Switched");
      window.location.reload();
    });
  };

  const handleSearchChange = (e: SyntheticEvent, { value }: SearchProps) => {
    setSearchValue(value || "");
    setIsLoading(true);

    setTimeout(() => {
      if (!value || value.length < 1) {
        setIsLoading(false);
        setSearchResults([]);
        return;
      }

      const re = new RegExp(_.escapeRegExp(value), "i");
      const isMatch = (result: ContentPage) => re.test(result.Title);

      setSearchResults(
        _.filter(contentPages, isMatch).map((item) => ({
          title: item.Title,
          description: item.Description || "",
          id: item.id,
        }))
      );
      setIsLoading(false);
    }, 300);
  };

  const handleResultSelect = (
    e: SyntheticEvent,
    { result }: SearchResultData
  ) => {
    navigate(`/reports/contentPage/${result.id}`);
    setSearchValue("");
    setSearchOpen(false);
  };

  const handleSearchFocus = () => {
    setSearchOpen(true);
  };

  const handleSearchBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!searchRef.current?.contains(e.relatedTarget as Node)) {
      setSearchOpen(false);
    }
  };

  const tenantOptions = tenants
    ?.filter((item) => item.isActive)
    .map((item) => ({
      key: item.id,
      text:
        item.tenantName === "superTenant" ? "Super Tenant" : item.tenantName,
      value: item.id,
    }));

  const handleExport = async (type: string) => {
    if (!embeddedReport) {
      console.error("Report is not loaded");
      return;
    }

    setIsExporting(true);
    setExportProgress(0);

    const exportStatusCallback = (statusMessage: string) => {
      console.log(statusMessage);
      if (statusMessage.includes("Progress")) {
        const progress = parseInt(statusMessage.replace("Progress: ", ""), 10);
        setExportProgress(progress);
      }
    };

    const incrementProgressRandomly = () => {
      setExportProgress((prev) => {
        const newProgress = prev + Math.floor(Math.random() * 10) + 1;
        return newProgress > 100 ? 100 : newProgress;
      });
    };

    const progressInterval = setInterval(incrementProgressRandomly, 1400);

    try {
      const exportRequest: PowerBiExportRequest = {
        format: type,
        exportStatusCallback,
      };

      await PowerBiService.ExportReport(
        contentPage?.PowerBiWorkspace || "",
        contentPage?.PagePath || "",
        exportRequest,
        dispatch
      );

      clearInterval(progressInterval);
      setExportProgress(100);
      setIsExporting(false);
      toast.success("Export completed successfully");
    } catch (error) {
      console.error("Error exporting report:", error);
      clearInterval(progressInterval);
      setIsExporting(false);
      toast.error("Export failed");
    }
  };

  return (
    <div className="border-t border-[var(--navigation-border-color)] pt-4">
      {tenantOptions.length > 1 && (
        <Dropdown
          className="mx-2 mr-100 border border-[var(--navigation-border-color)]"
          placeholder={`${open ? "Switch Tenant" : ""}`}
          openOnFocus
          selection
          value={`${open ? Number(user?.currentTenant) : ""}`}
          options={tenantOptions}
          style={{ minWidth: open ? "200px" : "50px", width: "fit-content" }}
          onChange={(e, { value }) =>
            value?.toString && handleSwitchTenant(value?.toString())
          }
        />
      )}
      <ul
        className={`p-4 flex flex-col ${
          open ? "items-start" : "items-center"
        } space-y-2`}
      >
        <li
          className={`text-[var(--side-navigation-font-primary)] flex items-center gap-x-4 cursor-pointer hover:text-[var(--side-navigation-panel-secondary)] my-2 rounded-md 
           `}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/settings/profile`);
          }}
        >
          <Icon name="user" />
          <span className={`${open ? "" : "hidden"}`}>Profile</span>
        </li>
        <li
          className={`text-[var(--side-navigation-font-primary)] flex items-center gap-x-4 cursor-pointer hover:text-[var(--side-navigation-panel-secondary)] my-2 rounded-md 
           `}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/settings`);
          }}
        >
          <Icon name="setting" />
          <span className={`${open ? "" : "hidden"}`}>Settings</span>
        </li>
      </ul>
      <div className="w-full mb-2 text-[var(--side-navigation-font-primary)] flex items-center px-4">
        <span>
          {tenants
            .filter((item) => item.id === Number(user?.currentTenant))
            .map((item, index) => (
              <Dropdown.Item key={index}>
                <span className="text-bold">
                  {item.tenantName === "superTenant"
                    ? "Super Tenant"
                    : item.tenantName}
                </span>{" "}
                <span className="text-secondary"> (Active)</span>
              </Dropdown.Item>
            ))}
        </span>
      </div>
      <div
        className={`w-full flex flex-col text-[var(--side-navigation-font-primary)] ${
          open ? "items-start" : "items-center"
        } px-4 py-2 bg-[var(--top-navigation-panel-secondary)]`}
      >
        <div className="flex items-center space-x-3">
          <Image
            avatar
            src={
              user?.imageUrl ||
              "https://cdn4.vectorstock.com/i/1000x1000/92/73/user-profile-group-outline-icon-symbol-one-of-set-vector-8349273.jpg"
            }
          />
          {open && (
            <div className="flex flex-col items-start">
              <span className="text-[var(--side-navigation-font-primary)] font-medium capitalize">
                {user?.firstName + " " + user?.lastName}
              </span>
            </div>
          )}
        </div>
        <div className="border cursor-pointer mt-4 flex w-full items-center justify-center py-1 rounded">
          <Dropdown.Item onClick={() => signout()}>
            <Icon name="sign out" />
            {open && "Sign out"}
          </Dropdown.Item>
        </div>
      </div>
    </div>
  );
};

export default BottomNavbar;
