import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
  Button,
  Input,
  Form,
  FormField,
  FormSelect,
  FormGroup,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Confirm,
  Modal,
  Pagination,
  Checkbox,
  Loader,
} from "semantic-ui-react";
import { IoMdAdd } from "react-icons/io";
import DefaultModal from "../../../constants/Modal";
import {
  fetchUsers,
  addUser,
  editUser,
  deleteUser,
  assignRole,
  UserResponse,
  changeUserStatus,
  resetPassword,
  assignSuperTenant,
  unAssignSuperTenant,
} from "../../../../store/slices/userSlice";
import { AppDispatch, RootState } from "../../../../store/store";
import { SkeletonTable } from "../../../constants/SkeletonTable";
import { fetchRoles } from "../../../../store/slices/roleSlice";
import { BsThreeDots } from "react-icons/bs";
import toast from "react-hot-toast";
import { assignUser } from "../../../../store/slices/teamSlice";

const Users = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [state, setState] = useState<{
    open: boolean;
    size: "small" | "tiny" | "mini" | "large" | "fullscreen" | undefined;
  }>({ open: false, size: undefined });
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<number | null>(null);
  const [assignRoleOpen, setAssignRoleOpen] = useState(false);
  const [assignSuperTenantOpen, setAssignSuperTenantOpen] = useState(false);
  const [unassignSuperTenantOpen, setUnassignSuperTenantOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    dateOfBirth: "",
    roleId: 0,
    teamId: 0,
  });

  const [userToEdit, setUserToEdit] = useState<null | UserResponse>(null);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [activeStartIndex, setActiveStartIndex] = useState(0);
  const [currentUser, setCurrentUser] = useState<UserResponse | null>(null);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<number>(0);

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchRoles());
  }, []);

  const {
    users,
    loading,
    activateLoading,
    addLoading,
    editLoading,
    deleteLoading,
    assignRoleLoading,
  } = useSelector((state: RootState) => state.users);
  const { roles } = useSelector((state: RootState) => state.roles);
  const { teams, assignUserLoading } = useSelector(
    (state: RootState) => state.teams
  );
  const [assignUserOpen, setAssignUserOpen] = useState(false);

  const options = roles
    ?.filter((item) => item.name !== "SuperAdmin")
    .map((role) => ({
      key: role.id.toString(),
      text: role.name,
      value: role.id.toString(),
    }));
  const teamOptions = teams.map((role) => ({
    key: role.id.toString(),
    text: role.teamName,
    value: role.id.toString(),
  }));

  const handleResetPassword = (userId: number) => {
    setSelectedUserId(userId);
    setResetPasswordOpen(true);
  };

  const handleAssignUserSubmit = () => {
    if (selectedUserId !== null) {
      dispatch(assignUser({ userIds: [selectedUserId], teamId: selectedTeam }))
        .then((res) => {
          if (res.type === "team/assignTeam/fulfilled") {
            toast.success("User assigned successfully");
            setAssignUserOpen(false);
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleTeamSelectChange = (e: any, data: any) => {
    setSelectedTeam(data.value);
  };

  const handleResetPasswordSubmit = () => {
    if (selectedUserId !== null) {
      setResetPasswordLoading(true);
      dispatch(resetPassword({ userId: selectedUserId, newPassword }))
        .then((res) => {
          if (res.type === "users/resetPassword/fulfilled") {
            toast.success("Password reset successfully");
            setResetPasswordOpen(false);
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        })
        .finally(() => {
          setResetPasswordLoading(false);
          setNewPassword("");
        });
    }
  };

  const handleClose = () => setState({ open: false, size: undefined });

  const handleOpen = () => {
    setUserToEdit(null);
    setUserData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
      dateOfBirth: "",
      roleId: 0,
      teamId: 0,
    });
    setState({ open: true, size: "tiny" });
  };

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    roleId: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });

    switch (name) {
      case "firstName":
        setErrors({
          ...errors,
          firstName: value ? "" : "First name is required",
        });
        break;
      case "lastName":
        setErrors({
          ...errors,
          lastName: value ? "" : "Last name is required",
        });
        break;
      case "email":
        setErrors({
          ...errors,
          email: /\S+@\S+\.\S+/.test(value) ? "" : "Email is invalid",
        });
        break;
      case "password":
        setErrors({ ...errors, password: value ? "" : "Password is required" });
        break;
      default:
        break;
    }
  };

  const handleSelectChange = (e: any, data: any) => {
    setUserData({ ...userData, roleId: data.value });
  };

  const handleSelectTeamChange = (e: any, data: any) => {
    setUserData({ ...userData, teamId: data.value });
  };

  const handleAssignUser = (teamId: number) => {
    setSelectedUserId(teamId);
    setAssignUserOpen(true);
  };

  const handleSubmit = () => {
    const { firstName, lastName, email, password, roleId } = userData;
    const newErrors = {
      firstName: firstName ? "" : "First name is required",
      lastName: lastName ? "" : "Last name is required",
      email: /\S+@\S+\.\S+/.test(email) ? "" : "Email is invalid",
      password: password ? "" : "Password is required",
      roleId: roleId ? "" : "Role is required",
    };
    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error);
    if (hasErrors) {
      toast.error("Please fill all required fields correctly.");
      return;
    }

    if (userToEdit) {
      dispatch(editUser({ userId: userToEdit.id, userData }))
        .then((res) => {
          if (res.type === "users/editUser/fulfilled") {
            toast.success("User edited successfully");
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      dispatch(addUser(userData))
        .then((res) => {
          if (res.type === "users/addUser/fulfilled") {
            toast.success("User added successfully");
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  useEffect(() => {
    if (userToEdit) {
      setUserData({
        firstName: userToEdit.firstName,
        lastName: userToEdit.lastName,
        email: userToEdit.email,
        password: "",
        phoneNumber: "",
        dateOfBirth: userToEdit.dateOfBirth,
        roleId: userToEdit.RoleId,
        teamId: 0,
      });
    }
  }, [userToEdit]);

  useEffect(() => {
    if (
      !deleteLoading &&
      !editLoading &&
      !addLoading &&
      !assignRoleLoading &&
      (state.open || confirmOpen || assignRoleOpen)
    ) {
      handleClose();
      handleCancel();
      setAssignRoleOpen(false);
    }
  }, [deleteLoading, editLoading, addLoading, assignRoleLoading]);

  const handleEdit = (user: UserResponse) => {
    setUserToEdit(user);
    setState({ open: true, size: "tiny" });
  };

  const openConfirm = (userId: number) => {
    setUserToDelete(userId);
    setConfirmOpen(true);
  };

  const handleConfirm = () => {
    if (userToDelete !== null) {
      dispatch(deleteUser(userToDelete))
        .then((res) => {
          if (res.type === "users/deleteUser/fulfilled") {
            toast.success("User deleted successfully");
            setConfirmOpen(false);
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleAssignSuper = (userId: number) => {
    setSelectedUserId(userId);
    setAssignSuperTenantOpen(true);
  };

  const handleUnassignSuper = (userId: number) => {
    setSelectedUserId(userId);
    setUnassignSuperTenantOpen(true);
  };

  const handleAssignSuperTenant = () => {
    if (currentUser) {
      dispatch(
        assignSuperTenant({
          userId: currentUser.id.toString(),
        })
      )
        .then((res) => {
          if (res.type === "users/fetchUsers/assignSupertenant/fulfilled") {
            toast.success("super tenant assigned successfully");
            setAssignSuperTenantOpen(false);
            setCurrentUser(null);
          } else if (
            res.type === "users/fetchUsers/assignSupertenant/rejected"
          ) {
            setAssignSuperTenantOpen(false);
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleUnassignSuperTenant = () => {
    if (currentUser) {
      dispatch(
        unAssignSuperTenant({
          userId: currentUser.id.toString(),
        })
      )
        .then((res) => {
          if (res.type === "users/fetchUsers/unAssignSupertenant/fulfilled") {
            toast.success("super tenant unassigned successfully");
            setUnassignSuperTenantOpen(false);
            setCurrentUser(null);
          } else if (
            res.type === "users/fetchUsers/unAssignSupertenant/rejected"
          ) {
            setUnassignSuperTenantOpen(false);
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const onAssignSuperTenantConfirm = (user: UserResponse | null) => {
    setCurrentUser(user);
    setAssignSuperTenantOpen(true);
  };

  const onUnassignSuperTenantConfirm = (user: UserResponse | null) => {
    setCurrentUser(user);
    setUnassignSuperTenantOpen(true);
  };

  const handleCancel = () => {
    setConfirmOpen(false);
    setUserToDelete(null);
  };

  const handleActivateConfirmCancel = () => {
    setAssignSuperTenantOpen(false);
    setCurrentUser(null);
  };

  const handleSuspendConfirmCancel = () => {
    setUnassignSuperTenantOpen(false);
    setCurrentUser(null);
  };

  const handleAssignRole = (userId: number) => {
    setSelectedUserId(userId);
    setAssignRoleOpen(true);
  };

  const handleAssignRoleSubmit = () => {
    if (selectedUserId !== null) {
      dispatch(assignRole({ userId: selectedUserId, roleIds: selectedRoles }))
        .then((res) => {
          if (res.type === "users/assignRole/fulfilled") {
            toast.success("Role assigned successfully");
            setAssignRoleOpen(false);
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleToggle = (userId: number, isActive: boolean) => {
    const action = isActive ? "deactivate" : "activate";
    dispatch(changeUserStatus({ userId, action }))
      .then((res) => {
        if (res.type === "users/changeUserStatus/fulfilled") {
          toast.success(`User ${action}d successfully`);
          // Refetch users after status change
          dispatch(fetchUsers());
        } else {
          throw new Error(res.payload as string);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleRoleSelectChange = (e: any, data: any) => {
    setSelectedRoles(data.value);
  };

  const handlePageChange = (e: any, { activePage }: any) => {
    setActivePage(activePage);
  };

  const indexOfLastUser = activePage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div>
      <Modal
        open={resetPasswordOpen}
        size="tiny"
        onClose={() => setResetPasswordOpen(false)}
      >
        <Modal.Header>Reset Password</Modal.Header>
        <Modal.Content>
          <Form>
            <FormField>
              <label>New Password</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
              />
            </FormField>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setResetPasswordOpen(false)}>Cancel</Button>
          <Button
            primary
            onClick={handleResetPasswordSubmit}
            loading={resetPasswordLoading}
          >
            Reset
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        open={assignUserOpen}
        size="tiny"
        onClose={() => setAssignUserOpen(false)}
      >
        <Modal.Header>Assign User</Modal.Header>
        <Modal.Content>
          <Form>
            <FormSelect
              fluid
              label="Select Team"
              options={teamOptions}
              placeholder="Select Team"
              name="team"
              onChange={handleTeamSelectChange}
              value={selectedTeam}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setAssignUserOpen(false)}>Cancel</Button>
          <Button
            primary
            onClick={handleAssignUserSubmit}
            loading={assignUserLoading}
          >
            Assign
          </Button>
        </Modal.Actions>
      </Modal>
      <DefaultModal
        title={userToEdit ? "Edit User" : "Add New User"}
        buttonTitle="Save"
        open={state.open}
        size={state.size}
        dimmer="default"
        onClose={handleClose}
        onSave={handleSubmit}
        loading={userToEdit ? editLoading : addLoading} // Pass the loading state
      >
        <Form>
          <FormGroup widths="equal">
            <FormField error={!!errors.firstName}>
              <label>First Name</label>
              <input
                name="firstName"
                value={userData.firstName}
                onChange={handleChange}
                placeholder="First Name"
              />
            </FormField>
            <FormField error={!!errors.lastName}>
              <label>Last Name</label>
              <input
                name="lastName"
                value={userData.lastName}
                onChange={handleChange}
                placeholder="Last Name"
              />
            </FormField>
          </FormGroup>
          <FormGroup widths="equal">
            <FormField error={!!errors.email}>
              <label>Email</label>
              <input
                name="email"
                value={userData.email}
                onChange={handleChange}
                type="email"
                placeholder="Email"
              />
            </FormField>{" "}
            <FormField error={!!errors.password}>
              <label>Password</label>
              <input
                name="password"
                value={userData.password}
                onChange={handleChange}
                type="password"
                placeholder="Password"
              />
            </FormField>
          </FormGroup>
          <FormGroup widths="equal">
            <FormSelect
              fluid
              label="Select Role"
              options={options}
              placeholder="Add Role"
              name="roleId"
              onChange={handleSelectChange}
              value={userData.roleId}
              error={!!errors.roleId}
            />
            <FormSelect
              fluid
              label="Select Team : Optional"
              options={teamOptions}
              placeholder="Assign Team"
              name="teamId"
              onChange={handleSelectTeamChange}
              value={userData.teamId}
            />
          </FormGroup>
        </Form>
      </DefaultModal>
      <Confirm
        open={confirmOpen}
        size="tiny"
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        content="Are you sure you want to delete this user?"
        confirmButton={{ content: "Delete", loading: deleteLoading }}
      />
      <Confirm
        open={assignSuperTenantOpen}
        onCancel={handleActivateConfirmCancel}
        onConfirm={handleAssignSuperTenant}
        size="tiny"
        content="Are you sure do you want to make this user super tenant?"
        confirmButton={{ content: "Make Super Tenant", loading: deleteLoading }}
      />
      <Confirm
        open={unassignSuperTenantOpen}
        onCancel={handleSuspendConfirmCancel}
        onConfirm={handleUnassignSuperTenant}
        size="tiny"
        content="Are you sure do you want to remove super tenant from this user?"
        confirmButton={{
          content: "Remove Super Tenant",
          loading: deleteLoading,
        }}
      />
      <Modal
        open={assignRoleOpen}
        size="tiny"
        onClose={() => setAssignRoleOpen(false)}
      >
        <Modal.Header>Change Roles</Modal.Header>
        <Modal.Content>
          <Form>
            <FormSelect
              fluid
              label="Select Roles"
              options={options}
              placeholder="Change roles"
              name="roles"
              onChange={handleRoleSelectChange}
              value={selectedRoles}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setAssignRoleOpen(false)}>Cancel</Button>
          <Button
            primary
            onClick={handleAssignRoleSubmit}
            loading={assignRoleLoading}
          >
            Assign
          </Button>
        </Modal.Actions>
      </Modal>
      <div className="m-3">
        <div className="bg-white border shadow-sm rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div className="my-5">
              <Input icon="search" placeholder="Search..." />
            </div>
            <Button primary size="tiny" onClick={handleOpen}>
              <div className="flex">
                <IoMdAdd />
                Add User
              </div>
            </Button>
          </div>

          <div>
            {loading ? (
              <SkeletonTable />
            ) : (
              <>
                <Table basic="very">
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell width={"3"}>Full Name</TableHeaderCell>
                      <TableHeaderCell>Email</TableHeaderCell>
                      <TableHeaderCell>Roles</TableHeaderCell>
                      <TableHeaderCell>Status</TableHeaderCell>
                      <TableHeaderCell>Actions</TableHeaderCell>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {currentUsers.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell width={2}>
                          {`${user.firstName} ${user.lastName}`}{" "}
                          {user.isSuperTenant && (
                            <span className="border rounded-xl shadow px-2 border-[var(--brand-primary-color)] text-[var(--brand-primary-color)]">
                              Super
                            </span>
                          )}
                        </TableCell>
                        <TableCell width="3">{user.email}</TableCell>
                        <TableCell>{user.Role?.name}</TableCell>
                        <Table.Cell>
                          {activateLoading && activeStartIndex === user.id ? (
                            <Loader active inline size="small" />
                          ) : (
                            <Checkbox
                              toggle
                              loading={activateLoading}
                              checked={user.isActive}
                              onChange={() => {
                                setActiveStartIndex(user.id);
                                handleToggle(user.id, user.isActive);
                              }}
                            />
                          )}
                        </Table.Cell>
                        <TableCell width={2}>
                          <Dropdown trigger={<BsThreeDots />} icon={null}>
                            <DropdownMenu>
                              <DropdownItem
                                icon="pencil alternate"
                                text="Edit"
                                onClick={() => handleEdit(user)}
                              />
                              <DropdownItem
                                onClick={() => openConfirm(user.id)}
                                icon="trash"
                                text="Delete"
                              />
                              <DropdownItem
                                onClick={() => handleAssignRole(user.id)}
                                icon="exchange"
                                text="Change Role"
                              />
                              <DropdownItem
                                onClick={() => handleResetPassword(user.id)}
                                icon="lock"
                                text="Reset Password"
                              />
                              <DropdownItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAssignUser(user.id);
                                }}
                                icon="plus"
                                text="Assign To Team"
                              />
                              {!user.isSuperTenant ? (
                                <DropdownItem
                                  onClick={() =>
                                    onAssignSuperTenantConfirm(user)
                                  }
                                  icon="check"
                                  text="Make Super Tenant"
                                />
                              ) : (
                                <DropdownItem
                                  onClick={() =>
                                    onUnassignSuperTenantConfirm(user)
                                  }
                                  icon="ban"
                                  text="Remove Super Tenant"
                                />
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Pagination
                  activePage={activePage}
                  onPageChange={handlePageChange}
                  totalPages={Math.ceil(users.length / itemsPerPage)}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
