import React, { useState } from "react";
import HeaderContainer from "../constants/HeaderContainer";
import ManageCapacity from "./components/powerbisettings/ManageCapacity";
// import ManageGateway from "./components/powerbisettings/ManageGateway";

const PowerBISetting = () => {
  const [activeTab, setActiveTab] = useState("Manage Capacity");

  return (
    <div className="bg-gray-100">
      <HeaderContainer
        title="Manage Power BI Settings"
        description="Setup Power BI Configurations"
        tabs={["Manage Capacity"]}
        activeTab={activeTab}
        onTabClick={setActiveTab}
      />
      <div>
        {activeTab === "Manage Capacity" && <ManageCapacity />}
        {/* {activeTab === "Manage Gateway" && <ManageGateway />} */}
      </div>
    </div>
  );
};

export default PowerBISetting;
