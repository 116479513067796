import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import * as powerbiClient from "powerbi-client";

interface EmbedTokenResponse {
  token: string;
  expiration: string;
}

interface EmbedTokenState {
  embedToken: EmbedTokenResponse | null;
  accessToken: string | null;
  loading: boolean;
  accessTokenLoading: boolean;
  embeddedReport: powerbiClient.Report | null;
  error: string | null;
}

const initialState: EmbedTokenState = {
  embedToken: null,
  accessToken: null,
  loading: false,
  accessTokenLoading: false,
  embeddedReport: null,
  error: null,
};

export const fetchEmbedToken = createAsyncThunk<
  EmbedTokenResponse,
  { groupId: string; reportId: string },
  { state: RootState }
>("embedToken/fetchEmbedToken", async ({ groupId, reportId }, { getState }) => {
  const token = getState().authentication.tokens?.token;
  try {
    const response = await axios.get<EmbedTokenResponse>(
      `https://cedarplatform.io:4400/api/v1/workspaces/get-embedtoken?groupId=${groupId}&reportId=${reportId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch embed token");
  }
});

export const fetchAccessToken = createAsyncThunk<
  string,
  void,
  { state: RootState }
>("embedToken/fetchAccessToken", async (_, { getState, rejectWithValue }) => {
  const token = getState().authentication.tokens?.token;

  if (!token) {
    return rejectWithValue("Authentication token not found");
  }

  try {
    const response = await axios.get<{ access_token: string }>(
      "https://cedarplatform.io:4400/api/v1/workspaces/get-token",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.access_token;
  } catch (error) {
    console.error("Failed to fetch access token:", error);
    return rejectWithValue("Failed to fetch access token");
  }
});

const embedTokenSlice = createSlice({
  name: "embedToken",
  initialState,
  reducers: {
    setEmbeddedReport: (state, action: PayloadAction<powerbiClient.Report>) => {
      // @ts-ignore
      state.embeddedReport = action.payload;
    },
    clearEmbeddedReport: (state) => {
      state.embedToken = null;
      state.accessToken = null;
      state.loading = false;
      state.accessTokenLoading = false;
      state.embeddedReport = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmbedToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchEmbedToken.fulfilled, (state, action) => {
        state.loading = false;
        state.embedToken = action.payload;
      })
      .addCase(fetchEmbedToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch embed token";
      })
      .addCase(fetchAccessToken.pending, (state) => {
        state.accessTokenLoading = true;
        state.error = null;
      })
      .addCase(fetchAccessToken.fulfilled, (state, action) => {
        state.accessTokenLoading = false;
        state.accessToken = action.payload;
      })
      .addCase(fetchAccessToken.rejected, (state, action) => {
        state.accessTokenLoading = false;
        state.error = action.error.message || "Failed to fetch access token";
      });
  },
});

export const { setEmbeddedReport, clearEmbeddedReport } =
  embedTokenSlice.actions;
export default embedTokenSlice.reducer;
