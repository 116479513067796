import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { RoleResponse } from "./roleSlice";

interface User {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  dateOfBirth: string;
  roleId: number;
  teamId: number;
}

export interface UserResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isSuperTenant: boolean;
  isActive: boolean;
  phoneNumber: string;
  dateOfBirth: string;
  currentTenant?: string;
  defaultTenant?: string;
  imageUrl?: string;
  Role: RoleResponse;
  RoleId: number;
}

interface UserState {
  users: UserResponse[];
  user: UserResponse | null;
  loading: boolean;
  error: string | null;
  addLoading: boolean;
  editLoading: boolean;
  deleteLoading: boolean;
  assignRoleLoading: boolean;
  changeProfileLoading: boolean;
  activateLoading: boolean;
}

const initialState: UserState = {
  users: [],
  loading: false,
  error: null,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  assignRoleLoading: false,
  changeProfileLoading: false,
  activateLoading: false,
  user: null,
};

const apiUrl = "https://cedarplatform.io:4400/api/v1/users";

const getHeaders = (getState: () => RootState) => {
  const token = getState().authentication.tokens?.token;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const assignSuperTenant = createAsyncThunk<
  void,
  { userId: string },
  { state: RootState; rejectValue: string }
>(
  "users/fetchUsers/assignSupertenant",
  async ({ userId }, { getState, rejectWithValue }) => {
    try {
      await axios.put(
        `${apiUrl}/assign-supertenant`,
        { userId },
        getHeaders(getState)
      );
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to assign super tenant");
    }
  }
);

export const unAssignSuperTenant = createAsyncThunk<
  void,
  { userId: string },
  { state: RootState; rejectValue: string }
>(
  "users/fetchUsers/unAssignSupertenant",
  async ({ userId }, { getState, rejectWithValue }) => {
    try {
      await axios.put(
        `${apiUrl}/unassign-supertenant`,
        { userId },
        getHeaders(getState)
      );
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to un assign super tenant");
    }
  }
);

// Define async thunk to fetch users
export const fetchUser = createAsyncThunk<
  UserResponse,
  void,
  { state: RootState; rejectValue: string }
>("users/fetchUsers", async (_, { getState, rejectWithValue }) => {
  try {
    const response = await axios.get<UserResponse>(
      "https://cedarplatform.io:4400/profile",
      getHeaders(getState)
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to fetch user");
  }
});

export const fetchUsers = createAsyncThunk<
  UserResponse[],
  void,
  { state: RootState; rejectValue: string }
>("users/profile", async (_, { getState, rejectWithValue }) => {
  try {
    const response = await axios.get<UserResponse[]>(
      apiUrl,
      getHeaders(getState)
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to fetch users");
  }
});

// Define async thunk to add a new user
export const addUser = createAsyncThunk<
  UserResponse,
  User,
  { state: RootState; rejectValue: string }
>("users/addUser", async (userData, { getState, rejectWithValue }) => {
  try {
    const response = await axios.post<UserResponse>(
      apiUrl,
      userData,
      getHeaders(getState)
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to add user");
  }
});

// Define async thunk to edit a user
export const editUser = createAsyncThunk<
  UserResponse,
  { userId: number; userData: User },
  { state: RootState; rejectValue: string }
>(
  "users/editUser",
  async ({ userId, userData }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.put<UserResponse>(
        `${apiUrl}/${userId}`,
        userData,
        getHeaders(getState)
      );
      return response.data;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to edit user");
    }
  }
);

export const updateProfile = createAsyncThunk<
  UserResponse,
  {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    dateOfBirth?: string;
  },
  { state: RootState; rejectValue: string }
>("users/updateProfile", async (profileData, { getState, rejectWithValue }) => {
  try {
    const token = getState().authentication.tokens?.token;
    const response = await axios.put<{ data: UserResponse }>(
      `${apiUrl}`,
      profileData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to update profile");
  }
});

export const changePassword = createAsyncThunk<
  string,
  { previousPassword: string; newPassword: string },
  { state: RootState; rejectValue: string }
>(
  "users/changePassword",
  async ({ previousPassword, newPassword }, { getState, rejectWithValue }) => {
    try {
      const token = getState().authentication.tokens?.token;
      const response = await axios.put<{ message: string }>(
        `${apiUrl}/changepassword`,
        { previousPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.message;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to change password");
    }
  }
);

export const forgotPassword = createAsyncThunk<
  string,
  { email: string },
  { state: RootState; rejectValue: string }
>("users/forgetpassword", async ({ email }, { getState, rejectWithValue }) => {
  try {
    const token = getState().authentication.tokens?.token;
    const response = await axios.put<{ message: string }>(
      `https://cedarplatform.io:4400/auth/forgetpassword`,
      { email }
    );
    return response.data.message;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to forgot password");
  }
});

export const changeUserStatus = createAsyncThunk<
  UserResponse,
  { userId: number; action: "activate" | "deactivate" },
  { state: RootState; rejectValue: string }
>(
  "users/changeUserStatus",
  async ({ userId, action }, { getState, rejectWithValue }) => {
    try {
      const token = getState().authentication.tokens?.token;
      const response = await axios.put<UserResponse>(
        `${apiUrl}/change-status`,
        { id: userId, action },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to change user status");
    }
  }
);

export const changeProfile = createAsyncThunk<
  string,
  { formData: FormData },
  { state: RootState; rejectValue: string }
>(
  "users/changeProfile",
  async ({ formData }, { getState, rejectWithValue }) => {
    try {
      const token = getState().authentication.tokens?.token;
      const response = await axios.put<{ message: string }>(
        `${apiUrl}/change-profile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data.message;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to change profile");
    }
  }
);

// Define async thunk to delete a user
export const deleteUser = createAsyncThunk<
  number,
  number,
  { state: RootState; rejectValue: string }
>("users/deleteUser", async (userId, { getState, rejectWithValue }) => {
  try {
    await axios.delete(`${apiUrl}/${userId}`, getHeaders(getState));
    return userId;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to delete user");
  }
});

// Define async thunk to assign roles to a user
export const assignRole = createAsyncThunk<
  UserResponse,
  { userId: number; roleIds: number[] },
  { state: RootState; rejectValue: string }
>(
  "users/assignRole",
  async ({ userId, roleIds }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.put<UserResponse>(
        `${apiUrl}/assign-role`,
        { userId, roleIds },
        getHeaders(getState)
      );
      return response.data;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to assign roles");
    }
  }
);

export const resetPassword = createAsyncThunk<
  string,
  { userId: number; newPassword: string },
  { state: RootState; rejectValue: string }
>(
  "users/resetPassword",
  async ({ userId, newPassword }, { getState, rejectWithValue }) => {
    try {
      const token = getState().authentication.tokens?.token;
      const response = await axios.put<{ message: string }>(
        `${apiUrl}/resetpassword/${userId}`,
        { newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.message;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to reset password");
    }
  }
);

export const createPassword = createAsyncThunk<
  string,
  { token: string; newPassword: string },
  { state: RootState; rejectValue: string }
>(
  "users/createPassword",
  async ({ token, newPassword }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post<{ message: string }>(
        `https://cedarplatform.io:4400/auth/resetPassword/${token}`,
        { newPassword }
      );
      return response.data.message;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to reset password");
    }
  }
);

// Create a slice for users
const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle fetchUsers
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || action.error.message || "Failed to fetch users";
      })
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || action.error.message || "Failed to fetch user";
      })
      // Handle addUser
      .addCase(addUser.pending, (state) => {
        state.addLoading = true;
        state.error = null;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.addLoading = false;
        state.users.push(action.payload);
      })
      .addCase(addUser.rejected, (state, action) => {
        state.addLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to add user";
      })
      // Handle editUser
      .addCase(editUser.pending, (state) => {
        state.editLoading = true;
        state.error = null;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.editLoading = false;
        const index = state.users.findIndex(
          (user) => user.id === action.payload.id
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
      })
      .addCase(editUser.rejected, (state, action) => {
        state.editLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to edit user";
      })
      // Handle updateProfile
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || action.error.message || "Failed to update profile";
      })
      // Handle changePassword
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally, you can add a message to the state to show a success message
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || action.error.message || "Failed to change password";
      })
      // Handle changeProfile
      .addCase(changeProfile.pending, (state) => {
        state.changeProfileLoading = true;
        state.error = null;
      })
      .addCase(changeProfile.fulfilled, (state, action) => {
        state.changeProfileLoading = false;
        // Optionally, you can add a message to the state to show a success message
      })
      .addCase(changeProfile.rejected, (state, action) => {
        state.changeProfileLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to change profile";
      })
      // Handle deleteUser
      .addCase(deleteUser.pending, (state) => {
        state.deleteLoading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.users = state.users.filter((user) => user.id !== action.payload);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to delete user";
      })
      // Handle assignRole
      .addCase(assignRole.pending, (state) => {
        state.assignRoleLoading = true;
        state.error = null;
      })
      .addCase(assignRole.fulfilled, (state, action) => {
        state.assignRoleLoading = false;
        const index = state.users.findIndex(
          (user) => user.id === action.payload.id
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
      })
      .addCase(assignRole.rejected, (state, action) => {
        state.assignRoleLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to assign roles";
      })
      .addCase(changeUserStatus.pending, (state) => {
        state.activateLoading = true;
        state.error = null;
      })
      .addCase(changeUserStatus.fulfilled, (state, action) => {
        state.activateLoading = false;
        const index = state.users.findIndex(
          (user) => user.id === action.payload.id
        );
        if (index !== -1) {
          state.users[index] = action.payload;
        }
      })
      .addCase(changeUserStatus.rejected, (state, action) => {
        state.activateLoading = false;
        state.error =
          action.payload ||
          action.error.message ||
          "Failed to change user status";
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || action.error.message || "Failed to reset password";
      })
      .addCase(assignSuperTenant.pending, (state) => {
        state.deleteLoading = true;
        state.error = null;
      })
      .addCase(assignSuperTenant.fulfilled, (state, action) => {
        state.deleteLoading = false;
        const userId = action.meta.arg.userId;
        const tenantIndex = state.users.findIndex(
          (user) => user.id === parseInt(userId, 10)
        );
        if (tenantIndex !== -1) {
          state.users[tenantIndex].isSuperTenant = true;
        }
      })
      .addCase(assignSuperTenant.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error =
          action.payload ||
          action.error.message ||
          "Failed to assign super tenant";
      })
      .addCase(unAssignSuperTenant.pending, (state) => {
        state.deleteLoading = true;
        state.error = null;
      })
      .addCase(unAssignSuperTenant.fulfilled, (state, action) => {
        state.deleteLoading = false;
        const userId = action.meta.arg.userId;
        const tenantIndex = state.users.findIndex(
          (user) => user.id === parseInt(userId, 10)
        );
        if (tenantIndex !== -1) {
          state.users[tenantIndex].isSuperTenant = false;
        }
      })
      .addCase(unAssignSuperTenant.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error =
          action.payload ||
          action.error.message ||
          "Failed to unassign super tenant";
      });
  },
});

export const selectUsers = (state: RootState) => state.users;

export default userSlice.reducer;
