import React, { FC, useState } from "react";
import { NavLink } from "react-router-dom";

type HeaderContainerProps = {
  title: string;
  description?: string;
  tabs?: string[];
  activeTab?: string;
  onTabClick?: (tab: string) => void;
};

const HeaderContainer: FC<HeaderContainerProps> = ({
  title,
  description,
  tabs,
  activeTab,
  onTabClick,
}) => {
  return (
    <div className="w-full bg-[var(--page-name-current-page)] flex flex-col px-12 pt-1">
      <span className="text-xl font-semibold mb-1 text-[var(--content-pane-titles)]">
        {title}
      </span>
      <span className="flex items-center space-x-5">
        {description && (
          <span className="text-[var(--button-face-color)]">{description}</span>
        )}
        {tabs && (
          <div className="text-sm font-medium text-center text-gray-500 border-gray-700">
            <ul className="flex flex-wrap">
              {tabs.map((tab, index) => (
                <li key={index} className="mt-1">
                  <NavLink
                    to={``}
                    className={`inline-block p-2 px-3 rounded-t-lg text-primary hover:text-[var(--navigation-arrow-color)] ${
                      activeTab === tab
                        ? "border-[var(--navigation-border-color)] border-t border-l border-r text-[var(--navigation-arrow-color)] bg-white"
                        : "border-b border-[var(--navigation-border-color)]"
                    }`}
                    onClick={() => onTabClick && onTabClick(tab)}
                  >
                    {tab}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        )}
      </span>
    </div>
  );
};

export default HeaderContainer;
