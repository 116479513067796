import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  FormField,
  Input,
  Modal,
  Loader,
  Segment,
  Grid,
  GridRow,
  GridColumn,
} from "semantic-ui-react";
import logo from "./../../../assets/images/logo.jpg";
import { AppDispatch, RootState } from "../../../store/store";
import {
  editBranding,
  fetchBranding,
} from "../../../store/slices/brandingSlice";
import loader from "./../../../assets/images/Ripple@1x-1.0s-200px-200px.gif";

const Branding = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { branding, loading } = useSelector(
    (state: RootState) => state.branding
  );

  const [open, setOpen] = useState(false);
  const [size, setSize] = useState<
    "small" | "mini" | "tiny" | "large" | "fullscreen" | undefined
  >(undefined);
  const [currentImage, setCurrentImage] = useState<string | null>(null);
  const [newImage, setNewImage] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>(new FormData());

  useEffect(() => {
    dispatch(fetchBranding());
    console.log("from here");
  }, [dispatch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    formData.set(name, value);
    setFormData(formData);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      formData.set(name, files[0]);
      setNewImage(URL.createObjectURL(files[0])); // Set new image URL for preview
      setFormData(formData);
    }
  };

  const handleSaveChanges = () => {
    if (currentImage) {
      dispatch(editBranding({ formData }));
      setOpen(false);
      setCurrentImage(null);
      setNewImage(null);
    }
  };

  const openModal = (imageType: string) => {
    setNewImage(null);
    setCurrentImage(imageType);
    setSize("tiny");
    setOpen(true);
  };

  const handleCancel = () => {
    setCurrentImage(null);
    setNewImage(null);
    setOpen(false);
  };

  const getImageUrl = () => {
    switch (currentImage) {
      case "logoImage":
        return branding?.logoImage ? branding?.logoImage : logo;
      case "secondaryLogoImage":
        return branding?.secondaryLogoImage
          ? branding?.secondaryLogoImage
          : logo;
      case "siteFaviconImage":
        return branding?.siteFaviconImage ? branding?.siteFaviconImage : logo;
      case "customLoader":
        return branding?.customLoader ? branding?.customLoader : loader;
      default:
        return logo;
    }
  };

  return (
    <div className="p-6 border m-3 rounded">
      <Modal
        open={open}
        size={size}
        onClose={handleCancel}
        onOpen={() => setOpen(true)}
      >
        <Modal.Header>Edit {currentImage}</Modal.Header>
        <Modal.Content>
          <div className="mb-4">
            <label htmlFor={currentImage || ""}>{currentImage}</label>
            <Input
              type="file"
              id={currentImage || ""}
              name={currentImage || ""}
              onChange={handleFileChange}
            />
          </div>
          <Segment placeholder>
            <Grid columns={2} stackable textAlign="center">
              <Divider vertical>To</Divider>

              <GridRow verticalAlign="middle">
                <GridColumn>
                  <div className="mb-4 flex items-center justify-center">
                    <img
                      src={getImageUrl()}
                      alt={currentImage || ""}
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                </GridColumn>

                <GridColumn>
                  <div className="mb-4 flex items-center justify-center">
                    <img
                      src={newImage || getImageUrl()}
                      alt={currentImage || ""}
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                </GridColumn>
              </GridRow>
            </Grid>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button primary onClick={handleSaveChanges}>
            Apply
          </Button>
        </Modal.Actions>
      </Modal>

      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-xl font-semibold">Primary Branding</h2>
          <p className="text-gray-600 text-sm -mt-4">
            Set primary logo and text
          </p>
        </div>
        <Button
          primary
          size="tiny"
          onClick={handleSaveChanges}
          disabled={loading}
        >
          {loading ? <Loader size="small" active inline /> : "Save Changes"}
        </Button>
      </div>

      <div className="grid grid-cols-2 gap-8 mt-8">
        <div className="border rounded-xl p-4 shadow-sm">
          <div className="flex justify-between items-center mb-4 space-x-8">
            <div className="flex flex-col">
              <span className="font-semibold whitespace-nowrap mb-3">
                Logo Image
              </span>
              <p>
                This is used in the application header bar, among other areas.
                This image should be 175px wide and 35px in height. Large Images
                will be scaled down.
              </p>
            </div>
            <div className="flex space-x-5">
              <div>
                <div className="border w-fit p-1">
                  <img
                    src={branding?.logoImage ? branding?.logoImage : logo}
                    alt="logo"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="my-2 whitespace-nowrap">
                  <Button
                    size="mini"
                    basic
                    color="red"
                    onClick={() => openModal("logoImage")}
                  >
                    Edit Logo
                  </Button>
                </div>
              </div>
              <div>
                <div className="border w-fit p-1">
                  <img
                    src={
                      branding?.secondaryLogoImage
                        ? branding?.secondaryLogoImage
                        : logo
                    }
                    alt="logo"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="my-2 whitespace-nowrap">
                  <Button
                    size="mini"
                    basic
                    color="red"
                    onClick={() => openModal("secondaryLogoImage")}
                  >
                    Edit Secondary Logo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border rounded-xl p-4 shadow-sm">
          <div className="flex justify-between items-center mb-4 space-x-8">
            <div className="flex flex-col">
              <span className="font-semibold whitespace-nowrap mb-3">
                Site Favicon Image
              </span>
              <p>
                This is used for browser tabs and shortcut icons. This should be
                a 256px square PNG image.
              </p>
            </div>
            <div className="flex space-x-5">
              <div>
                <div className="border p-1">
                  <img
                    src={
                      branding?.siteFaviconImage
                        ? branding?.siteFaviconImage
                        : logo
                    }
                    width={100}
                    height={100}
                    alt="favicon"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="my-2 whitespace-nowrap">
                  <Button
                    size="mini"
                    basic
                    color="red"
                    onClick={() => openModal("siteFaviconImage")}
                  >
                    Select Image
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border rounded-xl p-4 shadow-sm">
          <div className="flex justify-between items-center mb-4 space-x-8">
            <div className="flex flex-col">
              <span className="font-semibold whitespace-nowrap mb-3">
                Custom Loader
              </span>
              <p>
                This icon is used when the page is loading data. This should be
                a 256px square GIF image.
              </p>
            </div>
            <div className="flex space-x-5">
              <div>
                <div className="border p-1">
                  <img
                    src={
                      branding?.customLoader ? branding?.customLoader : loader
                    }
                    width={100}
                    height={100}
                    alt="custom loader"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div className="my-2 whitespace-nowrap">
                  <Button
                    size="mini"
                    basic
                    color="red"
                    onClick={() => openModal("customLoader")}
                  >
                    Select Image
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default Branding;
