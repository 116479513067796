import React, { useState } from "react";
import HeaderContainer from "../constants/HeaderContainer";
import AuthScheme from "./components/appsettings/AuthScheme";
import TenantAdmin from "./components/appsettings/TenantAdmin";
// import Integration from "./components/appsettings/Integration";
// import LanguageEditor from "./components/appsettings/LanguageEditor";

const AppSettings = () => {
  const [activeTab, setActiveTab] = useState("Tenant Admin");

  return (
    <div>
      <HeaderContainer
        title="Authentication settings"
        description="Authentication settings"
        tabs={[
          "Tenant Admin",
          // "Integration",
          // "Auth Schemes",
          // "Language Editor",
        ]}
        activeTab={activeTab}
        onTabClick={setActiveTab}
      />
      <div>
        {activeTab === "Tenant Admin" && <TenantAdmin />}
        {/* {activeTab === "Auth Schemes" && <AuthScheme />} */}
        {/* {activeTab === "Integration" && <Integration />}
        {activeTab === "Language Editor" && <LanguageEditor />} */}
      </div>
    </div>
  );
};

export default AppSettings;
