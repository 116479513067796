import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

export interface Role {
  // id: number;
  name: string;
  description: string;
}

interface Permissions {
  id: number;
  name: string;
  description: string;
  isSuperTenantPermission: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface RoleResponse {
  id: number;
  name: string;
  TenantId: number;
  description: string;
  Permissions: Permissions[];
}

interface RoleState {
  roles: RoleResponse[];
  loading: boolean;
  error: string | null;
  addLoading: boolean;
  editLoading: boolean;
  deleteLoading: boolean;
  assignLoading: boolean;
}

const initialState: RoleState = {
  roles: [],
  loading: false,
  error: null,
  addLoading: false,
  editLoading: false,
  deleteLoading: false,
  assignLoading: false,
};

const apiUrl = "https://cedarplatform.io:4400/api/v1/roles";

const getHeaders = (getState: () => RootState) => {
  const token = getState().authentication.tokens?.token;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const fetchRoles = createAsyncThunk<
  RoleResponse[],
  void,
  { state: RootState; rejectValue: string }
>("roles/fetchRoles", async (_, { getState, rejectWithValue }) => {
  try {
    const response = await axios.get<RoleResponse[]>(
      apiUrl,
      getHeaders(getState)
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to fetch roles");
  }
});

export const addRole = createAsyncThunk<
  RoleResponse,
  Omit<Role, "id">,
  { state: RootState; rejectValue: string }
>("roles/addRole", async (roleData, { getState, rejectWithValue }) => {
  try {
    const response = await axios.post<{ role: RoleResponse }>(
      apiUrl,
      roleData,
      getHeaders(getState)
    );
    return response.data.role;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to add role");
  }
});

export const editRole = createAsyncThunk<
  RoleResponse,
  { roleId: number; roleData: Role },
  { state: RootState; rejectValue: string }
>(
  "roles/editRole",
  async ({ roleId, roleData }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.put<{ data: RoleResponse }>(
        `${apiUrl}/${roleId}`,
        roleData,
        getHeaders(getState)
      );
      return response.data.data;
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue("Failed to edit role");
    }
  }
);

export const deleteRole = createAsyncThunk<
  number,
  number,
  { state: RootState; rejectValue: string }
>("roles/deleteRole", async (roleId, { getState, rejectWithValue }) => {
  try {
    await axios.delete(`${apiUrl}/${roleId}`, getHeaders(getState));
    return roleId;
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    }
    return rejectWithValue("Failed to delete role");
  }
});

const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.roles = action.payload;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || action.error.message || "Failed to fetch roles";
      })
      .addCase(addRole.pending, (state) => {
        state.addLoading = true;
        state.error = null;
      })
      .addCase(addRole.fulfilled, (state, action) => {
        state.addLoading = false;
        state.roles.push(action.payload);
      })
      .addCase(addRole.rejected, (state, action) => {
        state.addLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to add role";
      })
      .addCase(editRole.pending, (state) => {
        state.editLoading = true;
        state.error = null;
      })
      .addCase(editRole.fulfilled, (state, action) => {
        state.editLoading = false;
        const editedRoleIndex = state.roles.findIndex(
          (role) => role.id === action.payload.id
        );
        if (editedRoleIndex !== -1) {
          state.roles[editedRoleIndex] = action.payload;
        }
      })
      .addCase(editRole.rejected, (state, action) => {
        state.editLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to edit role";
      })
      .addCase(deleteRole.pending, (state) => {
        state.deleteLoading = true;
        state.error = null;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.deleteLoading = false;
        state.roles = state.roles.filter((role) => role.id !== action.payload);
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.deleteLoading = false;
        state.error =
          action.payload || action.error.message || "Failed to delete role";
      });
  },
});

export default roleSlice.reducer;
