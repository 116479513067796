import { fetchAccessToken } from "../store/slices/embedTokenSlice";
import { AppDispatch } from "../store/store";

const PowerBiApiRoot: string = "https://api.powerbi.com/v1.0/myorg/";

export interface PowerBiExportRequest {
  format: string;
  exportStatusCallback?: (statusMessage: string) => void;
}

interface PowerBiExportJob {
  id: string;
  percentComplete: number;
  status: string;
  resourceLocation: string;
  reportName: string;
  resourceFileExtension: string;
}

class PowerBiService {
  private dispatch: AppDispatch;
  private accessToken: string | null = null;

  constructor(dispatch: AppDispatch) {
    this.dispatch = dispatch;
  }

  private async getAccessToken(): Promise<string> {
    if (!this.accessToken) {
      this.accessToken = await this.dispatch(fetchAccessToken()).unwrap();
    }
    return this.accessToken;
  }

  static async ExportReport(
    WorkspaceId: string,
    ReportId: string,
    ExportRequest: PowerBiExportRequest,
    dispatch: AppDispatch
  ): Promise<void> {
    const service = new PowerBiService(dispatch);
    console.log("ExportReport");

    const setExportStatusMessage = ExportRequest.exportStatusCallback;
    console.log("setExportStatusMessage", setExportStatusMessage);

    const accessToken = await service.getAccessToken();
    let exportJob: PowerBiExportJob = await service.startExportReportJob(
      WorkspaceId,
      ReportId,
      ExportRequest,
      accessToken
    );

    if (setExportStatusMessage) {
      setExportStatusMessage(
        "[" + exportJob.percentComplete + "%] " + exportJob.status
      );
    }

    let waitTime = "";

    while (exportJob.status !== "Succeeded" && exportJob.status !== "Failed") {
      await new Promise((f) => setTimeout(f, 1000));
      if (setExportStatusMessage) {
        setExportStatusMessage(
          "[" + exportJob.percentComplete + "%] " + exportJob.status + waitTime
        );
      }
      if (exportJob.id) {
        exportJob = await service.getExportReportJobStatus(
          WorkspaceId,
          ReportId,
          exportJob.id,
          accessToken
        );
      } else {
        console.log("exportJob.id does not exist");
      }
      console.log(exportJob.status);
      waitTime += ".";
    }

    if (setExportStatusMessage) {
      setExportStatusMessage(
        "[" + exportJob.percentComplete + "%] " + exportJob.status + waitTime
      );
    }

    await fetch(exportJob.resourceLocation, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((res) => res.blob())
      .then((data) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = exportJob.reportName + exportJob.resourceFileExtension;
        a.click();
      });
  }

  private async startExportReportJob(
    WorkspaceId: string,
    ReportId: string,
    exportRequest: PowerBiExportRequest,
    accessToken: string
  ): Promise<PowerBiExportJob> {
    const restUrl =
      WorkspaceId === "MY_WORKSPACE_ID"
        ? `${PowerBiApiRoot}reports/${ReportId}/ExportTo`
        : `${PowerBiApiRoot}groups/${WorkspaceId}/reports/${ReportId}/ExportTo`;

    const postBody = JSON.stringify(exportRequest);

    const response = await fetch(restUrl, {
      method: "POST",
      body: postBody,
      headers: {
        Accept: "application/json;odata.metadata=minimal;",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + accessToken,
      },
    });

    return response.json();
  }

  private async getExportReportJobStatus(
    WorkspaceId: string,
    ReportId: string,
    ExportId: string,
    accessToken: string
  ): Promise<PowerBiExportJob> {
    const restUrl =
      WorkspaceId === "MY_WORKSPACE_ID"
        ? `${PowerBiApiRoot}reports/${ReportId}/exports/${ExportId}`
        : `${PowerBiApiRoot}groups/${WorkspaceId}/reports/${ReportId}/exports/${ExportId}`;

    const response = await fetch(restUrl, {
      mode: "cors",
      headers: {
        Accept: "application/json;odata.metadata=minimal;",
        Authorization: "Bearer " + accessToken,
      },
    });

    return response.json();
  }
}

export default PowerBiService;
