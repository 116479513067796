import React, { useEffect, useState } from "react";
import { RiDashboardFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import logo from "./../../assets/images/logo.jpg";
import { BiMenu } from "react-icons/bi";
import { AppDispatch, RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { hasPermission } from "../../utils/roleUtils";
import SettingMenus from "../../config/menuConfig";
import { useDispatch } from "react-redux";
import { fetchBranding } from "../../store/slices/brandingSlice";
import { Dropdown } from "semantic-ui-react";

const SettingSidebar = () => {
  const user = useSelector((state: RootState) => state.users.user);
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { branding } = useSelector((state: RootState) => state.branding);
  const { tenants } = useSelector((state: RootState) => state.tenants);

  useEffect(() => {
    if (!branding) {
      dispatch(fetchBranding());
    }
  }, [dispatch, branding]);

  return (
    <div
      className={`bg-[var(--brand-primary-color)] h-screen ${
        open ? "w-80" : "w-20"
      } duration-300 relative`}
    >
      <BiMenu
        onClick={() => setOpen(!open)}
        className={`text-[var(--top-navigation-font)] text-3xl absolute z-[100] -right-14 top-8 hover:text-[var(--side-navigation-font-hover)]
           cursor-pointer ${!open && "rotate-180"}`}
      />
      <div className="flex w-full gap-2 items-center p-5 pb-2 bg-[var(--top-navigation-panel-secondary)]">
        <div className="border-2 border-gray-600 rounded-full p-1">
          {open ? (
            <img
              src={branding?.logoImage ? branding?.logoImage : logo}
              width={35}
              alt="logo"
              className="rounded-full"
            />
          ) : (
            <img
              src={
                branding?.secondaryLogoImage
                  ? branding?.secondaryLogoImage
                  : logo
              }
              width={35}
              alt="logo"
              className="rounded-full"
            />
          )}
        </div>
        {open && (
          <div className="flex flex-col items-start text-[var(--side-navigation-header-font-color)]">
            {tenants
              .filter((item) => item.id === Number(user?.currentTenant))
              .map((item, index) => (
                <Dropdown.Item key={index}>
                  <span className="text-bold">
                    {item.tenantName === "superTenant"
                      ? "Super Tenant"
                      : item.tenantName}
                  </span>{" "}
                </Dropdown.Item>
              ))}
          </div>
        )}
      </div>
      <ul className="pt-2 p-5">
        <li
          className={`text-[var(--side-navigation-font-primary)] font-bold text-xl duration-200 ${
            !open && "hidden"
          }`}
        >
          App Settings
        </li>
        {SettingMenus.map((menu, index) => (
          <li
            key={index}
            className={`text-[var(--side-navigation-font-primary)] text-sm ${
              hasPermission(user, menu.allowedRoles) ? "flex" : "hidden"
            } flex items-center gap-x-4 
              cursor-pointer p-2 hover:text-[var(--side-navigation-panel-secondary)] rounded-md
              ${
                menu.route === window.location.pathname &&
                "text-[var(--side-navigation-panel-secondary)]"
              }
              `}
            onClick={() => navigate(menu.route || "")}
          >
            <span className="text-xl block float-left">
              {menu.icon ? menu.icon : <RiDashboardFill />}
            </span>
            <span
              className={`text-base font-medium flex-1 
                duration-200 ${!open && "hidden"}`}
            >
              {menu.title}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SettingSidebar;
