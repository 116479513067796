export const allIcons = [
  "home",
  "settings",
  "chart line",
  "chart bar",
  "chart pie",
  "chart area",
  "database",
  "line graph",
  "bar graph",
  "pie chart",
  "area chart",
  "line chart",
  "calendar",
  "camera",
  "address book",
  "anchor",
  "archive",
  "asterisk",
  "balance scale",
  "ban",
  "barcode",
  "bed",
  "beer",
  "bicycle",
  "binoculars",
  "birthday cake",
  "bold",
  "bolt",
  "bomb",
  "book",
  "bookmark",
  "briefcase",
  "bug",
  "building",
  "bullhorn",
  "bullseye",
  "bus",
  "calculator",
  "camera retro",
  "car",
  "caret down",
  "caret left",
  "caret right",
  "caret up",
  "cart plus",
  "certificate",
  "check",
  "check circle",
  "check square",
  "child",
  "circle",
  "clipboard",
  "clock",
  "clone",
  "cloud",
  "code",
  "cogs",
  "columns",
  "comment",
  "compass",
  "copy",
  "credit card",
  "crop",
  "crosshairs",
  "cube",
  "cubes",
  "cut",
  "desktop",
  "dollar",
  "download",
  "edit",
  "ellipsis horizontal",
  "ellipsis vertical",
  "envelope",
  "eraser",
  "exchange",
  "exclamation",
  "exclamation circle",
  "exclamation triangle",
  "external",
  "eye",
  "eye slash",
  "fast backward",
  "fast forward",
  "fax",
  "female",
  "fighter jet",
  "file",
  "file archive",
  "file audio",
  "file code",
  "file excel",
  "file image",
  "file pdf",
  "file text",
  "file video",
  "film",
  "filter",
  "fire",
  "flag",
  "flag checkered",
  "flask",
  "folder",
  "folder open",
  "font",
  "football ball",
  "forward",
  "frown",
  "gamepad",
  "gavel",
  "gift",
  "glass martini",
  "globe",
  "graduation cap",
  "hdd",
  "headphones",
  "heart",
  "heartbeat",
  "history",
  "hospital",
  "hourglass",
  "id badge",
  "id card",
  "image",
  "inbox",
  "industry",
  "info",
  "info circle",
  "italic",
  "key",
  "keyboard",
  "laptop",
  "leaf",
  "lemon",
  "level down",
  "level up",
  "life ring",
  "lightbulb",
  "link",
  "list",
  "list alternate",
  "location arrow",
  "lock",
  "magic",
  "magnet",
  "male",
  "map",
  "map marker",
  "map pin",
  "medkit",
  "meh",
  "microphone",
  "mobile",
  "money bill",
  "moon",
  "motorcycle",
  "music",
  "newspaper",
  "object group",
  "paint brush",
  "paper plane",
  "paperclip",
  "paragraph",
  "pause",
  "paw",
  "pencil",
  "percent",
  "phone",
  "phone square",
  "plane",
  "plug",
  "plus",
  "plus square",
  "podcast",
  "pound",
  "power off",
  "print",
  "puzzle piece",
  "qrcode",
  "question",
  "question circle",
  "quote left",
  "quote right",
  "random",
  "recycle",
  "redo",
  "reply",
  "reply all",
  "retweet",
  "road",
  "rocket",
  "rss",
  "save",
  "search",
  "server",
  "share",
  "share square",
  "shield",
  "shopping bag",
  "sign in",
  "sign out",
  "signal",
  "sitemap",
  "smile",
  "snowflake",
  "sort",
  "sort alphabet down",
  "sort alphabet up",
  "sort amount down",
  "sort amount up",
  "sort numeric down",
  "sort numeric up",
  "spinner",
  "square",
  "star",
  "star half",
  "sticky note",
  "stop",
  "stopwatch",
  "street view",
  "subway",
  "suitcase",
  "sun",
  "sync",
  "table",
  "tablet",
  "tachometer",
  "tag",
  "tags",
  "tasks",
  "taxi",
  "terminal",
  "text height",
  "text width",
  "th",
  "th large",
  "th list",
  "thermometer",
  "thumbs down",
  "thumbs up",
  "ticket",
  "times",
  "times circle",
  "tint",
  "toggle off",
  "toggle on",
  "trademark",
  "train",
  "trash",
  "trash alternate",
  "tree",
  "trophy",
  "truck",
  "tv",
  "umbrella",
  "underline",
  "undo",
  "universal access",
  "unlink",
  "unlock",
  "upload",
  "user circle",
  "user plus",
  "user times",
  "users",
  "video",
  "volume down",
  "volume off",
  "volume up",
  "wheelchair",
  "wifi",
  "window close",
  "window maximize",
  "window minimize",
  "window restore",
  "wrench",
  "yen",
];
