import React from "react";
import {
  Placeholder,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";

export const SkeletonTable = () => {
  return (
    <div>
      <Table basic="very">
        <TableHeader>
          <TableRow>
            <TableHeaderCell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </TableHeaderCell>
            <TableHeaderCell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </TableHeaderCell>
            <TableHeaderCell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </TableHeaderCell>
            <TableHeaderCell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {[...Array(5)].map((_, index) => (
            <TableRow key={index}>
              <TableCell>
                <Placeholder>
                  <Placeholder.Line />
                </Placeholder>
              </TableCell>
              <TableCell>
                <Placeholder>
                  <Placeholder.Line />
                </Placeholder>
              </TableCell>
              <TableCell>
                <Placeholder>
                  <Placeholder.Line />
                </Placeholder>
              </TableCell>
              <TableCell>
                <Placeholder>
                  <Placeholder.Line />
                </Placeholder>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
