import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface AuthenticationState {
  tokens: Tokens | null;
  loading: boolean;
  error: string | null;
  isRegistered: boolean | null; // Added isRegistered state
}

const tokensFromLocalStorage = localStorage.getItem("tokens");

const initialState: AuthenticationState = {
  tokens: tokensFromLocalStorage ? JSON.parse(tokensFromLocalStorage) : null,
  loading: false,
  error: null,
  isRegistered: null, // Initialize isRegistered state
};

interface Tokens {
  token: string;
  refreshToken: string;
}

interface LoginData {
  email: string;
  password: string;
}

interface SignupData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  dateOfBirth: string;
}

export const login = createAsyncThunk<Tokens, LoginData>(
  "authentication/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post<Tokens>(
        "https://cedarplatform.io:4400/login",
        data
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || "Login failed");
    }
  }
);

export const isRegistered = createAsyncThunk<{ isExist: boolean }>(
  "authentication/isRegistered",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<{ isExist: boolean }>(
        "https://cedarplatform.io:4400/superuserexist"
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || "Request failed");
    }
  }
);

export const signin = createAsyncThunk<Tokens, { email: string }>(
  "authentication/signin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post<Tokens>(
        "https://cedarplatform.io:4400/signin",
        data
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || "Signin failed");
    }
  }
);

export const signup = createAsyncThunk<Tokens, SignupData>(
  "authentication/signup",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post<Tokens>(
        "https://cedarplatform.io:4400/signup",
        data
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || "Signup failed");
    }
  }
);

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setTokens(state, action: PayloadAction<Tokens>) {
      state.tokens = action.payload;
      localStorage.setItem("tokens", JSON.stringify(action.payload));
    },
    clearTokens(state) {
      state.tokens = null;
      localStorage.removeItem("tokens");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<Tokens>) => {
        state.loading = false;
        state.tokens = action.payload;
        localStorage.setItem("tokens", JSON.stringify(action.payload));
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || "Login failed";
      })
      .addCase(signin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signin.fulfilled, (state, action: PayloadAction<Tokens>) => {
        state.loading = false;
        state.tokens = action.payload;
        localStorage.setItem("tokens", JSON.stringify(action.payload));
      })
      .addCase(signin.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || "Signin failed";
      })
      .addCase(signup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signup.fulfilled, (state, action: PayloadAction<Tokens>) => {
        state.loading = false;
        state.tokens = action.payload;
        localStorage.setItem("tokens", JSON.stringify(action.payload));
      })
      .addCase(signup.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || "Signup failed";
      })
      .addCase(isRegistered.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(isRegistered.fulfilled, (state, action) => {
        state.loading = false;
        state.isRegistered = action.payload.isExist;
      })
      .addCase(isRegistered.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || "Request failed";
      });
  },
});

export const { setTokens, clearTokens } = authenticationSlice.actions;

export default authenticationSlice.reducer;
