import React from "react";
import {
  Container,
  Header,
  Segment,
  List,
  Icon,
  Divider,
  Grid,
  Button,
} from "semantic-ui-react";

const HelpPage = () => {
  return (
    <Container className="mt-5">
      <Header
        as="h1"
        textAlign="center"
        className="text-3xl text-secondary font-bold"
      >
        Cedar Street Help Center
      </Header>
      <Segment className="mt-5">
        <Header as="h2">
          <Icon name="question circle outline" />
          <Header.Content>Getting Started</Header.Content>
        </Header>
        <p>
          Welcome to the Cedar Street! Here are some quick tips to get you
          started:
        </p>
        <List bulleted>
          <List.Item>Creating your first report</List.Item>
          <List.Item>Understanding your dashboard</List.Item>
          <List.Item>Exporting your reports</List.Item>
        </List>
      </Segment>

      <Segment className="mt-5">
        <Header as="h2">
          <Icon name="info circle" />
          <Header.Content>FAQs</Header.Content>
        </Header>
        <List divided relaxed>
          <List.Item>
            <Icon name="question circle" size="large" verticalAlign="middle" />
            <List.Content>
              <List.Header>How do I create a report?</List.Header>
              <List.Description>
                To create a report, go to settings - manage navigation - add
                new. Follow the steps in the wizard to complete your report.
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </Segment>
      <Segment className="mt-5">
        <Header as="h2">
          <Icon name="phone" />
          <Header.Content>Contact Support</Header.Content>
        </Header>
        <p>If you need further assistance, please contact our support team:</p>
        <Grid columns={2} relaxed="very">
          <Grid.Column>
            <Header as="h3">
              <Icon name="envelope" />
              <Header.Content>Email</Header.Content>
            </Header>
            <p>data@cedarstreet.io</p>
          </Grid.Column>
          <Grid.Column>
            <Header as="h3">
              <Icon name="phone" />
              <Header.Content>Phone</Header.Content>
            </Header>
            <p>+2519 3356 6565</p>
          </Grid.Column>
        </Grid>
        <Divider vertical>Or</Divider>
      </Segment>
      {/* <Segment className="mt-5">
        <Header as="h2">
          <Icon name="chat" />
          <Header.Content>Live Chat</Header.Content>
        </Header>
        <p>
          Our support team is available 24/7 via live chat. Click the button
          below to start a chat session:
        </p>
        <Button primary disabled>
          <Icon name="chat" />
          Start Live Chat
        </Button>
        <span className="font-semibold text-xl text-secondary">
          Something exiting coming soon!
        </span>
      </Segment> */}
    </Container>
  );
};

export default HelpPage;
