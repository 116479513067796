import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { isRegistered, login } from "../../store/slices/authenticationSlice";
import { AppDispatch, RootState } from "../../store/store";
import toast from "react-hot-toast";
import GoogleLoginButton from "./GoogleLoginButton";
import GoogleOAuthConfig from "./GoogleOAuthConfig";
import MicrosoftOAuthConfig from "./MicrosoftOAuthConfig";
import MicrosoftLoginButton from "./MicrosoftLoginButton";
import { useSelector } from "react-redux";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { isRegistered: registered, loading: isLoading } = useSelector(
    (state: RootState) => state.authentication
  );

  useEffect(() => {
    dispatch(isRegistered());
  }, []);

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    if (!email || !password) {
      setError("Email and password are required.");
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    try {
      dispatch(login({ email, password }))
        .then((res) => {
          if (res.type === "authentication/login/fulfilled") {
            toast.success("Login Success");
            navigate("/reports");
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          setError(error.message);
        });
    } catch (err: any) {
      console.error("Login failed:", err);
      setError(err.message || "An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full flex-1 px-4 md:px-20 text-center">
      <div className="bg-white rounded-2xl border shadow flex flex-col md:flex-row w-full md:w-2/3 max-w-4xl">
        <div className="w-full md:w-3/5 p-5">
          <div className="text-left font-bold">
            <span className="text-orange-500">CEDAR</span>
            <span>STREET</span>
          </div>
          <div className="py-10">
            <h2 className="text-3xl font-bold text-primary mb-2">
              Sign in to Account
            </h2>
            <div className="border-2 w-10 border-primary inline-block mb-2"></div>
            <div className="flex justify-center my-2">
              <MicrosoftOAuthConfig>
                <MicrosoftLoginButton
                  setError={setError}
                  setLoading={setLoading}
                />
              </MicrosoftOAuthConfig>
              <GoogleOAuthConfig>
                <GoogleLoginButton
                  setError={setError}
                  setLoading={setLoading}
                />
              </GoogleOAuthConfig>
            </div>
            <p className="text-gray-300 my-3">or use your email and password</p>
            {error && <p className="text-red-500 mt-3">{error}</p>}
            <form
              onSubmit={handleSubmit}
              className="flex flex-col items-center space-y-3"
            >
              <div className="w-72">
                <Input
                  fluid
                  icon="mail"
                  iconPosition="left"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                />
              </div>
              <div className="w-72">
                <Input
                  fluid
                  icon="key"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                />
              </div>
              <div className="flex justify-end text-secondary w-72 mb-8">
                <label
                  htmlFor="remember"
                  className="cursor-pointer"
                  onClick={() => navigate("/forgot")}
                >
                  Forgot Password
                </label>
              </div>
              <button
                type="submit"
                className={`border-2 border-primary text-primary rounded-full px-12 py-2 inline-block font-semibold ${
                  loading ? "bg-gray-200" : "hover:bg-primary hover:text-white"
                }`}
                disabled={loading}
              >
                {loading ? "Signing In..." : "Sign In"}
              </button>
            </form>
          </div>
        </div>
        <div className="w-full md:w-2/5 bg-primary text-white rounded-bl-2xl md:rounded-tr-2xl md:rounded-br-2xl py-10 md:py-36 px-6 md:px-12">
          <h2 className="text-3xl font-bold mb-2">Hello, Friend!</h2>
          <div className="border-2 w-10 border-white inline-block mb-2"></div>
          <p className="mb-10">
            Fill up personal information and start journey with us.
          </p>
          {registered || isLoading ? (
            ""
          ) : (
            <Link
              to="/signup"
              className="border-2 border-white rounded-full px-12 py-2 inline-block font-semibold hover:bg-secondary hover:text-primary"
            >
              Sign Up
            </Link>
          )}
        </div>
      </div>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-64 w-64"></div>
        </div>
      )}
    </div>
  );
};

export default Signin;
