import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../store/store";
import { isRegistered, signup } from "../../store/slices/authenticationSlice";
import { Input } from "semantic-ui-react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const Signup = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { isRegistered: registered } = useSelector(
    (state: RootState) => state.authentication
  );

  useEffect(() => {
    dispatch(isRegistered());
  }, [dispatch]);
  useEffect(() => {
    if (registered) {
      navigate("/");
    }
  }, [registered]);

  const validateForm = () => {
    if (!firstName || !lastName || !email || !password || !dateOfBirth) {
      setError("All fields are required");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email address");
      return false;
    }
    if (password.length < 6) {
      setError("Password must be at least 6 characters long");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError(null);

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      const res = await dispatch(
        signup({
          firstName,
          lastName,
          email,
          password,
          dateOfBirth,
        })
      );
      if (res.type === "authentication/signup/fulfilled") {
        toast.success("Registered Successfully");
        navigate("/");
        localStorage.setItem("registered", "1");
      } else {
        throw new Error(res.payload as string);
      }
    } catch (err: any) {
      console.error("Signup failed:", err);
      setError(err.message || "An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full flex-1 px-4 md:px-20 text-center">
      <div className="bg-white rounded-2xl border shadow flex flex-col md:flex-row w-full md:w-2/3 max-w-4xl">
        <div className="w-full md:w-3/5 p-5">
          <div className="text-left font-bold w-full">
            <span className="text-orange-500">CEDAR</span>
            <span>STREET</span>
          </div>
          <div className="py-1">
            <h2 className="text-3xl font-bold text-primary mb-2">
              Create an Account
            </h2>
            <p className="text-gray-300 my-3">Enter your email to register</p>
            {error && <p className="text-red-500 mt-3">{error}</p>}
            <form
              onSubmit={handleSubmit}
              className="flex flex-col items-center space-y-3"
            >
              <div className="w-72">
                <Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="w-72">
                <Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="w-72">
                <Input
                  fluid
                  icon="mail"
                  iconPosition="left"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="w-72">
                <Input
                  fluid
                  icon="key"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="w-72">
                <Input
                  fluid
                  label="Birth Date"
                  type="date"
                  iconPosition="left"
                  placeholder="Date of Birth (YYYY-MM-DD)"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="border-2 border-primary text-primary rounded-full px-12 py-2 inline-block
                  font-semibold hover:bg-primary hover:text-white"
                disabled={loading}
              >
                {loading ? "Signing Up..." : "Sign Up"}
              </button>
            </form>
          </div>
        </div>
        <div className="w-full md:w-2/5 bg-primary text-white rounded-bl-2xl md:rounded-tr-2xl md:rounded-br-2xl py-10 md:py-36 px-6 md:px-12">
          <h2 className="text-3xl font-bold mb-2">Welcome Back!</h2>
          <div className="border-2 w-10 border-white inline-block mb-2"></div>
          <p className="mb-10">
            To keep connected with us please login with your personal info.
          </p>
          <Link
            to="/"
            className="border-2 border-white rounded-full px-12 py-2 inline-block
              font-semibold hover:bg-secondary hover:text-primary"
          >
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
