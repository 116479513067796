import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

export interface LayoutState {
  id: number;
  layout: string;
  TenantId: number;
}

interface LayoutSliceState {
  layouts: LayoutState[];
  loading: boolean;
  error: string | null;
}

const initialState: LayoutSliceState = {
  layouts: [],
  loading: false,
  error: null,
};

const getHeaders = (getState: () => RootState) => {
  const token = getState().authentication.tokens?.token;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const fetchLayouts = createAsyncThunk<
  LayoutState[],
  void,
  { state: RootState }
>("layouts/fetchLayouts", async (_, { getState, rejectWithValue }) => {
  try {
    const response = await axios.get<LayoutState[]>(
      "https://cedarplatform.io:4400/api/v1/layouts",
      getHeaders(getState)
    );
    return response.data;
  } catch (error) {
    return rejectWithValue("Failed to fetch layouts data");
  }
});

export const updateLayout = createAsyncThunk<
  LayoutState,
  { layout: string },
  { state: RootState }
>(
  "layouts/updateLayout",
  async (updatedLayout, { getState, rejectWithValue }) => {
    try {
      const response = await axios.put<{ themalayout: LayoutState }>(
        "https://cedarplatform.io:4400/api/v1/layouts",
        updatedLayout,
        getHeaders(getState)
      );
      return response.data.themalayout;
    } catch (error) {
      return rejectWithValue("Failed to update layout data");
    }
  }
);

const layoutSlice = createSlice({
  name: "layouts",
  initialState,
  reducers: {
    setLayouts: (state, action: PayloadAction<LayoutState[]>) => {
      state.layouts = action.payload;
    },
    clearLayouts: (state) => {
      state.layouts = [];
    },
    updateLayoutLocally: (
      state,
      action: PayloadAction<{ id: number; layout: string }>
    ) => {
      const index = state.layouts.findIndex(
        (layout) => layout.id === action.payload.id
      );
      if (index !== -1) {
        state.layouts[index].layout = action.payload.layout;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLayouts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLayouts.fulfilled, (state, action) => {
        state.loading = false;
        state.layouts = action.payload;
      })
      .addCase(fetchLayouts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateLayout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateLayout.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.layouts.findIndex(
          (layout) => layout.id === action.payload.id
        );
        if (index !== -1) {
          state.layouts[index] = action.payload;
        } else {
          state.layouts.push(action.payload);
        }
      })
      .addCase(updateLayout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setLayouts, clearLayouts, updateLayoutLocally } =
  layoutSlice.actions;
export default layoutSlice.reducer;
