import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Form,
  FormField,
  FormTextArea,
  Icon,
  Confirm,
} from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { IoMdAdd } from "react-icons/io";
import DefaultModal from "../../../constants/Modal";
import { RootState, AppDispatch } from "../../../../store/store";
import {
  fetchRoles,
  addRole,
  deleteRole,
  editRole, // Import the editRole action creator
} from "../../../../store/slices/roleSlice";
import { SkeletonCard } from "../../../constants/SkeletonCard";
import toast from "react-hot-toast";

const Role = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { roles, loading, error, addLoading, editLoading, deleteLoading } =
    useSelector((state: RootState) => state.roles);

  const [state, setState] = useState<{
    open: boolean;
    size: "small" | "tiny" | "mini" | "large" | "fullscreen" | undefined;
    roleName?: string;
    description?: string;
    confirmOpen: boolean;
    deleteRoleId: number | null;
    editRoleId: number | null; // New state to store the ID of the role being edited
  }>({
    open: false,
    size: undefined,
    roleName: "",
    description: "",
    confirmOpen: false,
    deleteRoleId: null,
    editRoleId: null, // Initialize editRoleId state
  });

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  const handleAddRole = async () => {
    const { roleName, description } = state;

    if (!roleName || !description) {
      toast.error("Both role name and description are required");
      return;
    }

    const newRoleData = {
      name: roleName,
      description: description,
    };

    try {
      if (typeof state.editRoleId === "number") {
        const result = await dispatch(
          editRole({ roleId: state.editRoleId, roleData: newRoleData })
        );
        if (result.type === "roles/editRole/fulfilled") {
          toast.success("Role edited successfully");
        } else {
          throw new Error(result.payload as string);
        }
      } else {
        const result = await dispatch(addRole(newRoleData));
        if (result.type === "roles/addRole/fulfilled") {
          toast.success("Role added successfully");
        } else {
          throw new Error(result.payload as string);
        }
      }
      // Clear form fields and close modal after submission
      setState((prevState) => ({
        ...prevState,
        roleName: "",
        description: "",
        open: false,
        size: undefined,
        editRoleId: null,
      }));
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleEditRole = (roleId: number) => {
    const roleToEdit = roles.find((role) => role.id === roleId);
    if (roleToEdit) {
      setState({
        ...state,
        open: true,
        size: "tiny",
        roleName: roleToEdit.name,
        description: roleToEdit.description,
        editRoleId: roleId, // Set the ID of the role being edited
      });
    }
  };

  const handleDeleteRole = (roleId: number) => {
    setState({ ...state, confirmOpen: true, deleteRoleId: roleId });
  };

  const handleCancelDelete = () => {
    setState({ ...state, confirmOpen: false, deleteRoleId: null });
  };

  const handleConfirmDelete = async () => {
    if (state.deleteRoleId !== null) {
      try {
        const result = await dispatch(deleteRole(state.deleteRoleId));
        if (result.type === "roles/deleteRole/fulfilled") {
          toast.success("Role deleted successfully");
          setState({ ...state, confirmOpen: false, deleteRoleId: null });
        } else {
          throw new Error(result.payload as string);
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  const roleCards = roles.map((role) => (
    <Card key={role.id} className="">
      <Card.Content>
        <Card.Header>
          <div className="flex flex-col">
            <div className="mb-2">
              <div className="text-lg text-black font-bold">{role.name}</div>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span>Total {role.Permissions?.length} Users</span>
              <Button
                basic
                size="mini"
                color="red"
                onClick={() => handleDeleteRole(role.id)}
              >
                <Icon name="trash" />
              </Button>
            </div>
            <div>
              <Button basic onClick={() => handleEditRole(role.id)}>
                Edit Role
              </Button>
            </div>
          </div>
        </Card.Header>
      </Card.Content>
    </Card>
  ));

  return (
    <div>
      <DefaultModal
        title={state.editRoleId !== null ? "Edit Role" : "Create Role"}
        buttonTitle={state.editRoleId !== null ? "Update" : "Save"}
        open={state.open}
        size={state.size}
        dimmer="default"
        onClose={() =>
          setState((prevState) => ({
            ...prevState,
            open: false,
            size: undefined,
            editRoleId: null,
            roleName: "",
            description: "",
          }))
        }
        onSave={handleAddRole}
        loading={state.editRoleId !== null ? editLoading : addLoading}
      >
        <Form>
          <FormField>
            <label>Role Name</label>
            <input
              placeholder="Role Name"
              value={state.roleName}
              onChange={(e) => setState({ ...state, roleName: e.target.value })}
            />
          </FormField>
          <FormField>
            <FormTextArea
              name="description"
              label="Description"
              placeholder="Write some description about this role..."
              value={state.description}
              onChange={(e) =>
                setState({ ...state, description: e.target.value })
              }
            />
          </FormField>
        </Form>
      </DefaultModal>

      <Confirm
        open={state.confirmOpen}
        size="tiny"
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        content="Are you sure you want to delete this role?"
        confirmButton={{ content: "Delete", loading: deleteLoading }}
      />

      <div className="m-3">
        <div className="bg-white border shadow-sm rounded-lg p-6">
          {loading ? (
            <SkeletonCard />
          ) : (
            <div className="grid grid-cols-4 gap-4">
              {roleCards}
              {/* <Card className="mb-4">
                <Card.Content>
                  <Card.Header>
                    <Card.Header> New Role </Card.Header>
                    <Card.Meta> Create a new role. </Card.Meta>
                  </Card.Header>
                </Card.Content>
                <Card.Content extra>
                  <Button
                    primary
                    fluid
                    size="tiny"
                    onClick={() =>
                      setState({
                        open: true,
                        size: "tiny",
                        confirmOpen: false,
                        deleteRoleId: null,
                        editRoleId: null,
                        roleName: "",
                        description: "",
                      })
                    }
                  >
                    <div className="flex items-center justify-center space-x-2">
                      <IoMdAdd />
                      <span>Create Role</span>
                    </div>
                  </Button>
                </Card.Content>
              </Card> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Role;
