import React, { useEffect, useState, SyntheticEvent, useRef } from "react";
import { IoMdHelp, IoMdNotificationsOutline } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Dropdown,
  Menu,
  Icon,
  Image,
  DropdownMenu,
  DropdownItem,
  Search,
  SearchProps,
  SearchResultData,
} from "semantic-ui-react";
import { AppDispatch, RootState } from "../../store/store";
import { fetchTenants, switchTenant } from "../../store/slices/tenantSlice";
import toast from "react-hot-toast";
import { BiSearch } from "react-icons/bi";
import { fetchUser } from "../../store/slices/userSlice";
import { BsThreeDotsVertical } from "react-icons/bs";
import PowerBiService, {
  PowerBiExportRequest,
} from "../../services/PowerBiService";
import { hasPermission } from "../../utils/roleUtils";
import CircularProgress from "../settings/components/CircularProgress";
import _ from "lodash";
import {
  clearContentPane,
  ContentPage,
} from "../../store/slices/contentPageSlice";
import { reset } from "../../store/slices/resetSlice";
import { clearTokens } from "../../store/slices/authenticationSlice";
import { clearBranding } from "../../store/slices/brandingSlice";
import { clearColors } from "../../store/slices/colorSlice";
import { clearEmbeddedReport } from "../../store/slices/embedTokenSlice";
import { clearLayouts } from "../../store/slices/layoutSlice";
import FullPageLoader from "./FullPageLoader";

interface SearchResult {
  title: string;
  description: string;
  id: number;
}

const Navbar: React.FC = () => {
  const { tenants } = useSelector((state: RootState) => state.tenants);
  const { user } = useSelector((state: RootState) => state.users);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { contentPages, contentPage } = useSelector(
    (state: RootState) => state.contentPages
  );
  const { embeddedReport } = useSelector(
    (state: RootState) => state.embedToken
  );
  const [loading, setLoading] = useState<boolean>(false);

  const [exportProgress, setExportProgress] = useState<number>(0);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { layouts, loading: layoutLoading } = useSelector(
    (state: RootState) => state.layouts
  );
  const { loading: colorLoading } = useSelector(
    (state: RootState) => state.colors
  );

  const searchRef = useRef<HTMLDivElement>(null);

  const customStyle: React.CSSProperties = {
    right: 0,
    left: "auto",
  };

  useEffect(() => {
    dispatch(fetchTenants());
    dispatch(fetchUser());
  }, [dispatch]);

  const handleSwitchTenant = (tenantId: string) => {
    setLoading(true);
    dispatch(switchTenant({ tenantId })).then(() => {
      toast.success("Tenant Switched");
      window.location.reload();
    });
  };

  const signout = () => {
    dispatch(clearTokens());
    dispatch(clearBranding());
    dispatch(clearColors());
    dispatch(clearEmbeddedReport());
    dispatch(clearLayouts());
    dispatch(clearContentPane());
    dispatch(reset());
    navigate("/");
  };

  const tenantOptions = tenants
    ?.filter((item) => item.isActive)
    .map((item) => ({
      key: item.id,
      text:
        item.tenantName === "superTenant" ? "Super Tenant" : item.tenantName,
      value: item.id,
    }));

  const handleExport = async (type: string) => {
    if (!embeddedReport) {
      console.error("Report is not loaded");
      return;
    }

    setIsExporting(true);
    setExportProgress(0);

    const exportStatusCallback = (statusMessage: string) => {
      if (statusMessage.includes("Progress")) {
        const progress = parseInt(statusMessage.replace("Progress: ", ""), 10);
        setExportProgress(progress);
      }
    };

    const incrementProgressRandomly = () => {
      setExportProgress((prev) => {
        const newProgress = prev + Math.floor(Math.random() * 10) + 1;
        return newProgress > 100 ? 100 : newProgress;
      });
    };

    const progressInterval = setInterval(incrementProgressRandomly, 1400);

    try {
      const exportRequest: PowerBiExportRequest = {
        format: type,
        exportStatusCallback,
      };

      await PowerBiService.ExportReport(
        contentPage?.PowerBiWorkspace || "",
        contentPage?.PagePath || "",
        exportRequest,
        dispatch
      );

      clearInterval(progressInterval);
      setExportProgress(100);
      setIsExporting(false);
      toast.success("Export completed successfully");
    } catch (error) {
      console.error("Error exporting report:", error);
      clearInterval(progressInterval);
      setIsExporting(false);
      toast.error("Export failed");
    }
  };

  const handleSearchChange = (e: SyntheticEvent, { value }: SearchProps) => {
    setSearchValue(value || "");
    setIsLoading(true);

    setTimeout(() => {
      if (!value || value.length < 1) {
        setIsLoading(false);
        setSearchResults([]);
        return;
      }

      const re = new RegExp(_.escapeRegExp(value), "i");
      const isMatch = (result: ContentPage) => re.test(result.Title);

      setSearchResults(
        _.filter(
          contentPages.filter((item) => item.type !== "category"),
          isMatch
        ).map((item) => ({
          title: item.Title,
          description: item.Description || "",
          id: item.id,
        }))
      );
      setIsLoading(false);
    }, 300);
  };

  const handleResultSelect = (
    e: SyntheticEvent,
    { result }: SearchResultData
  ) => {
    navigate(`/reports/contentPage/${result.id}`);
    setSearchValue("");
    setSearchOpen(false);
  };

  const handleSearchFocus = () => {
    setSearchOpen(true);
  };

  const handleSearchBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!searchRef.current?.contains(e.relatedTarget as Node)) {
      setSearchOpen(false);
    }
  };

  const getPSTGreeting = (): string => {
    const now = new Date();
    const pstOffset = -8; // PST offset from UTC
    const utc = now.getTime() + now.getTimezoneOffset() * 60000; // 60000 instead of 6000
    const pst = new Date(utc + pstOffset * 3600 * 1000);
    const hours = pst.getHours();

    if (hours < 12) {
      return "Good Morning";
    } else if (hours < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  const greeting = getPSTGreeting();

  return (
    <>
      {(loading || layoutLoading || colorLoading) && <FullPageLoader />}
      <div
        className={`h-16 ${
          layouts[0]?.layout === "Classic" ? "hidden" : "flex"
        } flex w-full`}
      >
        <Menu
          borderless
          className="w-full"
          style={{ backgroundColor: "var(--top-navigation-panel-primary)" }}
        >
          <Menu.Item>
            <h3 className="ml-6 text-[var(--top-navigation-font)]">
              {greeting}, {user?.firstName}
            </h3>
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item className="space-x-2">
              {tenantOptions.length > 0 && (
                <Dropdown
                  className="mx-2 border border-[var(--navigation-border-color)]"
                  placeholder="Switch Tenant"
                  openOnFocus
                  selection
                  value={Number(user?.currentTenant)}
                  options={tenantOptions}
                  onChange={(e, { value }) =>
                    value?.toString && handleSwitchTenant(value?.toString())
                  }
                />
              )}
              <Dropdown
                icon={null}
                trigger={
                  <button
                    type="button"
                    className="p-2 rounded-full text-[var(--top-navigation-font)] bg-[var(--side-navigation-panel-item-highlight)] hover:bg-[var(--side-navigation-font-hover)]"
                    onClick={() => setSearchOpen(!searchOpen)}
                  >
                    <BiSearch size={20} />
                  </button>
                }
                open={searchOpen}
                onOpen={() => setSearchOpen(true)}
              >
                <Dropdown.Menu style={customStyle} onBlur={handleSearchBlur}>
                  <Dropdown.Item>
                    <div ref={searchRef}>
                      <div className="mb-4 flex items-center justify-end">
                        <span
                          onClick={() => setSearchOpen(false)}
                          className="cursor-pointer hover:text-secondary"
                        >
                          <Icon name="x" />
                        </span>
                      </div>
                      <Search
                        fluid
                        placeholder="Search Reports ..."
                        loading={isLoading}
                        onResultSelect={handleResultSelect}
                        onSearchChange={_.debounce(handleSearchChange, 500, {
                          leading: true,
                        })}
                        results={searchResults}
                        value={searchValue}
                        onFocus={handleSearchFocus}
                      />
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <button
              type="button"
              onClick={() => navigate("/settings/help")}
              className="hidden sm:inline-flex items-center justify-center p-2 bg-[var(--side-navigation-panel-item-highlight)] text-[var(--top-navigation-font)] hover:bg-[var(--side-navigation-font-hover)] font-medium rounded-full"
            >
              <IoMdHelp size={20} />
            </button> */}
              {/* <button
              type="button"
              className="p-2 rounded-full text-[var(--top-navigation-font)] bg-[var(--side-navigation-panel-item-highlight)] hover:bg-[var(--side-navigation-font-hover)]"
            >
              <IoMdNotificationsOutline size={20} />
            </button> */}
              {isExporting && <CircularProgress progress={exportProgress} />}
              {embeddedReport &&
                user &&
                hasPermission(user, ["Admin", "Power", "Read/Write"]) && (
                  <Dropdown
                    additionPosition="bottom"
                    trigger={<BsThreeDotsVertical />}
                    icon={null}
                  >
                    <DropdownMenu className="mt-5" style={customStyle}>
                      <DropdownItem
                        icon="expand"
                        onClick={() => embeddedReport.fullscreen()}
                        text="Full Screen"
                      />
                      {contentPage?.ShowExportButton && (
                        <Dropdown icon={false} item trigger={trigger}>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleExport("PDF")}>
                              <Icon name="file pdf" />
                              PDF
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleExport("PPTX")}>
                              <Icon name="file excel" />
                              PPTX
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                )}
              <Dropdown
                item
                trigger={
                  <UserTrigger
                    imageUrl={
                      user?.imageUrl ||
                      "https://cdn4.vectorstock.com/i/1000x1000/92/73/user-profile-group-outline-icon-symbol-one-of-set-vector-8349273.jpg"
                    }
                  />
                }
                className="w-20"
                icon={false}
              >
                <Dropdown.Menu>
                  <Dropdown.Header>
                    <span className="inline-block text-base">
                      <strong className="capitalize">
                        {user?.firstName + " " + user?.lastName}
                      </strong>
                    </span>
                    <br />
                    <span className="lowercase text-base">{user?.email}</span>
                  </Dropdown.Header>
                  <Dropdown.Divider />

                  <Dropdown.Item as={Link} to="/settings/profile">
                    <Icon name="user" />
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/settings">
                    <Icon name="setting" />
                    Settings
                  </Dropdown.Item>
                  {/* 
                <Dropdown.Item as={Link} to="/settings/help">
                  <Icon name="help" />
                  Help
                </Dropdown.Item> */}
                  <Dropdown.Divider />
                  {tenants
                    .filter((item) => item.id === Number(user?.currentTenant))
                    .map((item, index) => (
                      <Dropdown.Item key={index}>
                        <span className="text-bold">
                          {item.tenantName === "superTenant"
                            ? "Super Tenant"
                            : item.tenantName}
                        </span>{" "}
                        <span className="text-secondary"> (Active)</span>
                      </Dropdown.Item>
                    ))}
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => signout()}>
                    <Icon name="sign out" />
                    Sign out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    </>
  );
};

interface UserTriggerProps {
  imageUrl: string;
}

const UserTrigger: React.FC<UserTriggerProps> = ({ imageUrl }) => (
  <span>
    <Image
      avatar
      className="avator object-cover w-full h-full"
      src={imageUrl}
      alt="User Avatar"
    />
  </span>
);

const trigger = (
  <span
    style={{
      display: "flex",
      alignItems: "center",
      width: "100px",
      justifyContent: "start",
    }}
  >
    <Icon name="download" />
    <span>Export</span>
  </span>
);

export default Navbar;
