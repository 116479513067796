import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { FaGoogle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { signin } from "../../store/slices/authenticationSlice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

interface GoogleLoginButtonProps {
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  setError,
  setLoading,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userInfoResponse = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );

        if (!userInfoResponse.ok) {
          throw new Error(`HTTP error! Status: ${userInfoResponse.status}`);
        }

        const userInfo = await userInfoResponse.json();
        console.log("User information:", userInfo);

        const email = userInfo.email;

        try {
          dispatch(signin({ email }))
            .then((res) => {
              if (res.type === "authentication/signin/fulfilled") {
                toast.success("Login Success");
                navigate("/reports");
              } else {
                throw new Error(res.payload as string);
              }
            })
            .catch((error) => {
              console.log(error);
              setError(error.message);
            });
        } catch (err: any) {
          console.error("Login failed:", err);
          setError(err.message || "An error occurred. Please try again.");
        } finally {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setError("An error occurred during authentication. Please try again.");
      }
    },
    onError: (error) => {
      console.error("Login failed:", error);
      if ((error as any).error === "popup_closed_by_user") {
        setError(
          "Login failed: The user closed the popup without completing the sign-in process."
        );
      } else {
        setError("An error occurred during authentication. Please try again.");
      }
    },
  });

  return (
    <div>
      <button onClick={() => login()}>
        {" "}
        <div className="border-2 text-primary border-gray-200 rounded-full p-3 mx-1">
          <FaGoogle className="text-sm" />
        </div>
      </button>
    </div>
  );
};

export default GoogleLoginButton;
