import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import CustomLoader from "../constants/CustomLoader";

const FullPageLoader: React.FC = () => {
  return (
    <Dimmer active page style={{ backgroundColor: "white", opacity: 1 }}>
      <div className="flex items-center justify-center h-screen">
        <CustomLoader height={70} />
      </div>
    </Dimmer>
  );
};

export default FullPageLoader;
