import { MdAccountTree, MdApps, MdGroups } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { IoIosArrowRoundBack, IoIosColorPalette } from "react-icons/io";
import { SiPowerbi } from "react-icons/si";

const SettingMenus = [
  {
    title: "Manage Navigation",
    route: "/settings",
    icon: <MdAccountTree />,
    allowedRoles: ["Admin"],
  },
  {
    title: "User Management",
    route: "/settings/groups",
    icon: <MdGroups />,
    allowedRoles: ["Admin"],
  },
  {
    title: "Edit Theme",
    route: "/settings/theme",
    icon: <IoIosColorPalette />,
    allowedRoles: ["Admin"],
  },
  {
    title: "Power BI Settings",
    route: "/settings/powerbi",
    icon: <SiPowerbi />,
    allowedRoles: ["Admin"],
  },
  {
    title: "App Settings",
    route: "/settings/apps",
    icon: <MdApps />,
    allowedRoles: ["Admin"],
  },
  {
    title: "Profile",
    route: "/settings/profile",
    icon: <BsPerson />,
    allowedRoles: ["Admin", "Read Only"],
  },
  // {
  //   title: "Help",
  //   route: "/settings/help",
  //   icon: <MdHelp />,
  //   allowedRoles: ["Admin", "Power", "Read/Write", "Read Only"],
  // },
  {
    title: "Exit Settings",
    route: "/reports",
    icon: <IoIosArrowRoundBack />,
    allowedRoles: ["Admin", "Power", "Read/Write", "Read Only"],
  },
];

export default SettingMenus;
