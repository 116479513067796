import React, { FC, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Segment,
  Message,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkspaces } from "../../../../store/slices/workspaceSlice";
import { RootState, AppDispatch } from "../../../../store/store";
import {
  ContentPage,
  addContentPage,
  updateContentPage,
} from "../../../../store/slices/contentPageSlice";
import { allIcons } from "../../../constants/icons";
import { fetchWorkspaceContents } from "../../../../store/slices/workspaceContentSlice";
import { fetchTeams } from "../../../../store/slices/teamSlice";
import toast from "react-hot-toast";

type AddContentPageProps = {
  parentItem: string;
  parentID: string;
  contentPage?: ContentPage;
  isNew: boolean;
};

const AddContentPage: FC<AddContentPageProps> = ({
  parentItem,
  parentID,
  isNew,
  contentPage,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { workspaces } = useSelector((state: RootState) => state.workspaces);
  const { teams } = useSelector((state: RootState) => state.teams);
  const { workspaceContents } = useSelector(
    (state: RootState) => state.workspaceContents
  );

  const { addLoading, loading } = useSelector(
    (state: RootState) => state.contentPages
  );

  const [selectedIcon, setSelectedIcon] = useState<string | undefined>(
    undefined
  );
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState<string | undefined>("");
  const [dynamicBinding, setDynamicBinding] = useState<boolean | undefined>(
    false
  );
  const [workspace, setWorkspace] = useState<string | undefined>("");
  const [content, setContent] = useState<string | undefined>("");
  const [reportPages, setReportPages] = useState<string[] | undefined>([]);
  const [permissions, setPermissions] = useState<any[] | undefined>([]);
  const [filterPane, setFilterPane] = useState<boolean | undefined>(true);
  const [contentPane, setContentPane] = useState<boolean | undefined>(true);
  const [titleDesc, setTitleDesc] = useState<boolean | undefined>(true);
  const [reportSharing, setReportSharing] = useState<boolean | undefined>(true);
  const [reportExport, setReportExport] = useState<boolean | undefined>(true);

  const [workspaceContentOptions, setWorkspaceContentOptions] = useState<any[]>(
    []
  );

  // Validation state
  const [titleError, setTitleError] = useState<string | null>(null);
  const [iconError, setIconError] = useState<string | null>(null);
  const [workspaceError, setWorkspaceError] = useState<string | null>(null);
  const [contentError, setContentError] = useState<string | null>(null);
  const [reportPageError, setReportPageError] = useState<string | null>(null);

  useEffect(() => {
    dispatch(fetchWorkspaces());
  }, []);

  const resetForm = () => {
    setTitle("");
    setIcon("");
    setDescription("");
    setDynamicBinding(false);
    setWorkspace("");
    setContent("");
    setReportPages([]);
    setPermissions([]);
    setFilterPane(true);
    setContentPane(true);
    setTitleDesc(true);
    setReportSharing(true);
    setReportExport(true);
  };

  useEffect(() => {
    if (!isNew && contentPage) {
      setTitle(contentPage.Title);
      setIcon(contentPage.Icon);
      setDescription(contentPage?.Description);
      setDynamicBinding(contentPage.DisplayUseDynamicBinding);
      setWorkspace(contentPage.PowerBiWorkspace);
      setContent(contentPage.PagePath);
      setReportPages(contentPage.ReportPages?.split(";"));
      setPermissions(contentPage.Teams?.map((perm) => perm.id));
      setFilterPane(contentPage.ShowFilter);
      setContentPane(contentPage.ShowContentPane);
      setTitleDesc(!contentPage.HideTitleAnddescription);
      setReportSharing(contentPage.ShowSharingButton);
      setReportExport(contentPage.ShowExportButton);
    } else {
      resetForm();
    }
  }, [isNew, contentPage]);

  useEffect(() => {
    if (workspace) {
      dispatch(fetchWorkspaceContents({ groupId: workspace }));
    }
  }, [workspace]);

  useEffect(() => {
    dispatch(fetchTeams());
  }, []);

  const handleIconClick = (icon: string) => {
    setSelectedIcon(icon);
    setIcon(icon);
  };

  const workspaceOptions = workspaces.map((workspace) => ({
    key: workspace.id,
    text: workspace.name,
    value: workspace.id,
  }));

  const roleOptions = teams.map((role) => ({
    key: role.id,
    text: role.teamName,
    value: role.id,
  }));

  const contentPageOptions = [
    {
      key: "all",
      text: "All",
      value: "all",
    },
  ];

  useEffect(() => {
    if (workspaceContents) {
      setWorkspaceContentOptions(
        workspaceContents.map((content) => ({
          key: content.id,
          text: content.name,
          value: content.id,
        }))
      );
    }
  }, [workspaceContents]);

  const handleSubmit = () => {
    let hasError = false;

    if (!title) {
      setTitleError("Title is required.");
      hasError = true;
    } else {
      setTitleError(null);
    }

    if (!icon) {
      setIconError("Icon is required.");
      hasError = true;
    } else {
      setIconError(null);
    }

    if (!workspace) {
      setWorkspaceError("Workspace is required.");
      hasError = true;
    } else {
      setWorkspaceError(null);
    }

    if (!content) {
      setContentError("Content is required.");
      hasError = true;
    } else {
      setContentError(null);
    }

    if (!reportPages?.length) {
      setReportPageError("Report is required.");
      hasError = true;
    } else {
      setReportPageError(null);
    }

    if (hasError) return;

    const contentDetail = workspaceContents.find((item) => item.id === content);

    const contentPageData = {
      Title: title,
      Icon: icon,
      Description: description,
      DisplayUseDynamicBinding: dynamicBinding,
      PowerBiWorkspace: workspace,
      PagePath: content,
      ReportPages: reportPages?.join(";"),
      NavSecurity: permissions?.map((perm) => ({
        GroupId: parseInt(perm),
        CanEdit: false,
        RolesValidation: "some team",
        Id: Math.random(),
      })),
      ShowFilter: filterPane,
      ShowContentPane: contentPane,
      HideTitleAnddescription: !titleDesc,
      ShowSharingButton: reportSharing,
      ShowExportButton: reportExport,
      Parent: isNew ? parentID : contentPage?.Parent,
      CreatedBy: "Yared@cedarstreet.io",
      CreatedOn: new Date().toISOString(),
      UpdatedOn: new Date().toISOString(),
      NavigationSetupId: "2",
      OrganisationId: 1,
      Type: "Report",
      IsRls: false,
      PageType: "All Pages",
      ReportType: "PowerBiReport",
      ReportDatasetId: contentDetail?.datasetId || "",
      toggler: "Report",
      DynamicDataSetid: "2",
      HideTitleSection: false,
      SortOrder: 0,
      type: "contentPage",
      EmbedUrl: contentDetail?.embedUrl || "",
    };

    if (!isNew && contentPage) {
      dispatch(updateContentPage({ id: contentPage.id, data: contentPageData }))
        .then((res) => {
          if (res.type === "contentPages/updateContentPage/fulfilled") {
            toast.success("Content Updated Successfully");
          } else if (res.type === "contentPages/updateContentPage/rejected") {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      dispatch(addContentPage(contentPageData))
        .then((res) => {
          if (res.type === "contentPages/addContentPage/fulfilled") {
            toast.success("Content Created Successfully");
          } else if (res.type === "contentPages/addContentPage/rejected") {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }

    // resetForm();
  };

  return (
    <div className="add-content-page">
      <Header as="h3" className="text-primary opacity-90">
        Create Navigation Option
      </Header>
      <p>Edit the details for your navigation option</p>

      <div className="pt-2 text-primary opacity-85">
        <Header as="h4">
          <span>Parent Item: </span>
          <span>{parentItem}</span>
        </Header>
      </div>

      <div className="w-full flex items-center justify-end space-x-2">
        <Button basic size="tiny">
          Cancel
        </Button>
        <Button
          loading={addLoading || loading}
          primary
          size="tiny"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>

      <Divider />

      <Form>
        <Form.Input
          label="Name"
          placeholder="Name"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          error={titleError ? { content: titleError, pointing: "below" } : null}
        />
        <Form.Field error={!!iconError}>
          <label>Icon</label>
          <Dropdown
            trigger={
              <span>
                {/* @ts-ignore */}
                {selectedIcon ? <Icon name={selectedIcon} /> : "Choose Icon"}
              </span>
            }
            fluid
            selection
            className="icon-dropdown"
          >
            <Dropdown.Menu>
              <div className="icon-grid">
                <Grid columns={14} doubling stackable className="m-10">
                  {allIcons.map((icon) => (
                    <Grid.Column key={icon} className="">
                      <Segment
                        onClick={() => handleIconClick(icon)}
                        className={`icon-segment ${
                          selectedIcon === icon ? "selected" : ""
                        }`}
                      >
                        {/* @ts-ignore */}
                        <Icon name={icon} />
                      </Segment>
                    </Grid.Column>
                  ))}
                </Grid>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {iconError && <Message error content={iconError} />}
        </Form.Field>
        <Form.Input
          label="Description"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Form.Field>
          <Checkbox
            label="Enable Dynamic Binding"
            checked={dynamicBinding}
            onChange={(e, { checked }) => setDynamicBinding(!!checked)}
          />
        </Form.Field>
        <Form.Field error={!!workspaceError}>
          <Dropdown
            placeholder="Power BI Workspace"
            fluid
            selection
            options={workspaceOptions}
            value={workspace}
            onChange={(e, { value }) => setWorkspace(value as string)}
          />
          {workspaceError && <Message error content={workspaceError} />}
        </Form.Field>
        <Form.Field error={!!contentError}>
          <Dropdown
            placeholder="Power BI Content"
            fluid
            selection
            options={workspaceContentOptions}
            value={content}
            onChange={(e, { value }) => setContent(value as string)}
          />
          {contentError && <Message error content={contentError} />}
        </Form.Field>
        <Form.Field error={!!reportPageError}>
          <Dropdown
            placeholder="Report Page"
            fluid
            multiple
            selection
            options={contentPageOptions}
            value={reportPages}
            onChange={(e, { value }) => setReportPages(value as string[])}
          />
          {reportPageError && <Message error content={reportPageError} />}
        </Form.Field>
        <Form.Field>
          <Dropdown
            placeholder="Add Groups"
            fluid
            multiple
            selection
            options={roleOptions}
            value={permissions}
            onChange={(e, { value }) => setPermissions(value as string[])}
          />
        </Form.Field>

        <div className="grid grid-cols-2 gap-4">
          <Form.Field>
            <label>Filter and Content Pane</label>
            <div className="flex flex-col space-y-2">
              <Checkbox
                label="Show Filter Pane"
                checked={filterPane}
                onChange={(e, { checked }) => setFilterPane(!!checked)}
              />
              <Checkbox
                label="Show Content Pane"
                checked={contentPane}
                onChange={(e, { checked }) => setContentPane(!!checked)}
              />
              <Checkbox
                label="Show Title and Description"
                checked={titleDesc}
                onChange={(e, { checked }) => setTitleDesc(!!checked)}
              />
            </div>
          </Form.Field>
          <Form.Field>
            <label>Report Sharing</label>
            <div className="flex flex-col space-y-2">
              <Checkbox
                label="Report Sharing Allowed"
                checked={reportSharing}
                onChange={(e, { checked }) => setReportSharing(!!checked)}
              />
              <Checkbox
                label="Report Export Allowed"
                checked={reportExport}
                onChange={(e, { checked }) => setReportExport(!!checked)}
              />
            </div>
          </Form.Field>
        </div>
      </Form>

      <Divider />

      <div className="w-full flex items-center justify-end space-x-2">
        <Button basic size="tiny">
          Cancel
        </Button>
        <Button
          loading={addLoading || loading}
          primary
          size="tiny"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default AddContentPage;
