import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  Container,
  Tab,
  Image,
  Input,
  Button,
  Form,
  Message,
} from "semantic-ui-react";
import { BiUpload } from "react-icons/bi";
import { AppDispatch, RootState } from "../../store/store";
import {
  UserResponse,
  changeProfile,
  fetchUser,
  updateProfile,
  changePassword,
} from "../../store/slices/userSlice";
import toast from "react-hot-toast";
import CustomLoader from "../constants/CustomLoader";

const ProfilePage = () => {
  const [profile, setProfile] = useState<UserResponse>({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    isSuperTenant: false,
    isActive: true,
    phoneNumber: "",
    dateOfBirth: "",
    RoleId: 0,
    Role: { id: 0, name: "", description: "", Permissions: [], TenantId: 1 },
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  // const [notifications, setNotifications] = useState({
  //   general: true,
  //   botOrders: true,
  // });
  const dispatch = useDispatch<AppDispatch>();

  const { user, changeProfileLoading, editLoading, loading } = useSelector(
    (state: RootState) => state.users
  );

  useEffect(() => {
    if (user) {
      setProfile(user);
    }
  }, [user]);

  const clearMessages = () => {
    setError("");
    setSuccess("");
  };

  const handlePasswordChange = () => {
    clearMessages();
    if (password !== confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    dispatch(
      changePassword({
        previousPassword: currentPassword,
        newPassword: password,
      })
    )
      .then((res) => {
        if (res.type === "users/changePassword/fulfilled") {
          toast.success("Password changed successfully!");
          setPassword("");
          setConfirmPassword("");
          setCurrentPassword("");
        } else {
          throw new Error(res.payload as string);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleProfileUpdate = () => {
    clearMessages();
    dispatch(updateProfile(profile))
      .then((res) => {
        if (res.type === "users/updateProfile/fulfilled") {
          toast.success("Profile updated successfully!");
        } else {
          throw new Error(res.payload as string);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleFileUpload = () => {
    clearMessages();
    if (file !== null) {
      const formData = new FormData();
      formData.append("imageUrl", file);

      dispatch(changeProfile({ formData }))
        .then((res) => {
          if (res.type === "users/changeProfile/fulfilled") {
            toast.success("Profile Changed");
            setFile(null);
            setFilePreview(null);
            dispatch(fetchUser());
          } else {
            throw new Error(res.payload as string);
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      setFilePreview(URL.createObjectURL(selectedFile));
    }
  };

  // const handleNotificationChange = async () => {
  //   clearMessages();
  //   try {
  //     await axios.put(`/api/users/${profile.id}/notifications`, notifications);
  //     setSuccess("Notification preferences updated successfully!");
  //   } catch (error) {
  //     setError("Error updating notification preferences. Please try again.");
  //   }
  // };

  if (loading) {
    return <CustomLoader height={70} />;
  }

  const panes = [
    {
      menuItem: "Profile Information",
      render: () => (
        <Tab.Pane>
          {user && (
            <div className="grid max-w-[1240px] m-auto">
              <div className="flex flex-col justify-left md:items-start w-full px-2 py-5">
                <h1 className="text-xl text-gray-500 mb-5">
                  GENERAL INFORMATION
                </h1>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-start mb-5">
                    <Image
                      src={
                        filePreview ||
                        user.imageUrl ||
                        "https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                      }
                      rounded
                      size="small"
                      className="mr-10"
                    />
                    <div className="flex flex-col">
                      <h1 className="text-gray-700">Add a profile picture</h1>
                      <p className="py-2 text-sm text-gray-500">
                        Please upload a square image (1:1 aspect ratio).
                      </p>
                      <Input
                        type="file"
                        className="mb-2"
                        onChange={handleFileChange}
                      />

                      <Button
                        loading={changeProfileLoading}
                        primary
                        size="tiny"
                        className="mt-2"
                        onClick={handleFileUpload}
                      >
                        <div className="flex py-2 items-center justify-center space-x-1">
                          <BiUpload />
                          <span>Upload File</span>
                        </div>
                      </Button>
                    </div>
                  </div>
                  <Form>
                    <Form.Field>
                      <label>First Name</label>
                      <Input
                        placeholder="First Name"
                        value={profile.firstName}
                        onChange={(e) =>
                          setProfile({ ...profile, firstName: e.target.value })
                        }
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Last Name</label>
                      <Input
                        placeholder="Last Name"
                        value={profile.lastName}
                        onChange={(e) =>
                          setProfile({ ...profile, lastName: e.target.value })
                        }
                      />
                    </Form.Field>
                    <Button
                      primary
                      size="tiny"
                      loading={editLoading}
                      onClick={handleProfileUpdate}
                    >
                      <div className="flex py-1 items-center justify-center space-x-1">
                        <span>Update my profile</span>
                      </div>
                    </Button>
                    {error && <Message negative>{error}</Message>}
                    {success && <Message positive>{success}</Message>}
                  </Form>
                </div>
              </div>
            </div>
          )}
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: "Notifications",
    //   render: () => (
    //     <Tab.Pane>
    //       <div className="grid md:grid-cols-2 max-w-[1240px] m-auto">
    //         <div className="flex flex-col justify-left md:items-start w-full px-2 py-8 border-r border-gray-200">
    //           <h1 className="py-3 text-2xl font-bold">Your Notifications</h1>
    //           <div className="flex items-center mb-5 justify-between w-full border-t border-b border-gray-200 py-5">
    //             <div>
    //               <p className="text-md">General Account Information</p>
    //               <p className="pt-2 text-sm text-gray-400">
    //                 Delivered By: Email
    //               </p>
    //             </div>
    //             <div className="scale-120">
    //               <Checkbox
    //                 toggle
    //                 checked={notifications.general}
    //                 onChange={() =>
    //                   setNotifications({
    //                     ...notifications,
    //                     general: !notifications.general,
    //                   })
    //                 }
    //               />
    //             </div>
    //           </div>

    //           <Button primary size="tiny" onClick={handleNotificationChange}>
    //             <div className="flex py-1 items-center justify-center space-x-1">
    //               <span>Save Preferences</span>
    //             </div>
    //           </Button>
    //           {error && <Message negative>{error}</Message>}
    //           {success && <Message positive>{success}</Message>}
    //         </div>
    //       </div>
    //     </Tab.Pane>
    //   ),
    // },
    {
      menuItem: "Change Password",
      render: () => (
        <Tab.Pane>
          <h1 className="text-xl text-gray-500 mb-5">Change Password</h1>
          <Form>
            <Form.Field>
              <label>Current Password</label>
              <Input
                type="password"
                placeholder="Current Password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>New Password</label>
              <Input
                type="password"
                placeholder="New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Confirm Password</label>
              <Input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Field>
            <Button
              primary
              size="tiny"
              loading={changeProfileLoading}
              onClick={handlePasswordChange}
            >
              <div className="flex py-1 items-center justify-center space-x-1">
                <span>Change Password</span>
              </div>
            </Button>
            {error && <Message negative>{error}</Message>}
            {success && <Message positive>{success}</Message>}
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Security",
      render: () => (
        <Tab.Pane>
          <div className="grid md:grid-cols-2 max-w-[1240px] m-auto text-gray-500">
            <div className="flex flex-col justify-left md:items-start w-full px-2 py-8">
              <p className="py-3 text-md md:text-xl font-bold">
                TWO-FACTOR AUTHENTICATION
              </p>
              <p className="text-sm">Setup or remove 2FA.</p>
              <Button primary size="tiny">
                <div className="flex py-1 items-center justify-center space-x-1">
                  <span>Setup or Remove</span>
                </div>
              </Button>
            </div>
          </div>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Container className="mt-5">
      {user && (
        <div className="mx-10">
          <h1 className="text-3xl text-secondary font-bold">Settings</h1>
          <p className="text-gray-500 pb-2">Adjust your exchange accounts.</p>
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </div>
      )}
    </Container>
  );
};

export default ProfilePage;
